/*eslint-disable max-len*/

import { Switch, Route, Router } from 'react-router-dom';
import loadable from '@loadable/component';
import history from './history'; // https://github.com/gaearon/react-hot-loader/issues/298#issuecomment-500382141

const MainView = loadable(() => import('./components/MainView'));
const AuthenticateView = loadable(() => import('./connection/components/AuthenticateView'));
const AuthenticateVerifyView = loadable(() => import('./connection/components/AuthenticateVerifyView'));
const ToHomePage = loadable(() => import('./connection/components/ToHomePage'));
const ToOauthPage = loadable(() => import('./connection/components/ToOauthPage'));
const CreateTokenView = loadable(() => import('./connection/components/CreateTokenView'));
const ResetPasswordView = loadable(() => import('./connection/components/ResetPasswordView'));
const HomeView = loadable(() => import('./home/components/HomeView'));
const TerminalActivitiesView = loadable(() => import('./reporting/components/TerminalActivitiesView'));
const ListTerminalConnectionView = loadable(
  () => import('./reporting/components/ListTerminalConnectionView')
);
const CreateCustomerView = loadable(() => import('./customer/components/CreateCustomerView'));
const ListCustomerView = loadable(() => import('./customer/components/ListCustomerView'));
const SettingsCustomerView = loadable(() => import('./customer/components/SettingsCustomerView'));
const CreateMerchantView = loadable(() => import('./merchant/components/CreateMerchantView'));
const ListMerchantView = loadable(() => import('./merchant/components/ListMerchantView'));
const ListStoreView = loadable(() => import('./store/components/ListStoreView'));
const EditPasswordView = loadable(() => import('./user/components/EditPasswordView'));
const ListSimView = loadable(() => import('./sim/components/ListSimView'));
const ListOfferView = loadable(() => import('./offer/components/ListOfferView'));
const CreateOfferView = loadable(() => import('./offer/components/CreateOfferView'));
const EditOfferView = loadable(() => import('./offer/components/EditOfferView'));
const CreateAutoinitDefaultProgramView = loadable(
  () => import('./autoinitprogram/components/CreateAutoinitDefaultProgramView')
);
const EditProgramView = loadable(() => import('./program/components/EditProgramView'));

const ListAlertView = loadable(() => import('./alerts/components/ListAlertView'));
const ListAdvancedAlertView = loadable(() => import('./alerts/components/ListAdvancedAlertView'));
const CreateAdvancedAlert = loadable(() => import('./alerts/components/CreateAdvancedAlert'));
const CreatePredefinedAlertView = loadable(
  () => import('./alerts/predefined/components/CreatePredefinedAlertView')
);
const EditAdvancedAlert = loadable(() => import('./alerts/components/EditAdvancedAlert'));

const CreateScheduledReport = loadable(() => import('./scheduled/components/CreateScheduledReport'));
const EditScheduledReport = loadable(() => import('./scheduled/components/EditScheduledReport'));
const ListScheduledView = loadable(() => import('./scheduled/components/ListScheduledView'));

const ListPoiView = loadable(() => import('./poi/components/ListPoiView'));
const CreateSwapPoiView = loadable(() => import('./poi/components/CreateSwapPoiView'));
const TerminalMobileView = loadable(() => import('./components/TerminalMobile/TerminalMobileView'));
const OnBoardTerminalMobileView = loadable(
  () => import('./components/TerminalMobile/OnBoardTerminal/OnboardTerminalMobileView')
);
const UnlinkTerminalMobileView = loadable(
  () => import('./components/TerminalMobile/UnlinkTerminal/UnlinkTerminalMobileView')
);
const LinkTerminalMobileView = loadable(
  () => import('./components/TerminalMobile/LinkTerminal/LinkTerminalMobileView')
);

const DeleteTerminalMobileView = loadable(
  () => import('./components/TerminalMobile/DeleteTerminal/DeleteTerminalMobileView')
);
const SwapTerminalMobileView = loadable(
  () => import('./components/TerminalMobile/SwapTerminal/SwapTerminalMobileView')
);

const AuthTerminalMobileView = loadable(
  () => import('./components/TerminalMobile/AuthTerminal/AuthTerminalMobileView')
);

const EditPoiView = loadable(() => import('./poi/components/EditPoiView'));
const ListPosView = loadable(() => import('./pos/components/ListPosView'));
const EditUserView = loadable(() => import('./user/components/EditUserView'));
const ListUserView = loadable(() => import('./user/components/ListUserView'));
const LinkPoiView = loadable(() => import('./poi/components/LinkPoiView'));
const LinkPosView = loadable(() => import('./pos/components/LinkPosView'));
const UnLinkPoiView = loadable(() => import('./pos/components/UnLinkPoiView'));
const UnLinkPosView = loadable(() => import('./poi/components/UnLinkPosView'));
const SimActivitiesView = loadable(() => import('./reporting/components/SimActivitiesView'));
const MerchantReceiptSendView = loadable(() => import('./receipt/MerchantReceiptSendView'));
const CardholderReceiptSendView = loadable(() => import('./receipt/CardholderReceiptSendView'));
const EditCustomerView = loadable(() => import('./customer/components/EditCustomerView'));
const EditSimView = loadable(() => import('./sim/components/EditSimView'));
const CreateSimView = loadable(() => import('./sim/components/CreateSimView'));
const ActivateRadiusSimView = loadable(() => import('./sim/components/ActivateRadiusSimView'));
const ActivateSierraSimView = loadable(() => import('./sim/components/ActivateSierraSimView'));

const SnapshotView = loadable(() => import('./snapshot/SnapshotView'));
const ReturningCustomersView = loadable(
  () => import('./reporting/components/returningCustomers/ReturningCustomersView')
);

const PaymentMethodsView = loadable(() => import('./reporting/components/PaymentMethodsView'));

const TransactionComparisonView = loadable(() => import('./reporting/components/TransactionComparisonView'));
const ListSimConnectionView = loadable(() => import('./reporting/components/ListSimConnectionView'));
const ListReconciliationView = loadable(
  () => import('./reporting/reconciliation/components/ListReconciliationView')
);
const ListTerminalAlertView = loadable(() => import('./terminalReporting/components/ListTerminalAlertView'));
const TaxAuditView = loadable(() => import('./reporting/components/TaxAuditView'));

const ListSimAlertView = loadable(() => import('./simReporting/components/ListSimAlertView'));

const ListTransactionView = loadable(() => import('./reporting/transactions/components/ListTransactionView'));

const LogoCustomerView = loadable(() => import('./customer/components/LogoCustomerView'));
const TemplateView = loadable(() => import('./customer/components/template/TemplateView'));
const EditEmailTemplateView = loadable(() => import('./customer/components/template/EditEmailTemplateView'));
const EditSmsTemplateView = loadable(() => import('./customer/components/template/EditSmsTemplateView'));

const EditMerchantView = loadable(() => import('./merchant/components/EditMerchantView'));
const SettingsMerchantView = loadable(() => import('./merchant/components/SettingsMerchantView'));

const CreateStoreView = loadable(() => import('./store/components/CreateStoreView'));
const EditStoreView = loadable(() => import('./store/components/EditStoreView'));

const CreateUserView = loadable(() => import('./user/components/CreateUserView'));
const EditCurrentUserView = loadable(() => import('./user/components/EditCurrentUserView'));

const CreatePosView = loadable(() => import('./pos/components/CreatePosView'));
const EditPosView = loadable(() => import('./pos/components/EditPosView'));

const ImportSierraSimView = loadable(() => import('./sim/components/ImportSierraSimView'));

const ListTagView = loadable(() => import('./tag/components/ListTagView'));
const CreateTagView = loadable(() => import('./tag/components/CreateTagView'));
const EditTagView = loadable(() => import('./tag/components/EditTagView'));

const ProgramListView = loadable(() => import('./program/components/ListProgramView'));
const CreateProgramView = loadable(() => import('./program/components/CreateProgramView'));

const SchemeView = loadable(() => import('./scheme/components/SchemeView'));
const SchedulerMonitoringView = loadable(() => import('./monitoring/components/SchedulerMonitoringView'));
const AuditView = loadable(() => import('./monitoring/components/AuditView'));
const CreateScheduledJob = loadable(() => import('./monitoring/components/CreateScheduledJob'));

const LoginAs = loadable(() => import('./monitoring/components/LoginAs'));
const AddWidgetView = loadable(() => import('./home/components/AddWidgetView'));
const LoginWrapper = loadable(() => import('./connection/components/LoginWrapper'));
const ResolveConflict = loadable(() => import('./reporting/components/ResolveConflict'));
const ConflictList = loadable(() => import('./reporting/components/ConflictList'));

const ApplicationSettingsView = loadable(
  () => import('./../jsx/applicationSettings/components/ApplicationSettingsView')
);

const CreateCatalogItemView = loadable(() => import('./catalog/components/CreateCatalogItemView'));
const ListCatalogItemView = loadable(() => import('./catalog/components/ListCatalogItemView'));
const EditCatalogItemView = loadable(() => import('./catalog/components/EditCatalogItemView'));
const UploadPhotoView = loadable(() => import('./catalog/components/UploadPhotoView'));

const VatRatesView = loadable(() => import('./vatRate/components/VatRatesView'));

const ListSaleView = loadable(() => import('./reporting/sale/components/ListSaleView'));
const ListPredefinedAlertView = loadable(
  () => import('./alerts/predefined/components/ListPredefinedAlertView')
);

const TaxAuditLogView = loadable(() => import('./taxAudit/components/TaxAuditLogView'));
const ListSessionsView = loadable(() => import('./taxAudit/components/ListSessionsView'));
const ShowMerchantsView = loadable(() => import('./taxAudit/components/ListMerchantView'));
const EditWidgetView = loadable(() => import('./home/components/EditWidgetView'));

const NotFound = loadable(() => import('./NotFound/NotFound'));

const loginPaths = '/(login|authenticate)';

const LoginRoutes = () => (
  <LoginWrapper>
    <Switch>
      <Route exact path="/authenticate" component={AuthenticateView} />
      <Route exact path="/login/2fa/:pair?" component={AuthenticateVerifyView} />
      <Route path="/login/forgot-password" component={CreateTokenView} />
      <Route path="/login/reset-password/:token" component={ResetPasswordView} />
    </Switch>
  </LoginWrapper>
);

const MainRoutes = () => (
  <MainView>
    <Switch>
      <Route exact path="/" component={HomeView} />
      <Route path="/reporting/snapshot" component={SnapshotView} />
      <Route path="/reporting/transaction" component={ListTransactionView} />
      <Route path="/reporting/cards" component={PaymentMethodsView} />
      <Route path="/reporting/comparison" component={TransactionComparisonView} />
      <Route path="/reporting/terminals" component={TerminalActivitiesView} />
      <Route path="/reporting/terminal-connections" component={ListTerminalConnectionView} />
      <Route path="/reporting/returningCustomers" component={ReturningCustomersView} />
      <Route exact path="/reporting/alerts" component={ListAlertView} />
      <Route exact path="/reporting/alerts/advanced" component={ListAdvancedAlertView} />
      <Route exact path="/reporting/alerts/predefined" component={ListPredefinedAlertView} />
      <Route exact path="/reporting/alerts/advanced/create" component={CreateAdvancedAlert} />
      <Route exact path="/reporting/alerts/predefined/create" component={CreatePredefinedAlertView} />
      <Route path="/reporting/alerts/advanced/edit/:alertId" component={EditAdvancedAlert} />
      <Route exact path="/reporting/scheduled" component={ListScheduledView} />
      <Route path="/reporting/scheduled/create" component={CreateScheduledReport} />
      <Route path="/reporting/scheduled/edit/:scheduledId" component={EditScheduledReport} />
      <Route path="/reporting/sims-status" component={SimActivitiesView} />
      <Route path="/reporting/sim-connections" component={ListSimConnectionView} />
      <Route path="/reporting/reconciliation" component={ListReconciliationView} />
      <Route exact path="/reporting/sales" component={ListSaleView} />
      <Route path="/reporting/conflicts" component={ConflictList} />
      <Route path="/reporting/resolve-conflict/:transactionId" component={ResolveConflict} />
      <Route path="/reporting/tax-audit" component={TaxAuditView} />
      <Route exact path="/terminal-reporting" component={TerminalActivitiesView} />
      <Route path="/terminal-reporting/terminal-alerts" component={ListTerminalAlertView} />
      <Route path="/sim-alerts" component={ListSimAlertView} />
      <Route exact path="/customer" component={ListCustomerView} />
      <Route path="/customer/create-customer" component={CreateCustomerView} />
      <Route path="/customer/edit-customer/:customerId" component={EditCustomerView} />
      <Route exact path="/settings/catalog" component={ListCatalogItemView} />
      <Route path="/settings/catalog/new-catalogItem" component={CreateCatalogItemView} />
      <Route path="/settings/catalog/edit-catalogItem/:catalogItemId" component={EditCatalogItemView} />
      <Route path="/settings/catalog/upload-photo/:catalogItemId/:edit?" component={UploadPhotoView} />
      <Route exact path="/settings/vatrate" component={VatRatesView} />
      <Route exact path="/settings/merchant" component={ListMerchantView} />
      <Route path="/settings/merchant/new-merchant" component={CreateMerchantView} />
      <Route path="/settings/merchant/edit-merchant/:merchantId" component={EditMerchantView} />
      <Route path="/merchant/settings-merchant" component={SettingsMerchantView} />
      <Route path="/merchant/application-settings-merchant" component={ApplicationSettingsView} />
      <Route exact path="/settings/tags" component={ListTagView} />
      <Route path="/settings/tags/new-tag" component={CreateTagView} />
      <Route path="/settings/tags/edit-tag/:tagId" component={EditTagView} />
      <Route exact path="/offers" component={ListOfferView} />
      <Route path="/offers/new-offer" component={CreateOfferView} />
      <Route path="/offers/edit-offer/:offerId" component={EditOfferView} />
      <Route exact path="/settings/program" component={ProgramListView} />
      <Route path="/program/default" component={CreateAutoinitDefaultProgramView} />
      <Route path="/settings/program/new-program" component={CreateProgramView} />
      <Route path="/settings/program/edit-program/:programId" component={EditProgramView} />
      <Route exact path="/settings/store" component={ListStoreView} />
      <Route path="/settings/store/new-store/:level?/:id?" component={CreateStoreView} />
      <Route path="/settings/store/edit-store/:storeId" component={EditStoreView} />
      <Route exact path="/settings/pos" component={ListPosView} />
      <Route path="/settings/pos/new-pos/:poiId?/:merchantId?" component={CreatePosView} />
      <Route path="/settings/pos/edit-pos/:posId" component={EditPosView} />
      <Route path="/settings/pos/link-pos/:posId" component={LinkPosView} />
      <Route exact path="/monitoring/logs/:merchantId?" component={TaxAuditLogView} />
      <Route path="/settings/pos/unlink-poi/:posId" component={UnLinkPoiView} />
      <Route path="/settings/poi/unlink-pos/:posId/:poiId" component={UnLinkPosView} />
      <Route exact path="/:settings?/sim" component={ListSimView} />
      <Route path="/sim/new-sim" component={CreateSimView} />
      <Route path="/sim/import-sierra" component={ImportSierraSimView} />
      <Route path="/sim/activate/radius/:simId" component={ActivateRadiusSimView} />
      <Route path="/sim/activate/sierra/:simId" component={ActivateSierraSimView} />
      <Route path="/:settings?/sim/edit-sim/:simId" component={EditSimView} />
      <Route exact path="/settings/poi" component={ListPoiView} />
      <Route path="/settings/poi/new-poi" component={CreateSwapPoiView} />
      <Route path="/settings/poi/edit-poi/:poiId/:linked?" component={EditPoiView} />
      <Route path="/settings/poi/link-poi/:poiId" component={LinkPoiView} />
      <Route path="/settings/poi/swap-poi/:poiId" component={CreateSwapPoiView} />
      <Route exact path="/mobile-poi" component={TerminalMobileView} />
      <Route path="/mobile-poi/onboarding" component={OnBoardTerminalMobileView} />
      <Route path="/mobile-poi/unlink" component={UnlinkTerminalMobileView} />
      <Route path="/mobile-poi/link-poi" component={LinkTerminalMobileView} />
      <Route path="/mobile-poi/delete" component={DeleteTerminalMobileView} />
      <Route path="/mobile-poi/swap" component={SwapTerminalMobileView} />
      <Route path="/mobile-poi/auth" component={AuthTerminalMobileView} />
      <Route path="/:settings?/user/new-user/:level?/:entityId?" component={CreateUserView} />
      <Route path="/my-account" component={EditCurrentUserView} />
      <Route path="/:settings?/user/edit-user/:userId" component={EditUserView} />
      <Route path="/edit-password" component={EditPasswordView} />
      <Route exact path="/:settings?/user/:level?" component={ListUserView} />
      <Route path="/scheme" component={SchemeView} />
      <Route path="/settings/merchants-settings" component={SettingsMerchantView} />
      <Route path="/settings/general" component={SettingsCustomerView} />
      <Route path="/settings/logo-customer" component={LogoCustomerView} />
      <Route exact path="/settings/templates/:template?" component={TemplateView} />
      <Route path="/settings/templates/edit-email-templates/:templateId" component={EditEmailTemplateView} />
      <Route path="/settings/templates/edit-sms-templates/:templateId" component={EditSmsTemplateView} />
      <Route path="/monitoring/scheduler" component={SchedulerMonitoringView} />
      <Route path="/monitoring/audit" component={AuditView} />
      <Route path="/monitoring/new-scheduled-job" component={CreateScheduledJob} />
      <Route path="/monitoring/login-as" component={LoginAs} />
      <Route
        path="/settings/merchantReceipt/:receiptType/multiple/send"
        component={MerchantReceiptSendView}
      />
      <Route
        path="/settings/merchantReceipt/:receiptType/id/:transactionId/send"
        component={MerchantReceiptSendView}
      />
      <Route
        path="/cardholderReceipt/:receiptType/id/:transactionId/send"
        component={CardholderReceiptSendView}
      />
      <Route path="/reporting/widgets/add" component={AddWidgetView} />
      <Route path="/reporting/widgets/edit/:id" component={EditWidgetView} />
      <Route path="/monitoring/open-sessions/:merchantId" component={ListSessionsView} />
      <Route path="/monitoring/open-sessions" component={ListSessionsView} />
      <Route path="/tax-audit" component={ShowMerchantsView} />
      <Route path="*" component={NotFound} />
    </Switch>
  </MainView>
);

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path="/redirect/:tokenValue" component={ToHomePage} />
      <Route path="/redirectOauth/:codeValue" component={ToOauthPage} />
      <Route path={loginPaths} component={LoginRoutes} />
      <Route path="/" component={MainRoutes} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
/*eslint-enable max-len*/
