import get from 'get-value';
import { upperCaseFirst } from 'upper-case-first';

import {
  PENDING_GET_TRANSACTIONS_ACTION,
  SUCCESS_GET_TRANSACTIONS_ACTION,
  SUCCESS_GET_TRANSACTIONS_SCROLL_ACTION,
  FAILURE_GET_TRANSACTIONS_ACTION,
  PENDING_GET_TRANSACTION_CONFLICT_ACTION,
  SUCCESS_GET_TRANSACTION_CONFLICT_ACTION,
  FAILURE_GET_TRANSACTION_CONFLICT_ACTION,
  FAILURE_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  RESET_TRANSACTIONS_ACTION,
  SUCCESS_GET_TRANSACTIONS_DETAIL_ACTION,
  FAILURE_GET_TRANSACTIONS_DETAIL_ACTION,
  CLEAR_TRANSACTIONS_DETAIL_ACTION,
  SUCCESS_GET_TRANSACTIONS_MERCHANT_RECEIPT_ACTION,
  FAILURE_GET_TRANSACTIONS_MERCHANT_RECEIPT_ACTION,
  SUCCESS_GET_TRANSACTIONS_CARDHOLDER_RECEIPT_ACTION,
  FAILURE_GET_TRANSACTIONS_CARDHOLDER_RECEIPT_ACTION,
  SUCCESS_GET_TRANSACTIONS_RECEIPTS_ACTION,
  FAILURE_GET_TRANSACTIONS_RECEIPTS_ACTION,
  SHOW_TRANSACTIONS_RECEIPTS_ACTION,
  HIDE_TRANSACTIONS_RECEIPTS_ACTION,
  SHOW_TRANSACTIONS_DETAIL_ACTION,
  HIDE_TRANSACTIONS_DETAIL_ACTION,
  CLOSE_TRANSACTIONS_RECEIPTS_ACTION
} from '../actionsTypes';
import { initSelection, onToggleSelectAll, onToggleUnSelectAll } from './selection';

const initialState = {
  data: [],
  detail: {},
  selection: {},
  receipts: {},
  receipt: '',
  showMerchantPopin: false,
  showCardholderPopin: false,
  showReceiptsSelectionPopin: false,
  showTransactionDetail: false,
  loading: true,
  error: false,
  errors: {}
};

const transactionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_TRANSACTION_CONFLICT_ACTION:
    case PENDING_GET_TRANSACTIONS_ACTION: {
      return {
        ...state,
        detail: {},
        selection: {},
        receipts: {},
        receipt: '',
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_TRANSACTIONS_ACTION: {
      const {
        payload: { count, data, scrollId }
      } = action;
      const selection = initSelection({
        data,
        key: 'transaction',
        keyId: 'transactionId'
      });

      return {
        ...state,
        data,
        selection,
        count,
        scrollId,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_TRANSACTION_CONFLICT_ACTION: {
      const {
        payload: { count, data }
      } = action;

      return {
        ...state,
        data,
        count,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_TRANSACTIONS_SCROLL_ACTION: {
      const {
        payload: { data: scrollData }
      } = action;
      const data = [...state.data, ...scrollData];
      const selection = {
        ...initSelection({
          data,
          key: 'transaction',
          keyId: 'transactionId'
        }),
        ...state.selection
      };

      return {
        ...state,
        data,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_TRANSACTION_CONFLICT_ACTION:
    case FAILURE_GET_TRANSACTIONS_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        data: initialState.data,
        selection: {},
        qrCodes: {},
        loading: false,
        error: true,
        ...errors
      };
    }
    case RESET_TRANSACTIONS_ACTION: {
      return {
        ...state,
        ...initialState
      };
    }
    case SUCCESS_GET_TRANSACTIONS_DETAIL_ACTION: {
      const {
        payload: { detail }
      } = action;

      return {
        ...state,
        detail
      };
    }
    case FAILURE_GET_TRANSACTIONS_DETAIL_ACTION:
    case FAILURE_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION:
    case FAILURE_GET_TRANSACTIONS_MERCHANT_RECEIPT_ACTION:
    case FAILURE_GET_TRANSACTIONS_CARDHOLDER_RECEIPT_ACTION:
    case FAILURE_GET_TRANSACTIONS_RECEIPTS_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        error: true,
        ...errors
      };
    }
    case CLEAR_TRANSACTIONS_DETAIL_ACTION: {
      return {
        ...state,
        detail: {}
      };
    }
    case SHOW_TRANSACTIONS_DETAIL_ACTION: {
      return {
        ...state,
        showTransactionDetail: true
      };
    }
    case HIDE_TRANSACTIONS_DETAIL_ACTION: {
      return {
        ...state,
        showTransactionDetail: false
      };
    }
    case SUCCESS_GET_TRANSACTIONS_MERCHANT_RECEIPT_ACTION:
    case SUCCESS_GET_TRANSACTIONS_CARDHOLDER_RECEIPT_ACTION: {
      const {
        payload: { receiptType, receiptHtml }
      } = action;
      const showPopin = `show${upperCaseFirst(receiptType)}Popin`;

      return {
        ...state,
        receiptHtml,
        [showPopin]: true
      };
    }
    case 'CLOSE_TRANSACTIONS_MERCHANT_RECEIPT_ACTION':
    case 'CLOSE_TRANSACTIONS_CARDHOLDER_RECEIPT_ACTION': {
      const {
        payload: { receiptType }
      } = action;
      const showPopin = `show${upperCaseFirst(receiptType)}Popin`;

      return {
        ...state,
        receiptHtml: '',
        [showPopin]: false
      };
    }
    case 'TOGGLE_SELECT_TRANSACTIONS_ACTION':
    case 'TOGGLE_UNSELECT_TRANSACTIONS_ACTION': {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find((transaction) => get(transaction, 'transactionId') === id) : false
        }
      };
    }
    case 'TOGGLE_SELECT_ALL_TRANSACTIONS_ACTION': {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: 'transactionId'
      });

      return {
        ...state,
        selection
      };
    }
    case 'TOGGLE_UNSELECT_ALL_TRANSACTIONS_ACTION': {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    case SUCCESS_GET_TRANSACTIONS_RECEIPTS_ACTION: {
      const {
        payload: { receipts }
      } = action;

      return {
        ...state,
        receipts
      };
    }
    case SHOW_TRANSACTIONS_RECEIPTS_ACTION: {
      return {
        ...state,
        showReceiptsSelectionPopin: true
      };
    }
    case HIDE_TRANSACTIONS_RECEIPTS_ACTION: {
      return {
        ...state,
        receipts: {},
        showReceiptsSelectionPopin: false
      };
    }
    case CLOSE_TRANSACTIONS_RECEIPTS_ACTION: {
      const {
        payload: { receiptId }
      } = action;
      const { receipts: prevReceipts, selection: prevSelection } = state;

      const receipts = Object.keys(prevReceipts).reduce((prevReceiptId, currReceiptId) => {
        delete prevReceiptId[receiptId];

        return prevReceiptId;
      }, prevReceipts);

      const selection = Object.entries(prevSelection).reduce(
        (prevSelectedTransaction, selectedTransaction) => {
          const [transactionId, transaction] = selectedTransaction;

          return {
            ...prevSelectedTransaction,
            [transactionId]: transactionId !== receiptId ? transaction : false
          };
        },
        {}
      );

      const showReceiptsSelectionPopin = Object.keys(receipts).length > 0;

      return {
        ...state,
        receipts,
        selection,
        showReceiptsSelectionPopin
      };
    }
    default:
      return state;
  }
};

export default transactionsReducer;
