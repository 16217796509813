import {
  PENDING_GET_POIS_LIST_ACTION,
  SUCCESS_GET_POIS_LIST_ACTION,
  FAILURE_GET_POIS_LIST_ACTION,
  PENDING_GET_POIS_ACTION,
  SUCCESS_GET_POIS_ACTION,
  FAILURE_GET_POIS_ACTION,
  SUCCESS_GET_POIS_SCROLL_ACTION,
  SUCCESS_DELETE_POI_ACTION,
  RESET_POIS_ACTION
} from '../actionsTypes';
import { initSelection, onToggleSelectAll, onToggleUnSelectAll, filterId } from './selection';
import get from 'get-value';

import { reduceCount } from '../actions/sseActions';

const initialState = {
  dataList: [],
  data: [],
  selection: {},
  loading: true,
  error: false,
  errors: {}
};

const poisReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_POIS_LIST_ACTION: {
      return {
        ...state,
        dataList: [],
        selection: {},
        loading: true
      };
    }
    case PENDING_GET_POIS_ACTION: {
      return {
        ...state,
        data: [],
        loading: true
      };
    }
    case SUCCESS_GET_POIS_LIST_ACTION: {
      const {
        payload: { count, scrollId, data: dataList }
      } = action;
      const selection = initSelection({ data: dataList, key: 'poi' });

      return {
        ...state,
        dataList,
        selection,
        count,
        scrollId,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_POIS_ACTION: {
      const {
        payload: { data }
      } = action;

      return {
        ...state,
        data,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_POIS_SCROLL_ACTION: {
      const {
        payload: { data: scrollData }
      } = action;
      const dataList = [...state.dataList, ...scrollData];
      const selection = {
        ...initSelection({ data: dataList, key: 'poi' }),
        ...state.selection
      };

      return {
        ...state,
        dataList,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_POIS_LIST_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        dataList: initialState.dataList,
        selection: {},
        loading: false,
        error: true,
        ...errors
      };
    }
    case FAILURE_GET_POIS_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case 'TOGGLE_SELECT_POIS_ACTION':
    case 'TOGGLE_UNSELECT_POIS_ACTION': {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        dataList
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? dataList.find((poi) => get(poi, 'poi.id') === id) : false
        }
      };
    }
    case 'TOGGLE_SELECT_ALL_POIS_ACTION': {
      const { selection: prevSelection, dataList } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data: dataList,
        keyId: 'poi.id'
      });

      return {
        ...state,
        selection
      };
    }
    case 'TOGGLE_UNSELECT_ALL_POIS_ACTION': {
      const { selection } = state;
      const nextSelection = onToggleUnSelectAll({ selection });

      return {
        ...state,
        selection: nextSelection
      };
    }
    case SUCCESS_DELETE_POI_ACTION: {
      const {
        payload: { id }
      } = action;

      const { dataList, selection: prevSelection, count } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });
      const nextData = filterId({
        data: dataList,
        keyValue: id,
        keyId: 'poi.id'
      });
      const countNumber = parseInt(count);
      const nextCount = reduceCount({ countNumber, data: dataList, nextData });

      return {
        ...state,
        selection,
        count: nextCount.toString(),
        dataList: nextData
      };
    }
    case RESET_POIS_ACTION:
      return initialState;
    default:
      return state;
  }
};

export default poisReducer;
