import get from 'get-value';
import {
  PENDING_GET_POSS_LIST_ACTION,
  SUCCESS_GET_POSS_LIST_ACTION,
  FAILURE_GET_POSS_LIST_ACTION,
  PENDING_GET_POSS_ACTION,
  SUCCESS_GET_POSS_ACTION,
  SUCCESS_GET_POSS_SCROLL_ACTION,
  FAILURE_GET_POSS_ACTION,
  RESET_POSS_ACTION,
  SUCCESS_UPDATE_STATUS_POSS_ACTION,
  DISPLAY_POSS_QR_CODES_ACTION,
  HIDE_POSS_QR_CODES_ACTION,
  CLOSE_POSS_QR_CODE_ACTION,
  SUCCESS_DELETE_POS_ACTION,
  SUCCESS_GET_POIS_ACTION
} from '../actionsTypes';
import {
  initSelection,
  onStatusUpdated,
  onToggleSelectAll,
  onToggleUnSelectAll,
  filterId
} from './selection';

import { reduceCount } from '../actions/sseActions';

const initialState = {
  dataList: [],
  data: [],
  selection: {},
  qrCodes: {},
  showQrCodesSelectionPopin: false,
  loading: true,
  error: false,
  errors: {}
};

const possReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_POSS_LIST_ACTION: {
      return {
        ...state,
        dataList: [],
        selection: {},
        loading: true
      };
    }
    case PENDING_GET_POSS_ACTION: {
      return {
        ...state,
        data: [],
        loading: true
      };
    }
    case SUCCESS_GET_POSS_LIST_ACTION: {
      const {
        payload: { count, data: dataList, scrollId }
      } = action;
      const selection = initSelection({ data: dataList, key: 'pos' });

      return {
        ...state,
        dataList,
        selection,
        count,
        scrollId,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_POSS_ACTION: {
      const {
        payload: { data }
      } = action;

      return {
        ...state,
        data,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_POSS_SCROLL_ACTION: {
      const {
        payload: { data: scrollData }
      } = action;
      const dataList = [...state.dataList, ...scrollData];
      const selection = {
        ...initSelection({ data: dataList, key: 'pos' }),
        ...state.selection
      };

      return {
        ...state,
        dataList,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_POSS_LIST_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        dataList: initialState.dataList,
        selection: {},
        qrCodes: {},
        loading: false,
        error: true,
        ...errors
      };
    }
    case FAILURE_GET_POSS_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        data: initialState.data,
        selection: {},
        qrCodes: {},
        loading: false,
        error: true,
        ...errors
      };
    }
    case SUCCESS_GET_POIS_ACTION: {
      const {
        payload: { data: poiListSearchResults }
      } = action;
      const { dataList: posListSearchResults } = state;
      const poisList = poiListSearchResults.reduce((prevPoi, poi) => {
        const {
          poi: { posId, ...restPoi }
        } = poi;

        prevPoi[posId] = prevPoi[posId]
          ? [
              ...prevPoi[posId],
              {
                ...restPoi
              }
            ]
          : [
              {
                ...restPoi
              }
            ];

        return prevPoi;
      }, {});

      const posWithpoisList = posListSearchResults.map((pos) => ({
        ...pos,
        pois: pos.pois || poisList[pos.pos.id]
      }));

      return {
        ...state,
        dataList: posWithpoisList,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case 'TOGGLE_SELECT_POSS_ACTION':
    case 'TOGGLE_UNSELECT_POSS_ACTION': {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        dataList
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? dataList.find((pos) => get(pos, 'pos.id') === id) : false
        }
      };
    }
    case 'TOGGLE_SELECT_ALL_POSS_ACTION': {
      const { selection: prevSelection, dataList } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data: dataList,
        keyId: 'pos.id'
      });

      return {
        ...state,
        selection
      };
    }
    case 'TOGGLE_UNSELECT_ALL_POSS_ACTION': {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    case SUCCESS_UPDATE_STATUS_POSS_ACTION: {
      const {
        payload: { status, ids }
      } = action;
      const { dataList } = state;
      const nextData = onStatusUpdated({
        data: dataList,
        ids,
        status,
        key: 'pos'
      });

      return {
        ...state,
        dataList: nextData
      };
    }
    case SUCCESS_DELETE_POS_ACTION: {
      const {
        payload: { id }
      } = action;

      const { dataList, selection: prevSelection, count } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });
      const nextData = filterId({
        data: dataList,
        keyValue: id,
        keyId: 'pos.id'
      });
      const countNumber = parseInt(count);
      const nextCount = reduceCount({ countNumber, data: dataList, nextData });

      return {
        ...state,
        selection,
        count: nextCount.toString(),
        dataList: nextData
      };
    }
    case DISPLAY_POSS_QR_CODES_ACTION: {
      const { selection } = state;

      const qrCodes = Object.values(selection).reduce((prevSelectedPos, selectedPos) => {
        const acquirerTerminalCustomerId = get(selectedPos, 'acquirer.terminalCustomerId');
        const posAutoInit = get(selectedPos, 'pos.autoInit');

        if (acquirerTerminalCustomerId && posAutoInit) {
          const data = `${acquirerTerminalCustomerId};${posAutoInit}`;

          const { QrCode: QRC } = qrcodegen;
          const qr0 = QRC.encodeText(data, QRC.Ecc.MEDIUM);
          const svg = qr0.toSvgString(4);

          const qrCode = { pos: selectedPos, svg };

          const posIds = prevSelectedPos[get(selectedPos, 'store.id')]
            ? [...prevSelectedPos[get(selectedPos, 'store.id')].posIds, qrCode]
            : [qrCode];

          return {
            ...prevSelectedPos,
            [get(selectedPos, 'store.id')]: {
              'merchant.name': get(selectedPos, 'merchant.name'),
              'store.name': get(selectedPos, 'store.name'),
              posIds
            }
          };
        }

        return prevSelectedPos;
      }, {});

      return {
        ...state,
        qrCodes,
        showQrCodesSelectionPopin: true
      };
    }
    case HIDE_POSS_QR_CODES_ACTION: {
      return {
        ...state,
        qrCodes: {},
        showQrCodesSelectionPopin: false
      };
    }
    case CLOSE_POSS_QR_CODE_ACTION: {
      const {
        payload: { id: posId }
      } = action;
      const { qrCodes: prevQrCodes, selection: prevSelection } = state;

      const qrCodes = Object.keys(prevQrCodes).reduce((prevStoreId, storeId) => {
        const posIds = prevStoreId[storeId].posIds.filter((pos) => get(pos.pos, 'pos.id') !== posId);

        if (posIds.length === 0) {
          delete prevStoreId[storeId];

          return prevStoreId;
        }

        return {
          ...prevStoreId,
          [storeId]: {
            ...prevStoreId[storeId],
            posIds
          }
        };
      }, prevQrCodes);

      const selection = Object.entries(prevSelection).reduce((prevSelectedPos, selectedPos) => {
        const [id, pos] = selectedPos;

        return {
          ...prevSelectedPos,
          [id]: id !== posId ? pos : false
        };
      }, {});

      const showQrCodesSelectionPopin = Object.keys(qrCodes).length > 0;

      return {
        ...state,
        qrCodes,
        selection,
        showQrCodesSelectionPopin
      };
    }
    case RESET_POSS_ACTION: {
      return initialState;
    }
    default:
      return state;
  }
};

export default possReducer;
