import _ from 'lodash';
import TimePeriods from '../../reporting/models/TimePeriod';
import { SearchContext } from '../../searchContext/SearchContext';
import { extractUrlSearchParams, setUrlWithParams } from '../../searchContext/URLizer';
import { getLevelSettings } from './auth';

import { INIT_SEARCH_CONTEXT_ACTION } from '../actionsTypes';

const initSearchContext = (url?: string) => {
  const searchParams = extractUrlSearchParams(url);
  const timePeriod = searchParams && searchParams.timePeriod ? searchParams.timePeriod : TimePeriods.INTRADAY;

  const filtersByAdvancedSearchKey = searchParams ? searchParams.filtersByAdvancedSearchKey : {};
  const sortByAdvancedSearchKey = searchParams ? searchParams.sortByAdvancedSearchKey : {};
  const currency =
    searchParams && searchParams.currency ? searchParams.currency : getLevelSettings().currency;

  return {
    currency,
    timePeriod,
    filtersByAdvancedSearchKey,
    sortByAdvancedSearchKey
  } as SearchContext;
};

const initialState = {
  data: {
    filtersByAdvancedSearchKey: {},
    timePeriod: TimePeriods.INTRADAY,
    sortByAdvancedSearchKey: {},
    ...initSearchContext()
  },
  loading: false,
  error: false,
  errors: {}
};

const searchContextReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case `SET_${action.name}_SEARCH_CONTEXT_ACTION`: {
      const {
        payload: { context, pathname, updateUrl }
      } = action;

      const {
        key,
        filters: nextFilters,
        timePeriod: nextTimePeriod,
        currency: nextCurrency,
        sort: nextSort
      } = context;

      const {
        data: {
          timePeriod: prevTimePeriod,
          currency: prevCurrency,
          filtersByAdvancedSearchKey: { [key]: prevFilters = [] } = {},
          sortByAdvancedSearchKey: { [key]: prevSort = {} } = {}
        }
      } = state;

      const timePeriod =
        nextTimePeriod && !_.eq(prevTimePeriod, nextTimePeriod) ? nextTimePeriod : prevTimePeriod;

      const currency = nextCurrency && !_.eq(prevCurrency, nextCurrency) ? nextCurrency : prevCurrency;

      const filtersByAdvancedSearchKey = !_.eq(prevFilters, nextFilters) ? nextFilters : prevFilters;

      const sortByAdvancedSearchKey = !_.eq(prevSort, nextSort) ? nextSort : prevSort;

      const nextContext = {
        ...state.data,
        filtersByAdvancedSearchKey: {
          ...state.data.filtersByAdvancedSearchKey,
          [key]: filtersByAdvancedSearchKey
        },
        sortByAdvancedSearchKey: {
          ...state.data.sortByAdvancedSearchKey,
          [key]: sortByAdvancedSearchKey
        },
        timePeriod,
        currency
      };

      if (updateUrl) {
        setUrlWithParams(nextContext, pathname);
      }

      return {
        ...state,
        data: nextContext,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case `CLEAN_${action.name}_SEARCH_CONTEXT_ACTION`: {
      const {
        payload: { context, pathname, updateUrl }
      } = action;

      const { key } = context;

      delete state.data.filtersByAdvancedSearchKey[key];
      delete state.data.sortByAdvancedSearchKey[key];

      if (updateUrl) {
        setUrlWithParams(state.data, pathname);
      }

      return {
        ...state,
        ...initialState,
        data: state.data
      };
    }
    case INIT_SEARCH_CONTEXT_ACTION: {
      const {
        payload: { url }
      } = action;

      return {
        ...initialState,
        data: {
          ...initialState.data,
          ...initSearchContext(url)
        }
      };
    }
    default:
      return state;
  }
};

export default searchContextReducer;
