import {
  SUCCESS_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  FAILURE_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  RESET_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  SUCCESS_DELETE_USER_COLUMNS_SETTINGS_ACTION,
  FAILURE_DELETE_USER_COLUMNS_SETTINGS_ACTION,
  RESET_DELETE_USER_COLUMNS_SETTINGS_ACTION
} from '../actionsTypes';

const initialState = {
  data: {},
  error: false,
  errors: {}
};

const userSettingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SUCCESS_UPDATE_USER_COLUMNS_SETTINGS_ACTION: {
      const {
        payload: { columnSetting, category }
      } = action;

      return {
        ...state,
        data: {
          ...state.data,
          [category]: columnSetting
        },
        error: false,
        errors: {}
      };
    }
    case SUCCESS_DELETE_USER_COLUMNS_SETTINGS_ACTION: {
      const {
        payload: { category }
      } = action;

      delete state.data[category];

      return {
        ...state,
        error: false,
        errors: {}
      };
    }
    case FAILURE_DELETE_USER_COLUMNS_SETTINGS_ACTION:
    case FAILURE_UPDATE_USER_COLUMNS_SETTINGS_ACTION: {
      const { errors } = action.payload;

      return {
        ...state,
        data: {},
        error: true,
        ...errors
      };
    }
    case RESET_DELETE_USER_COLUMNS_SETTINGS_ACTION:
    case RESET_UPDATE_USER_COLUMNS_SETTINGS_ACTION: {
      return {
        ...state,
        columnSetting: {
          ...initialState.columnSetting
        }
      };
    }
    default:
      return state;
  }
};

export default userSettingsReducer;
