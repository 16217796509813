import _ from 'lodash';
import get from 'get-value';

export const onStatusUpdated = ({ data, ids, status, key }) => {
  return data.map((current) => {
    const {
      [key]: { id }
    } = current;

    if (ids.includes(id)) {
      return {
        ...current,
        [key]: {
          ...current[key],
          status
        }
      };
    }
    return current;
  });
};

export const filterId = ({ data, keyValue, keyId }) => {
  return data.filter((item) => keyValue !== get(item, keyId));
};

export const initSelection = ({ data, key, keyId }) => {
  const selection = {};

  for (let i = 0; i < data.length; i++) {
    const selectedId = get(data[i], keyId || `${key}.id`);

    Object.assign(selection, { [selectedId]: false });
  }

  return selection;
};

export const onToggleSelectAll = ({ selection, data, keyId, selectionDisabled }) => {
  if (selectionDisabled && selectionDisabled.length > 0) {
    return _.mapValues(selection, (selectedValue, selectedKey) => {
      const disabled = selectionDisabled.find((item) => get(item, keyId) === selectedKey);
      return disabled ? false : data.find((item) => get(item, keyId) === selectedKey);
    });
  }
  return _.mapValues(selection, (selectedValue, selectedKey) =>
    data.find((item) => get(item, keyId) === selectedKey)
  );
};

export const onToggleUnSelectAll = ({ selection }) => {
  return _.mapValues(selection, () => false);
};

export const getNamesFromSelection = ({ selection, keyName }) => {
  const names = Object.values(selection).reduce((prevItem, item) => {
    const name = get(item, keyName);

    if (name) {
      return [...prevItem, name];
    }

    return prevItem;
  }, []);

  return names;
};
