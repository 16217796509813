import {
  FAILURE_ADD_VATRATE_ACTION,
  PENDING_ADD_VATRATE_ACTION,
  SUCCESS_ADD_VATRATE_ACTION
} from '../actionsTypes';

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

const vatRateReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_ADD_VATRATE_ACTION:
      return {
        ...state,
        loading: true,
        error: false,
        errors: {}
      };
    case SUCCESS_ADD_VATRATE_ACTION: {
      const { vatRate } = action.payload;
      return {
        ...state,
        data: vatRate
      };
    }
    case FAILURE_ADD_VATRATE_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    default:
      return state;
  }
};

export default vatRateReducer;
