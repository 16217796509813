import {
  FAILURE_GET_CUSTOMERS_ACTION,
  PENDING_GET_CUSTOMERS_ACTION,
  SUCCESS_GET_CUSTOMERS_ACTION,
  SUCCESS_UPDATE_CUSTOMER_STATUS_ACTION
} from '../actionsTypes';
import { initSelection, onToggleSelectAll, onToggleUnSelectAll } from './selection';
import get from 'get-value';

const initialState = {
  data: [],
  selection: {},
  loading: false,
  error: false,
  errors: {}
};

const customersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_CUSTOMERS_ACTION:
      return {
        ...state,
        selection: {},
        loading: true,
        error: false
      };
    case SUCCESS_GET_CUSTOMERS_ACTION:
      const {
        payload: { customers }
      } = action;
      const selection = initSelection({ data: customers, keyId: 'id' });

      return {
        ...state,
        data: customers,
        selection
      };
    case FAILURE_GET_CUSTOMERS_ACTION:
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    case 'TOGGLE_SELECT_CUSTOMERS_ACTION':
    case 'TOGGLE_UNSELECT_CUSTOMERS_ACTION': {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find((customer) => get(customer, 'id') === id) : false
        }
      };
    }
    case 'TOGGLE_SELECT_ALL_CUSTOMERS_ACTION': {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: 'id'
      });

      return {
        ...state,
        selection
      };
    }
    case 'TOGGLE_UNSELECT_ALL_CUSTOMERS_ACTION': {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    case SUCCESS_UPDATE_CUSTOMER_STATUS_ACTION: {
      const {
        payload: { status, id }
      } = action;
      const { data } = state;
      const nextData = data.reduce((prevCustomer, customer) => {
        const { id: customerId } = customer;

        if (id === customerId) {
          return [
            ...prevCustomer,
            {
              ...customer,
              status
            }
          ];
        }

        return [...prevCustomer, customer];
      }, []);

      return {
        ...state,
        data: nextData
      };
    }
    default:
      return state;
  }
};

export default customersReducer;
