import {
  PENDING_GET_VATRATES_ACTION,
  SUCCESS_GET_VATRATES_ACTION,
  FAILURE_GET_VATRATES_ACTION,
  SUCCESS_DELETE_VATRATE_ACTION,
  SUCCESS_ADD_VATRATE_ACTION
} from '../actionsTypes';

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

const vatRatesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_VATRATES_ACTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS_GET_VATRATES_ACTION:
      const {
        payload: { vatRates: data }
      } = action;
      return {
        ...state,
        data
      };
    case FAILURE_GET_VATRATES_ACTION:
      const { errors } = action;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    case SUCCESS_DELETE_VATRATE_ACTION: {
      const vatRates = state.data;
      const {
        payload: { vatRatName }
      } = action;
      delete vatRates[vatRatName];
      return {
        ...state,
        data: vatRates
      };
    }
    case SUCCESS_ADD_VATRATE_ACTION: {
      const {
        vatRate: { name, value }
      } = action.payload;
      let vatRates = state.data;
      vatRates[name] = value;
      return {
        ...state,
        data: vatRates
      };
    }
    default:
      return state;
  }
};

export default vatRatesReducer;
