import { initSelection, onToggleSelectAll, onToggleUnSelectAll } from './selection';
import get from 'get-value';

const initialState = {
  data: [],
  loading: false,
  selection: {},
  error: false,
  errors: {}
};

const tagsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case `PENDING_GET_${action.name}_TAGS_ACTION`: {
      return {
        ...state,
        data: initialState.data,
        selection: {},
        loading: true,
        error: false,
        errors: {}
      };
    }
    case `SUCCESS_GET_${action.name}_TAGS_ACTION`: {
      const { tags: data } = action.payload;
      const selection = initSelection({ data, keyId: 'name' });

      return {
        ...state,
        data,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case `FAILURE_GET_${action.name}_TAGS_ACTION`: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case 'TOGGLE_SELECT_TAGS_ACTION':
    case 'TOGGLE_UNSELECT_TAGS_ACTION': {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find((tag) => get(tag, 'name') === id) : false
        }
      };
    }
    case 'TOGGLE_SELECT_ALL_TAGS_ACTION': {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: 'name'
      });

      return {
        ...state,
        selection
      };
    }
    case 'TOGGLE_UNSELECT_ALL_TAGS_ACTION': {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    case `RESET_${action.name}_TAGS_STATE_ACTION`: {
      return initialState;
    }
    default:
      return state;
  }
};

export default tagsReducer;
