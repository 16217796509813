import {
  FAILURE_GET_PALETTES_ACTION,
  PENDING_GET_PALETTES_ACTION,
  SUCCESS_GET_PALETTES_ACTION
} from '../actionsTypes';

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

const palettesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_PALETTES_ACTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS_GET_PALETTES_ACTION:
      const {
        payload: { palettes }
      } = action;

      return {
        ...state,
        data: palettes
      };
    case FAILURE_GET_PALETTES_ACTION:
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    default:
      return state;
  }
};

export default palettesReducer;
