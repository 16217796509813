import get from 'get-value';

import {
  PENDING_GET_RECONCILIATIONS_ACTION,
  SUCCESS_GET_RECONCILIATIONS_ACTION,
  FAILURE_GET_RECONCILIATIONS_ACTION,
  RESET_RECONCILIATIONS_ACTION,
  SUCCESS_GET_RECONCILIATION_RECEIPT_ACTION,
  FAILURE_GET_RECONCILIATION_RECEIPT_ACTION,
  OPEN_MERCHANT_RECEIPT_ACTION,
  CLOSE_MERCHANT_RECEIPT_ACTION,
  SUCCESS_GET_RECONCILIATIONS_SCROLL_ACTION
} from '../actionsTypes';
import { initSelection, onToggleSelectAll, onToggleUnSelectAll } from './selection';

const initialState = {
  data: [],
  selection: {},
  receiptHtml: '',
  transactionId: '',
  showMerchantPopin: false,
  loading: true,
  error: false,
  errors: {}
};

const reconciliationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_RECONCILIATIONS_ACTION: {
      return {
        ...state,
        selection: {},
        receiptHtml: '',
        transactionId: '',
        showMerchantPopin: false,
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_RECONCILIATIONS_ACTION: {
      const {
        payload: { count, scrollId, data }
      } = action;
      data.map((item) => {
        const { transactionId } = item;
        Object.assign(item, {
          reconciliation: {
            id: transactionId
          }
        });
        return item;
      });

      const selection = initSelection({ data, key: 'reconciliation' });

      return {
        ...state,
        data,
        selection,
        receiptHtml: '',
        transactionId: '',
        showMerchantPopin: false,
        count,
        scrollId,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_RECONCILIATIONS_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        data: initialState.data,
        selection: {},
        loading: false,
        error: true,
        ...errors
      };
    }
    case SUCCESS_GET_RECONCILIATIONS_SCROLL_ACTION: {
      const {
        payload: { data: scrollData }
      } = action;
      const data = [...state.data, ...scrollData];
      const selection = {
        ...initSelection({
          data,
          key: 'reconciliation',
          keyId: 'transactionId'
        }),
        ...state.selection
      };

      return {
        ...state,
        data,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case RESET_RECONCILIATIONS_ACTION: {
      return {
        ...state,
        ...initialState
      };
    }
    case OPEN_MERCHANT_RECEIPT_ACTION: {
      const {
        payload: { id }
      } = action;
      return {
        ...state,
        transactionId: id,
        showMerchantPopin: true
      };
    }
    case CLOSE_MERCHANT_RECEIPT_ACTION: {
      return {
        ...state,
        receiptHtml: '',
        transactionId: '',
        showMerchantPopin: false
      };
    }
    case OPEN_MERCHANT_RECEIPT_ACTION:
    case SUCCESS_GET_RECONCILIATION_RECEIPT_ACTION: {
      const {
        payload: { receiptHtml }
      } = action;

      return {
        ...state,
        receiptHtml
      };
    }
    case FAILURE_GET_RECONCILIATION_RECEIPT_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        error: true,
        ...errors
      };
    }
    case 'TOGGLE_SELECT_RECONCILIATIONS_ACTION':
    case 'TOGGLE_UNSELECT_RECONCILIATIONS_ACTION': {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find((reconciliation) => get(reconciliation, 'transactionId') === id) : false
        }
      };
    }
    case 'TOGGLE_SELECT_ALL_RECONCILIATIONS_ACTION': {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: 'transactionId'
      });

      return {
        ...state,
        selection
      };
    }
    case 'TOGGLE_UNSELECT_ALL_RECONCILIATIONS_ACTION': {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    default:
      return state;
  }
};

export default reconciliationsReducer;
