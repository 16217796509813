import {
  RESET_TRANSACTION_ALERT_STATE_ACTION,
  PENDING_GET_TRANSACTION_ALERT_ACTION,
  SUCCESS_GET_TRANSACTION_ALERT_ACTION,
  FAILURE_GET_TRANSACTION_ALERT_ACTION
} from '../actionsTypes';

const initialState = {
  data: {
    name: null,
    threshold: {
      timeWindow: 'Immediate',
      criteria: 'Count',
      value: null,
      operator: '=',
      triggerScope: 'onAll'
    },
    sendWith: {
      sms: false,
      email: false
    }
  },
  loading: false,
  error: false,
  errors: {}
};

const transactionAlertReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_TRANSACTION_ALERT_ACTION: {
      return {
        ...state,
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_TRANSACTION_ALERT_ACTION: {
      const {
        payload: { transactionAlert }
      } = action;

      return {
        data: {
          ...transactionAlert
        },
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_TRANSACTION_ALERT_ACTION: {
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case RESET_TRANSACTION_ALERT_STATE_ACTION:
      return initialState;
    default:
      return state;
  }
};

export default transactionAlertReducer;
