import {
  PENDING_GET_CONFIG_ACTION,
  SUCCESS_GET_CONFIG_ACTION,
  FAILURE_GET_CONFIG_ACTION
} from '../actionsTypes';

const initialState = () => ({
  data: {},
  isLoaded: false,
  loading: false,
  error: false,
  errors: {}
});

export const defaultInitialState = initialState();

const configReducer = (state = defaultInitialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_CONFIG_ACTION: {
      return {
        ...state,
        data: {},
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_CONFIG_ACTION: {
      const {
        payload: { config: data }
      } = action;

      return {
        ...state,
        data,
        isLoaded: true,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_CONFIG_ACTION: {
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        data: {},
        loading: false,
        error: true,
        errors
      };
    }
    default:
      return state;
  }
};

export default configReducer;
