const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

const fieldsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case `PENDING_GET_FIELDS_ACTION_${action.name}`:
      return {
        ...state,
        loading: true,
        error: false
      };
    case `SUCCESS_GET_FIELDS_ACTION_${action.name}`:
      const {
        payload: { fields }
      } = action;
      return {
        ...state,
        data: [...fields]
      };
    case `FAILURE_GET_FIELDS_ACTION_${action.name}`:
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    default:
      return state;
  }
};

export default fieldsReducer;
