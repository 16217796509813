import get from 'get-value';
import {
  PENDING_GET_CATALOGITEMS_ACTION,
  SUCCESS_GET_CATALOGITEMS_ACTION,
  SUCCESS_GET_CATALOGITEMS_SCROLL_ACTION,
  FAILURE_GET_CATALOGITEMS_ACTION,
  SUCCESS_DELETE_CATALOGITEM_ACTION,
  RESET_CATALOGITEMS_ACTION
} from '../actionsTypes';
import { initSelection, onToggleSelectAll, onToggleUnSelectAll, filterId } from './selection';

import { reduceCount } from '../actions/sseActions';

const initialState = {
  data: [],
  selection: {},
  loading: true,
  error: false,
  errors: {}
};

const catalogItemsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_CATALOGITEMS_ACTION: {
      return {
        ...state,
        selection: {},
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_CATALOGITEMS_ACTION: {
      const {
        payload: { count, scrollId, data }
      } = action;

      const selection = initSelection({ data, key: 'catalogItem' });

      return {
        ...state,
        data,
        selection,
        count,
        scrollId,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_CATALOGITEMS_SCROLL_ACTION: {
      const {
        payload: { data: scrollData }
      } = action;
      const data = [...state.data, ...scrollData];
      const selection = {
        ...initSelection({ data, key: 'catalogItem' }),
        ...state.selection
      };

      return {
        ...state,
        data,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_CATALOGITEMS_ACTION: {
      const { errors } = action;

      return {
        ...state,
        data: initialState.data,
        selection: {},
        loading: false,
        error: true,
        ...errors
      };
    }
    case RESET_CATALOGITEMS_ACTION: {
      return {
        ...state,
        ...initialState
      };
    }
    case 'TOGGLE_SELECT_CATALOGS_ACTION':
    case 'TOGGLE_UNSELECT_CATALOGS_ACTION': {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find((catalogItem) => get(catalogItem, 'catalogItem.id') === id) : false
        }
      };
    }
    case 'TOGGLE_SELECT_ALL_CATALOGS_ACTION': {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: 'catalogItem.id'
      });

      return {
        ...state,
        selection
      };
    }
    case 'TOGGLE_UNSELECT_ALL_CATALOGS_ACTION': {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    case SUCCESS_DELETE_CATALOGITEM_ACTION: {
      const {
        payload: { id }
      } = action;

      const { data, selection: prevSelection, count } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });
      const nextData = filterId({
        data,
        keyValue: id,
        keyId: 'catalogItem.id'
      });
      const countNumber = parseInt(count);
      const nextCount = reduceCount({ countNumber, data, nextData });

      return {
        ...state,
        selection,
        count: nextCount.toString(),
        data: nextData
      };
    }
    default:
      return state;
  }
};

export default catalogItemsReducer;
