import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

import { composeWithDevTools } from 'redux-devtools-extension';

import { mediaQuery } from './reducers/theme';

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

// https://stackoverflow.com/a/35075190

if (mediaQuery) {
  try {
    mediaQuery.addEventListener('change', async () => {
      const { getDarkMode } = await import('./actions');

      store.dispatch(getDarkMode());
    });
  } catch {
    // https://github.com/kazzkiq/darkmode/pull/4 : Fallback for Safari < 14 and older browsers
    mediaQuery.addListener(async () => {
      const { getDarkMode } = await import('./actions');

      store.dispatch(getDarkMode());
    });
  }
}
