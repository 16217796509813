import {
  FAILURE_GET_PROGRAMS_ACTION,
  PENDING_GET_PROGRAMS_ACTION,
  SUCCESS_GET_PROGRAMS_ACTION,
  SUCCESS_DELETE_PROGRAM_ACTION
} from '../actionsTypes';
import { initSelection, onToggleSelectAll, onToggleUnSelectAll } from './selection';
import get from 'get-value';

const initialState = {
  data: [],
  loading: false,
  selection: {},
  error: false,
  errors: {}
};

const _onProgramDeleted = ({ programId, programs }) => {
  return programs.filter((program) => {
    const { id } = program;

    return id !== programId;
  });
};

const programsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_PROGRAMS_ACTION:
      return {
        ...state,
        selection: {},
        loading: true,
        error: false
      };
    case SUCCESS_GET_PROGRAMS_ACTION:
      const { programs } = action.payload;
      const selection = initSelection({ data: programs, keyId: 'id' });

      return {
        ...state,
        ...initialState,
        data: programs,
        selection
      };
    case FAILURE_GET_PROGRAMS_ACTION:
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };

    case 'TOGGLE_SELECT_PROGRAMS_ACTION':
    case 'TOGGLE_UNSELECT_PROGRAMS_ACTION': {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find((program) => get(program, 'id') === id) : false
        }
      };
    }
    case 'TOGGLE_SELECT_ALL_PROGRAMS_ACTION': {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: 'id'
      });

      return {
        ...state,
        selection
      };
    }
    case 'TOGGLE_UNSELECT_ALL_PROGRAMS_ACTION': {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    case SUCCESS_DELETE_PROGRAM_ACTION: {
      const {
        payload: { programId }
      } = action;
      const { data: prevPrograms, selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });
      const programs = _onProgramDeleted({ programId, programs: prevPrograms });

      return {
        ...state,
        selection,
        data: programs
      };
    }
    default:
      return state;
  }
};

export default programsReducer;
