import {
  PENDING_GET_SECOND_FACTORS_ACTION,
  SUCCESS_GET_SECOND_FACTORS_ACTION,
  FAILURE_GET_SECOND_FACTORS_ACTION
} from '../actionsTypes';

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

const secondFactorsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_SECOND_FACTORS_ACTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS_GET_SECOND_FACTORS_ACTION:
      const {
        payload: { secondFactors }
      } = action;

      return {
        ...state,
        data: secondFactors
      };
    case FAILURE_GET_SECOND_FACTORS_ACTION:
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    default:
      return state;
  }
};

export default secondFactorsReducer;
