import { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import Routes from './routes';
import languages from '../../public/languages/languages.json';
import './apexcharts';
import './i18n';

import { store } from './redux/store';

//Load all locales for numeral then render
(async () => {
  await Promise.all(
    languages.numeralLanguages.map((lng) => {
      const lang = lng === 'en' ? 'en-gb' : lng;

      return import(`numeral/locales/${lang}`);
    })
  );

  return render(
    <Provider store={store}>
      <Suspense fallback={null}>
        <Routes />
      </Suspense>
    </Provider>,
    document.getElementById('root')
  );
})();
