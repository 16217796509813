import {
  RESET_CUSTOMER_STATE_ACTION,
  PENDING_GET_CUSTOMER_ACTION,
  SUCCESS_GET_CUSTOMER_ACTION,
  FAILURE_GET_CUSTOMER_ACTION
} from '../actionsTypes';

const initialState = {
  data: null,
  loading: false,
  error: false,
  errors: {}
};

const customerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_CUSTOMER_ACTION: {
      return {
        ...state,
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_CUSTOMER_ACTION: {
      const {
        payload: { customer }
      } = action;

      return {
        data: customer,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_CUSTOMER_ACTION: {
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case RESET_CUSTOMER_STATE_ACTION:
      return initialState;
    default:
      return state;
  }
};

export default customerReducer;
