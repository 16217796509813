import {
  PENDING_GET_SIMS_ACTIVITIES_ACTION,
  SUCCESS_GET_SIMS_ACTIVITIES_ACTION,
  FAILURE_GET_SIMS_ACTIVITIES_ACTION,
  RESET_SIMS_ACTIVITIES_ACTION,
  SUCCESS_UPDATE_STATUS_SIMS_ACTIVITIES_ACTION,
  SUCCESS_GET_SIMS_ACTIVITIES_SCROLL_ACTION,
  PENDING_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION,
  SUCCESS_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION,
  FAILURE_UPDATE_STATUS_SIMS_ACTIVITIES_ACTION,
  RESET_SIMS_ACTIVITIES_CONNECTIONS_ACTION
} from '../actionsTypes';
import { initSelection, onStatusUpdated, onToggleSelectAll, onToggleUnSelectAll } from './selection';
import get from 'get-value';

const initialState = {
  data: [],
  selection: {},
  loading: true,
  error: false,
  errors: {},
  connections: {
    data: [],
    loading: true,
    error: false,
    errors: {}
  }
};

const simsActivitiesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_SIMS_ACTIVITIES_ACTION: {
      return {
        ...state,
        loading: true,
        selection: {},
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_SIMS_ACTIVITIES_ACTION: {
      const {
        payload: { count, scrollId, data }
      } = action;

      const selection = initSelection({ data, key: 'sim' });

      return {
        ...state,
        data,
        selection,
        count,
        scrollId,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_SIMS_ACTIVITIES_SCROLL_ACTION: {
      const {
        payload: { data: scrollData }
      } = action;
      const data = [...state.data, ...scrollData];
      const selection = {
        ...initSelection({ data, key: 'sim' }),
        ...state.selection
      };

      return {
        ...state,
        data,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_SIMS_ACTIVITIES_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        selection: {},
        loading: false,
        error: true,
        ...errors
      };
    }
    case RESET_SIMS_ACTIVITIES_ACTION: {
      return {
        ...state,
        ...initialState
      };
    }
    case `TOGGLE_SELECT_SIMS_ACTIVITIES_ACTION`:
    case `TOGGLE_UNSELECT_SIMS_ACTIVITIES_ACTION`: {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find((sim) => get(sim, 'sim.id') === id) : false
        }
      };
    }
    case `TOGGLE_SELECT_ALL_SIMS_ACTIVITIES_ACTION`: {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: 'sim.id'
      });

      return {
        ...state,
        selection
      };
    }
    case `TOGGLE_UNSELECT_ALL_SIMS_ACTIVITIES_ACTION`: {
      const { selection } = state;
      const nextSelection = onToggleUnSelectAll({ selection });
      return {
        ...state,
        selection: nextSelection
      };
    }
    case SUCCESS_UPDATE_STATUS_SIMS_ACTIVITIES_ACTION: {
      const {
        payload: { status, ids }
      } = action;
      const { data } = state;
      const nextData = onStatusUpdated({ data, ids, status, key: 'sim' });
      return {
        ...state,
        data: nextData
      };
    }
    case PENDING_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION: {
      return {
        ...state,
        connections: {
          ...state.connections,
          loading: true,
          error: false,
          errors: {}
        }
      };
    }
    case SUCCESS_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION: {
      const {
        payload: { data }
      } = action;

      return {
        ...state,
        connections: {
          ...state.connections,
          data,
          loading: false,
          error: false,
          errors: {}
        }
      };
    }
    case FAILURE_UPDATE_STATUS_SIMS_ACTIVITIES_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        connections: {
          ...state.connections,
          data: initialState.data,
          loading: false,
          error: true,
          ...errors
        }
      };
    }
    case RESET_SIMS_ACTIVITIES_CONNECTIONS_ACTION: {
      return {
        ...state,
        connections: {
          ...initialState.connections
        }
      };
    }

    default:
      return state;
  }
};

export default simsActivitiesReducer;
