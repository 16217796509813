import ky from 'ky';

import {
  PENDING_AUTH_ACTION,
  SUCCESS_AUTH_ACTION,
  FAILURE_AUTH_ACTION,
  PENDING_GET_2FA_PAIR_ACTION,
  SUCCESS_GET_2FA_PAIR_ACTION,
  FAILURE_GET_2FA_PAIR_ACTION,
  FAILURE_REFRESH_AUTH_ACTION,
  PENDING_REFRESH_AUTH_ACTION,
  SUCCESS_REFRESH_AUTH_ACTION,
  SUCCESS_IMPERSONATE_ACTION,
  PENDING_IMPERSONATE_ACTION,
  FAILURE_IMPERSONATE_ACTION,
  PENDING_GET_USER_SETTINGS_ACTION,
  SUCCESS_GET_USER_SETTINGS_ACTION,
  FAILURE_GET_USER_SETTINGS_ACTION,
  PENDING_GET_LEVEL_SETTINGS_ACTION,
  SUCCESS_GET_LEVEL_SETTINGS_ACTION,
  FAILURE_GET_LEVEL_SETTINGS_ACTION,
  PENDING_REVOKE_ACTION,
  SUCCESS_REVOKE_ACTION,
  FAILURE_REVOKE_ACTION,
  SET_OAUTH_REDIRECT_URI_ACTION,
  PENDING_RESET_PASSWORD_ACTION,
  SUCCESS_RESET_PASSWORD_ACTION,
  FAILURE_RESET_PASSWORD_ACTION,
  PENDING_UPDATE_PASSWORD_ACTION,
  SUCCESS_UPDATE_PASSWORD_ACTION,
  FAILURE_UPDATE_PASSWORD_ACTION,
  PENDING_CREATE_TOKEN_ACTION,
  SUCCESS_CREATE_TOKEN_ACTION,
  FAILURE_CREATE_TOKEN_ACTION
} from '../actionsTypes';
import { generateUrl } from '../../commons/utils/url';
import * as ContentTypes from '../../commons/constants/HTTPContentTypes';
import history from '../../history';

export const AUTHENTICATION_STORAGE_KEY = 'currentUser-v2';
export const AUTHENTICATION_STORAGE_LEVEL_SETTINGS = 'levelSettings';
export const OAUTH_REDIRECT_URI_STORAGE_KEY = 'oauthRedirectUri';
export const OAUTH_STATE_STORAGE_KEY = 'oauthState';
export const OAUTH_TOKEN_STORAGE_KEY = 'token';
export const OAUTH_TOKEN_EXPIRES_AT_STORAGE_KEY = 'tokenExpiresAt';

export const authenticate = ({ code, redirect_uri, client_id }) => (dispatch) => {
  dispatch(authStart());

  const body = {
    grant_type: 'authorization_code',
    code,
    redirect_uri,
    client_id
  };

  const searchParams = new URLSearchParams();
  Object.entries(body).forEach((param) => searchParams.set(param[0], param[1]));

  const url = generateUrl({ url: '/oauth2/token', version: 'v2' });

  return ky
    .post(url, {
      body: searchParams
    })
    .json()
    .then(
      (token) => {
        const { access_token: accessToken, expires_in } = token;
        const tokenExpiresAt = Date.now() + expires_in * 1000;

        localStorage.setItem(JWT_KEY, accessToken);
        localStorage.setItem(OAUTH_TOKEN_STORAGE_KEY, JSON.stringify(token));
        localStorage.setItem(OAUTH_TOKEN_EXPIRES_AT_STORAGE_KEY, tokenExpiresAt);

        dispatch(authSucceeded({ token, tokenExpiresAt }));
      },
      (errors) => {
        dispatch(authFailure({ errors }));

        throw errors;
      }
    );
};

const authStart = () => ({
  type: PENDING_AUTH_ACTION
});

const authSucceeded = ({ token, tokenExpiresAt }) => ({
  type: SUCCESS_AUTH_ACTION,
  payload: {
    token,
    tokenExpiresAt
  }
});

const authFailure = (errors) => ({
  type: FAILURE_AUTH_ACTION,
  payload: {
    errors
  }
});

export const impersonate = (impersonated) => async (dispatch) => {
  dispatch(impersonateStart());

  const { default: F } = await import('../../commons/HTTPFetcher');
  const { refresh_token } = F;

  const body = {
    refresh_token,
    impersonated
  };
  const { FORM: contentType } = ContentTypes;

  return F.post(
    '/oauth2/impersonate',
    {
      body,
      contentType
    },
    'v2'
  ).then(
    (token) => {
      const { access_token: accessToken, expires_in } = token;
      const tokenExpiresAt = Date.now() + expires_in * 1000;

      localStorage.setItem(JWT_KEY, accessToken);
      localStorage.setItem(OAUTH_TOKEN_STORAGE_KEY, JSON.stringify(token));
      localStorage.setItem(OAUTH_TOKEN_EXPIRES_AT_STORAGE_KEY, tokenExpiresAt);

      dispatch(impersonateSucceeded({ token, tokenExpiresAt }));
    },
    (errors) => {
      dispatch(impersonateFailure({ errors }));

      throw errors;
    }
  );
};

const impersonateStart = () => ({
  type: PENDING_IMPERSONATE_ACTION
});

const impersonateSucceeded = ({ token, tokenExpiresAt }) => ({
  type: SUCCESS_IMPERSONATE_ACTION,
  payload: {
    token,
    tokenExpiresAt
  }
});

const impersonateFailure = (errors) => ({
  type: FAILURE_IMPERSONATE_ACTION,
  payload: {
    errors
  }
});

export const getUserSettings = () => (dispatch) => {
  dispatch(getUserSettingsStart());

  const Authorization = `Bearer ${localStorage.getItem(JWT_KEY)}`;

  const url = generateUrl({ url: '/me' });

  return ky(url, {
    headers: {
      Authorization
    }
  })
    .json()
    .then(
      (user) => {
        const { settings, ...restUser } = user;
        const currentUser = { ...restUser, ...settings };
        localStorage.setItem(AUTHENTICATION_STORAGE_KEY, JSON.stringify(currentUser));

        dispatch(getUserSettingsSucceeded(currentUser));

        return currentUser;
      },
      (errors) => {
        dispatch(getUserSettingsFailure(errors));

        throw errors;
      }
    );
};

export const getUserSettingsFetcher = () => async (dispatch) => {
  dispatch(getUserSettingsStart());

  const { default: F } = await import('../../commons/HTTPFetcher');
  const token = localStorage.getItem(OAUTH_TOKEN_STORAGE_KEY);

  F.updateTokenFrom(token);

  return F.get('/me').then(
    (user) => {
      const { settings, ...restUser } = user;
      const currentUser = { ...restUser, ...settings };
      localStorage.setItem(AUTHENTICATION_STORAGE_KEY, JSON.stringify(currentUser));

      dispatch(getUserSettingsSucceeded(currentUser));

      return currentUser;
    },
    (errors) => {
      dispatch(getUserSettingsFailure(errors));

      throw errors;
    }
  );
};

const getUserSettingsStart = () => ({
  type: PENDING_GET_USER_SETTINGS_ACTION
});

const getUserSettingsSucceeded = (user) => ({
  type: SUCCESS_GET_USER_SETTINGS_ACTION,
  payload: {
    user
  }
});

const getUserSettingsFailure = (errors) => ({
  type: FAILURE_GET_USER_SETTINGS_ACTION,
  payload: {
    errors
  }
});

export const getLevelSettings = () => (dispatch) => {
  dispatch(getLevelSettingsStart());

  const Authorization = `Bearer ${localStorage.getItem(JWT_KEY)}`;

  const url = generateUrl({ url: '/customer/me/setting' });

  return ky
    .get(url, {
      headers: {
        Authorization
      }
    })
    .json()
    .then(
      (levelSettings) => {
        localStorage.setItem(AUTHENTICATION_STORAGE_LEVEL_SETTINGS, JSON.stringify(levelSettings));

        dispatch(getLevelSettingsSucceeded(levelSettings));

        return levelSettings;
      },
      (errors) => {
        dispatch(getLevelSettingsFailure(errors));

        throw errors;
      }
    );
};

export const getLevelSettingsFetcher = () => async (dispatch) => {
  dispatch(getLevelSettingsStart());

  const { default: F } = await import('../../commons/HTTPFetcher');
  const token = localStorage.getItem(OAUTH_TOKEN_STORAGE_KEY);

  F.updateTokenFrom(token);

  return F.get('/customer/me/setting').then(
    (levelSettings) => {
      localStorage.setItem(AUTHENTICATION_STORAGE_LEVEL_SETTINGS, JSON.stringify(levelSettings));

      dispatch(getLevelSettingsSucceeded(levelSettings));

      return levelSettings;
    },
    (errors) => {
      dispatch(getLevelSettingsFailure(errors));

      throw errors;
    }
  );
};

const getLevelSettingsStart = () => ({
  type: PENDING_GET_LEVEL_SETTINGS_ACTION
});

const getLevelSettingsSucceeded = (levelSettings) => ({
  type: SUCCESS_GET_LEVEL_SETTINGS_ACTION,
  payload: {
    levelSettings
  }
});

const getLevelSettingsFailure = (errors) => ({
  type: FAILURE_GET_LEVEL_SETTINGS_ACTION,
  payload: {
    errors
  }
});

export const refresh = (refresh_token) => (dispatch) => {
  dispatch(refreshStart());

  const body = {
    grant_type: 'refresh_token',
    refresh_token
  };

  const searchParams = new URLSearchParams();
  Object.entries(body).forEach((param) => searchParams.set(param[0], param[1]));

  const url = generateUrl({ url: '/oauth2/token', version: 'v2' });

  return ky
    .post(url, {
      body: searchParams
    })
    .json()
    .then(
      (token) => {
        const { access_token: accessToken, expires_in } = token;
        const tokenExpiresAt = Date.now() + expires_in * 1000;

        localStorage.setItem(JWT_KEY, accessToken);
        localStorage.setItem(OAUTH_TOKEN_STORAGE_KEY, JSON.stringify(token));
        localStorage.setItem(OAUTH_TOKEN_EXPIRES_AT_STORAGE_KEY, tokenExpiresAt);

        dispatch(refreshSucceeded({ token, tokenExpiresAt }));

        return token;
      },
      (errors) => {
        const redirectUri = `${window.location.origin}${window.location.pathname}`;
        const state = window.location.search;

        localStorage.removeItem(JWT_KEY);
        localStorage.removeItem(OAUTH_TOKEN_EXPIRES_AT_STORAGE_KEY);
        localStorage.removeItem(OAUTH_TOKEN_STORAGE_KEY);
        localStorage.removeItem(OAUTH_REDIRECT_URI_STORAGE_KEY);
        localStorage.removeItem(OAUTH_STATE_STORAGE_KEY);
        localStorage.removeItem(AUTHENTICATION_STORAGE_KEY);
        localStorage.removeItem(AUTHENTICATION_STORAGE_LEVEL_SETTINGS);

        dispatch(setAuthRedirectUriAndState({ redirectUri, state }));

        dispatch(refreshFailure(errors));

        throw errors;
      }
    );
};

const refreshStart = () => ({
  type: PENDING_REFRESH_AUTH_ACTION
});

const refreshSucceeded = ({ token, tokenExpiresAt }) => ({
  type: SUCCESS_REFRESH_AUTH_ACTION,
  payload: {
    token,
    tokenExpiresAt
  }
});

const refreshFailure = (errors) => ({
  type: FAILURE_REFRESH_AUTH_ACTION,
  payload: {
    errors
  }
});

export const revoke = (token) => (dispatch) => {
  dispatch(revokeStart());

  const body = {
    token
  };

  const searchParams = new URLSearchParams();
  Object.entries(body).forEach((param) => searchParams.set(param[0], param[1]));

  const url = generateUrl({ url: '/oauth2/revoke', version: 'v2' });

  return ky
    .post(url, {
      body: searchParams
    })
    .json()
    .then(
      async () => {
        localStorage.removeItem(JWT_KEY);
        localStorage.removeItem(OAUTH_TOKEN_EXPIRES_AT_STORAGE_KEY);
        localStorage.removeItem(OAUTH_TOKEN_STORAGE_KEY);
        localStorage.removeItem(OAUTH_REDIRECT_URI_STORAGE_KEY);
        localStorage.removeItem(OAUTH_STATE_STORAGE_KEY);
        localStorage.removeItem(AUTHENTICATION_STORAGE_KEY);
        localStorage.removeItem(AUTHENTICATION_STORAGE_LEVEL_SETTINGS);

        dispatch(revokeSucceeded());

        const { default: F } = await import('../../commons/HTTPFetcher');
        F.init(), history.push('/authenticate');
      },
      (errors) => {
        dispatch(revokeFailure(errors));

        throw errors;
      }
    );
};

const revokeStart = () => ({
  type: PENDING_REVOKE_ACTION
});

const revokeSucceeded = () => ({
  type: SUCCESS_REVOKE_ACTION
});

const revokeFailure = (errors) => ({
  type: FAILURE_REVOKE_ACTION,
  payload: {
    errors
  }
});

export const setAuthRedirectUriAndState = ({ redirectUri, state: oauthState }) => {
  localStorage.setItem(OAUTH_REDIRECT_URI_STORAGE_KEY, redirectUri);
  localStorage.setItem(OAUTH_STATE_STORAGE_KEY, oauthState);

  return {
    type: SET_OAUTH_REDIRECT_URI_ACTION,
    payload: {
      redirectUri,
      oauthState
    }
  };
};

const resetPasswordStart = () => ({
  type: PENDING_RESET_PASSWORD_ACTION
});

const resetPasswordSucceeded = () => ({
  type: SUCCESS_RESET_PASSWORD_ACTION
});

const resetPasswordFailure = (errors) => ({
  type: FAILURE_RESET_PASSWORD_ACTION,
  payload: {
    errors
  }
});

export const resetPassword = ({ data }) => async (dispatch) => {
  dispatch(resetPasswordStart());

  const { default: F } = await import('../../commons/HTTPFetcher');
  const { JSON: contentType } = ContentTypes;

  return F.put('/users/password/reset', {
    body: data,
    contentType
  }).then(
    () => dispatch(resetPasswordSucceeded()),
    (errors) => {
      dispatch(resetPasswordFailure(errors));

      throw errors;
    }
  );
};

const updatePasswordStart = () => ({
  type: PENDING_UPDATE_PASSWORD_ACTION
});

const updatePasswordSucceeded = () => ({
  type: SUCCESS_UPDATE_PASSWORD_ACTION
});

const updatePasswordFailure = (errors) => ({
  type: FAILURE_UPDATE_PASSWORD_ACTION,
  payload: {
    errors
  }
});

export const updatePassword = ({ login, password, newPassword }) => async (dispatch) => {
  dispatch(updatePasswordStart());

  const { default: F } = await import('../../commons/HTTPFetcher');
  const { JSON: contentType } = ContentTypes;

  return F.put(`/users/${login}/password`, {
    body: {
      currentPassword: password,
      newPassword
    },
    contentType
  }).then(
    () => dispatch(updatePasswordSucceeded()),
    (errors) => {
      dispatch(updatePasswordFailure(errors));
      throw errors;
    }
  );
};

const createTokenStart = () => ({
  type: PENDING_CREATE_TOKEN_ACTION
});

const createTokenSucceeded = () => ({
  type: SUCCESS_CREATE_TOKEN_ACTION
});

const createTokenFailure = (errors) => ({
  type: FAILURE_CREATE_TOKEN_ACTION,
  payload: {
    errors
  }
});

export const createToken = ({ login, captcha }) => async (dispatch) => {
  dispatch(createTokenStart());

  const { default: F } = await import('../../commons/HTTPFetcher');
  const { JSON: contentType } = ContentTypes;

  return F.post(
    '/password/token',
    {
      body: { login, captcha },
      contentType
    },
    'v2'
  ).then(
    () => dispatch(createTokenSucceeded()),
    (errors) => {
      dispatch(createTokenFailure(errors));

      throw errors;
    }
  );
};

const get2FAPairStart = () => ({
  type: PENDING_GET_2FA_PAIR_ACTION
});

const get2FAPairSucceeded = (pair) => ({
  type: SUCCESS_GET_2FA_PAIR_ACTION,
  payload: {
    pair
  }
});

const get2FAPairFailure = (errors) => ({
  type: FAILURE_GET_2FA_PAIR_ACTION,
  payload: {
    errors
  }
});

export const get2FAPair = () => async (dispatch) => {
  dispatch(get2FAPairStart());

  const url = generateUrl({
    url: '/authenticate/two-factor/pair',
    version: 'v2'
  });

  return ky
    .get(url)
    .json()
    .then(
      ({ secret, issuer, login }) => {
        const data = `otpauth://totp/${login}?secret=${secret}&issuer=${issuer}`;

        const { QrCode: QRC } = qrcodegen;
        const qr0 = QRC.encodeText(data, QRC.Ecc.MEDIUM);
        const svg = qr0.toSvgString(4);

        dispatch(get2FAPairSucceeded(svg));
      },
      (errors) => {
        dispatch(get2FAPairFailure(errors));

        throw errors;
      }
    );
};
