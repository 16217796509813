import {
  FAILURE_GET_TIMEZONES_ACTION,
  PENDING_GET_TIMEZONES_ACTION,
  SUCCESS_GET_TIMEZONES_ACTION,
  RESET_TIMEZONES_STATE_ACTION
} from '../actionsTypes';

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

const timezonesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_TIMEZONES_ACTION:
      return {
        ...state,
        loading: true,
        error: false,
        errors: {}
      };
    case SUCCESS_GET_TIMEZONES_ACTION:
      const { timezones: data } = action.payload;
      return {
        ...state,
        data,
        loading: false,
        error: false,
        errors: {}
      };
    case FAILURE_GET_TIMEZONES_ACTION:
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    case RESET_TIMEZONES_STATE_ACTION: {
      return initialState;
    }
    default:
      return state;
  }
};

export default timezonesReducer;
