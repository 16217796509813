import get from 'get-value';
import {
  PENDING_GET_TAX_AUDIT_LOG_ACTION,
  PENDING_GET_TAX_AUDIT_LOG_SCROLL_ACTION,
  SUCCESS_GET_TAX_AUDIT_LOG_SCROLL_ACTION,
  SUCCESS_GET_TAX_AUDIT_LOG_ACTION,
  FAILURE_GET_TAX_AUDIT_LOG_ACTION,
  RESET_TAX_AUDIT_LOG_ACTION,
  SUCCESS_UPDATE_STATUS_TAX_AUDIT_LOG_ACTION
} from '../actionsTypes';

import { initSelection, onStatusUpdated, onToggleSelectAll, onToggleUnSelectAll } from './selection';

import { reduceCount } from '../actions/sseActions';

const initialState = {
  data: [],
  selection: {},
  loading: true,
  error: false,
  errors: {}
};

const logReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_TAX_AUDIT_LOG_ACTION: {
      return {
        ...state,
        selection: {},
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_TAX_AUDIT_LOG_ACTION: {
      const {
        payload: { count, scrollId, data }
      } = action;
      const selection = initSelection({ data, key: 'logs' });
      return {
        ...state,
        data,
        selection,
        count,
        scrollId,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case PENDING_GET_TAX_AUDIT_LOG_SCROLL_ACTION: {
      return { ...state, loading: true };
    }
    case SUCCESS_GET_TAX_AUDIT_LOG_SCROLL_ACTION: {
      const {
        payload: { data: scrollData, scrollId }
      } = action;
      const data = [...state.data, ...scrollData];
      const selection = {
        ...initSelection({ data, key: 'logs' }),
        ...state.selection
      };

      return {
        ...state,
        data,
        scrollId,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_TAX_AUDIT_LOG_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        data: initialState.data,
        selection: {},
        count: 0,
        loading: false,
        error: true,
        ...errors
      };
    }
    case RESET_TAX_AUDIT_LOG_ACTION: {
      return {
        ...state,
        ...initialState
      };
    }
    case 'TOGGLE_SELECT_TAX_AUDIT_LOG_ACTION':
    case 'TOGGLE_UNSELECT_TAX_AUDIT_LOG_ACTION': {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find((store) => get(store, 'log.id') === id) : false
        }
      };
    }
    case 'TOGGLE_SELECT_ALL_TAX_AUDIT_LOG_ACTION': {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: 'log.id'
      });

      return {
        ...state,
        selection
      };
    }
    case 'TOGGLE_UNSELECT_ALL_TAX_AUDIT_LOG_ACTION': {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    case SUCCESS_UPDATE_STATUS_TAX_AUDIT_LOG_ACTION: {
      const {
        payload: { status, ids }
      } = action;
      const { data } = state;
      const nextData = onStatusUpdated({ data, ids, status, key: 'logs' });

      return {
        ...state,
        data: nextData
      };
    }
    default:
      return state;
  }
};

export default logReducer;
