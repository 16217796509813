export const SUCCESS_AUTH_ACTION = 'SUCCESS_AUTH_ACTION';
export const PENDING_AUTH_ACTION = 'PENDING_AUTH_ACTION';
export const FAILURE_AUTH_ACTION = 'FAILURE_AUTH_ACTION';

export const SUCCESS_GET_2FA_PAIR_ACTION = 'SUCCESS_GET_2FA_PAIR_ACTION';
export const PENDING_GET_2FA_PAIR_ACTION = 'PENDING_GET_2FA_PAIR_ACTION';
export const FAILURE_GET_2FA_PAIR_ACTION = 'FAILURE_GET_2FA_PAIR_ACTION';

export const SUCCESS_CREATE_TOKEN_ACTION = 'SUCCESS_CREATE_TOKEN_ACTION';
export const PENDING_CREATE_TOKEN_ACTION = 'PENDING_CREATE_TOKEN_ACTION';
export const FAILURE_CREATE_TOKEN_ACTION = 'FAILURE_CREATE_TOKEN_ACTION';

export const SUCCESS_REFRESH_AUTH_ACTION = 'SUCCESS_REFRESH_AUTH_ACTION';
export const PENDING_REFRESH_AUTH_ACTION = 'PENDING_REFRESH_AUTH_ACTION';
export const FAILURE_REFRESH_AUTH_ACTION = 'FAILURE_REFRESH_AUTH_ACTION';

export const SUCCESS_IMPERSONATE_ACTION = 'SUCCESS_IMPERSONATE_ACTION';
export const PENDING_IMPERSONATE_ACTION = 'PENDING_IMPERSONATE_ACTION';
export const FAILURE_IMPERSONATE_ACTION = 'FAILURE_IMPERSONATE_ACTION';

export const SUCCESS_GET_USER_SETTINGS_ACTION = 'SUCCESS_GET_USER_SETTINGS_ACTION';
export const PENDING_GET_USER_SETTINGS_ACTION = 'PENDING_GET_USER_SETTINGS_ACTION';
export const FAILURE_GET_USER_SETTINGS_ACTION = 'FAILURE_GET_USER_SETTINGS_ACTION';

export const SUCCESS_GET_LEVEL_SETTINGS_ACTION = 'SUCCESS_GET_LEVEL_SETTINGS_ACTION';
export const PENDING_GET_LEVEL_SETTINGS_ACTION = 'PENDING_GET_LEVEL_SETTINGS_ACTION';
export const FAILURE_GET_LEVEL_SETTINGS_ACTION = 'FAILURE_GET_LEVEL_SETTINGS_ACTION';

export const SUCCESS_REVOKE_ACTION = 'SUCCESS_REVOKE_ACTION';
export const PENDING_REVOKE_ACTION = 'PENDING_REVOKE_ACTION';
export const FAILURE_REVOKE_ACTION = 'FAILURE_REVOKE_ACTION';

export const SUCCESS_REVOKE_SECOND_FACTOR_ACTION = 'SUCCESS_REVOKE_SECOND_FACTOR_ACTION';
export const PENDING_REVOKE_SECOND_FACTOR_ACTION = 'PENDING_REVOKE_SECOND_FACTORACTION';
export const FAILURE_REVOKE_SECOND_FACTOR_ACTION = 'FAILURE_REVOKE_SECOND_FACTOR_ACTION';

export const SUCCESS_HAS_SECOND_FACTOR_ACTION = 'SUCCESS_HAS_SECOND_FACTOR_ACTION';
export const PENDING_HAS_SECOND_FACTOR_ACTION = 'PENDING_HAS_SECOND_FACTOR_ACTION';
export const FAILURE_HAS_SECOND_FACTOR_ACTION = 'FAILURE_HAS_SECOND_FACTOR_ACTION';

export const SET_OAUTH_REDIRECT_URI_ACTION = 'SET_OAUTH_REDIRECT_URI_ACTION';

export const PENDING_UPDATE_CUSTOMER_SETTINGS_ACTION = 'PENDING_UPDATE_CUSTOMER_SETTINGS_ACTION';
export const SUCCESS_UPDATE_CUSTOMER_SETTINGS_ACTION = 'SUCCESS_UPDATE_CUSTOMER_SETTINGS_ACTION';
export const FAILURE_UPDATE_CUSTOMER_SETTINGS_ACTION = 'FAILURE_UPDATE_CUSTOMER_SETTINGS_ACTION';

export const SUCCESS_ADD_TAG_ACTION = 'SUCCESS_ADD_TAG_ACTION';
export const PENDING_ADD_TAG_ACTION = 'PENDING_ADD_TAG_ACTION';
export const FAILURE_ADD_TAG_ACTION = 'FAILURE_ADD_TAG_ACTION';

export const SUCCESS_UPDATE_TAG_ACTION = 'SUCCESS_UPDATE_TAG_ACTION';
export const PENDING_UPDATE_TAG_ACTION = 'PENDING_UPDATE_TAG_ACTION';
export const FAILURE_UPDATE_TAG_ACTION = 'FAILURE_UPDATE_TAG_ACTION';

export const SUCCESS_GET_MERCHANTS_LIST_ACTION = 'SUCCESS_GET_MERCHANTS_LIST_ACTION';
export const SUCCESS_GET_MERCHANTS_ACTION = 'SUCCESS_GET_MERCHANTS_ACTION';
export const SUCCESS_GET_MERCHANTS_SCROLL_ACTION = 'SUCCESS_GET_MERCHANTS_SCROLL_ACTION';
export const PENDING_GET_MERCHANTS_LIST_ACTION = 'PENDING_GET_MERCHANTS_LIST_ACTION';
export const PENDING_GET_MERCHANTS_ACTION = 'PENDING_GET_MERCHANTS_ACTION';
export const PENDING_GET_MERCHANTS_SCROLL_ACTION = 'PENDING_GET_MERCHANTS_SCROLL_ACTION';
export const FAILURE_GET_MERCHANTS_LIST_ACTION = 'FAILURE_GET_MERCHANTS_LIST_ACTION';
export const FAILURE_GET_MERCHANTS_ACTION = 'FAILURE_GET_MERCHANTS_ACTION';
export const FAILURE_GET_MERCHANTS_SCROLL_ACTION = 'FAILURE_GET_MERCHANTS_SCROLL_ACTION';
export const RESET_MERCHANTS_ACTION = 'RESET_MERCHANTS_ACTION';

export const SUCCESS_GET_MERCHANT_ACTION = 'SUCCESS_GET_MERCHANT_ACTION';
export const PENDING_GET_MERCHANT_ACTION = 'PENDING_GET_MERCHANT_ACTION';
export const FAILURE_GET_MERCHANT_ACTION = 'FAILURE_GET_MERCHANT_ACTION';

export const FAILURE_CREATE_MERCHANT_ACTION = 'FAILURE_CREATE_MERCHANT_ACTION';
export const PENDING_CREATE_MERCHANT_ACTION = 'PENDING_CREATE_MERCHANT_ACTION';
export const SUCCESS_CREATE_MERCHANT_ACTION = 'SUCCESS_CREATE_MERCHANT_ACTION';

export const FAILURE_UPDATE_MERCHANT_ACTION = 'FAILURE_UPDATE_MERCHANT_ACTION';
export const PENDING_UPDATE_MERCHANT_ACTION = 'PENDING_UPDATE_MERCHANT_ACTION';
export const SUCCESS_UPDATE_MERCHANT_ACTION = 'SUCCESS_UPDATE_MERCHANT_ACTION';

export const RESET_MERCHANT_STATE_ACTION = 'RESET_MERCHANT_STATE_ACTION';

export const PENDING_UPDATE_STATUS_ACTION = 'PENDING_UPDATE_STATUS_ACTION';
export const FAILURE_UPDATE_STATUS_ACTION = 'FAILURE_UPDATE_STATUS_ACTION';
export const SUCCESS_UPDATE_STATUS_MERCHANTS_ACTION = 'SUCCESS_UPDATE_STATUS_MERCHANTS_ACTION';

export const PENDING_DELETE_MERCHANT_ACTION = 'PENDING_DELETE_MERCHANT_ACTION';
export const FAILURE_DELETE_MERCHANT_ACTION = 'FAILURE_DELETE_MERCHANT_ACTION';
export const SUCCESS_DELETE_MERCHANT_ACTION = 'SUCCESS_DELETE_MERCHANT_ACTION';

export const FAILURE_UPDATE_SETTINGS_MERCHANT_ACTION = 'FAILURE_UPDATE_SETTINGS_MERCHANT_ACTION';
export const PENDING_UPDATE_SETTINGS_MERCHANT_ACTION = 'PENDING_UPDATE_SETTINGS_MERCHANT_ACTION';
export const SUCCESS_UPDATE_SETTINGS_MERCHANT_ACTION = 'SUCCESS_UPDATE_SETTINGS_MERCHANT_ACTION';

export const PENDING_GET_AMAZON_PAY_SETTINGS_ACTION = 'PENDING_GET_AMAZON_PAY_SETTINGS_ACTION';
export const SUCCESS_GET_AMAZON_PAY_SETTINGS_ACTION = 'SUCCESS_GET_AMAZON_PAY_SETTINGS_ACTION';
export const FAILURE_GET_AMAZON_PAY_SETTINGS_ACTION = 'FAILURE_GET_AMAZON_PAY_SETTINGS_ACTION';

export const PENDING_UPDATE_AMAZON_PAY_SETTINGS_ACTION = 'PENDING_UPDATE_AMAZON_PAY_SETTINGS_ACTION';
export const SUCCESS_UPDATE_AMAZON_PAY_SETTINGS_ACTION = 'SUCCESS_UPDATE_AMAZON_PAY_SETTINGS_ACTION';
export const FAILURE_UPDATE_AMAZON_PAY_SETTINGS_ACTION =
  'FAILURFAILURE_UPDATE_AMAZON_PAY_SETTINGS_ACTIONE_GET_AMAZON_PAY_SETTINGS_ACTION';

export const PENDING_GET_CATALOGITEMS_ACTION = 'PENDING_GET_CATALOGITEMS_ACTION';
export const PENDING_GET_CATALOGITEMS_SCROLL_ACTION = 'PENDING_GET_CATALOGITEMS_SCROLL_ACTION';
export const SUCCESS_GET_CATALOGITEMS_ACTION = 'SUCCESS_GET_CATALOGITEMS_ACTION';
export const SUCCESS_GET_CATALOGITEMS_SCROLL_ACTION = 'SUCCESS_GET_CATALOGITEMS_SCROLL_ACTION';
export const FAILURE_GET_CATALOGITEMS_ACTION = 'FAILURE_GET_CATALOGITEMS_ACTION';
export const FAILURE_GET_CATALOGITEMS_SCROLL_ACTION = 'FAILURE_GET_CATALOGITEMS_SCROLL_ACTION';

export const RESET_CATALOGITEMS_ACTION = 'RESET_CATALOGITEMS_ACTION';

export const SUCCESS_ADD_CATALOGITEM_ACTION = 'SUCCESS_ADD_CATALOGITEM_ACTION';
export const PENDING_ADD_CATALOGITEM_ACTION = 'PENDING_ADD_CATALOGITEM_ACTION';
export const FAILURE_ADD_CATALOGITEM_ACTION = 'FAILURE_ADD_CATALOGITEM_ACTION';
export const RESET_CATALOGITEM_STATE_ACTION = 'RESET_CATALOGITEM_STATE_ACTION';

export const PENDING_DELETE_CATALOGITEM_ACTION = 'PENDING_DELETE_CATALOGITEM_ACTION';
export const FAILURE_DELETE_CATALOGITEM_ACTION = 'FAILURE_DELETE_CATALOGITEM_ACTION';
export const SUCCESS_DELETE_CATALOGITEM_ACTION = 'SUCCESS_DELETE_CATALOGITEM_ACTION';

export const SUCCESS_UPDATE_CATALOGITEM_ACTION = 'SUCCESS_UPDATE_CATALOGITEM_ACTION';
export const PENDING_UPDATE_CATALOGITEM_ACTION = 'PENDING_UPDATE_CATALOGITEM_ACTION';
export const FAILURE_UPDATE_CATALOGITEM_ACTION = 'FAILURE_UPDATE_CATALOGITEM_ACTION';

export const SUCCESS_GET_CATALOGITEM_ACTION = 'SUCCESS_GET_CATALOGITEM_ACTION';
export const PENDING_GET_CATALOGITEM_ACTION = 'PENDING_GET_CATALOGITEM_ACTION';
export const FAILURE_GET_CATALOGITEM_ACTION = 'FAILURE_GET_CATALOGITEM_ACTION';

export const PENDING_UPLOAD_CATALOGITEM_PHOTO_ACTION = 'PENDING_UPLOAD_CATALOGITEM_PHOTO_ACTION';
export const SUCCESS_UPLOAD_CATALOGITEM_PHOTO_ACTION = 'SUCCESS_UPLOAD_CATALOGITEM_PHOTO_ACTION';
export const FAILURE_UPLOAD_CATALOGITEM_PHOTO_ACTION = 'FAILURE_UPLOAD_CATALOGITEM_PHOTO_ACTION';

export const PENDING_GET_PHOTO_ACTION = 'PENDING_GET_PHOTO_ACTION';
export const SUCCESS_GET_PHOTO_ACTION = 'SUCCESS_GET_PHOTO_ACTION';
export const FAILURE_GET_PHOTO_ACTION = 'FAILURE_GET_PHOTO_ACTION';

export const PENDING_GET_MEASURES_ACTION = 'PENDING_GET_MEASURES_ACTION';
export const SUCCESS_GET_MEASURES_ACTION = 'SUCCESS_GET_MEASURES_ACTION';
export const FAILURE_GET_MEASURES_ACTION = 'FAILURE_GET_MEASURES_ACTION';

export const SUCCESS_GET_VATRATES_ACTION = 'SUCCESS_GET_VATRATES_ACTION';
export const PENDING_GET_VATRATES_ACTION = 'PENDING_GET_VATRATES_ACTION';
export const FAILURE_GET_VATRATES_ACTION = 'FAILURE_GET_VATRATES_ACTION';

export const SUCCESS_DELETE_VATRATE_ACTION = 'SUCCESS_DELETE_VATRATE_ACTION';
export const PENDING_DELETE_VATRATE_ACTION = 'PENDING_DELETE_VATRATE_ACTION';
export const FAILURE_DELETE_VATRATE_ACTION = 'FAILURE_DELETE_VATRATE_ACTION';

export const SUCCESS_ADD_VATRATE_ACTION = 'SUCCESS_ADD_VATRATE_ACTION';
export const PENDING_ADD_VATRATE_ACTION = 'PENDING_ADD_VATRATE_ACTION';
export const FAILURE_ADD_VATRATE_ACTION = 'FAILURE_ADD_VATRATE_ACTION';

export const SUCCESS_GET_WIDGETS_ACTION = 'SUCCESS_GET_WIDGETS_ACTION';
export const PENDING_GET_WIDGETS_ACTION = 'PENDING_GET_WIDGETS_ACTION';
export const FAILURE_GET_WIDGETS_ACTION = 'FAILURE_GET_WIDGETS_ACTION';
export const RESET_WIDGETS_STATE_ACTION = 'RESET_WIDGETS_STATE_ACTION';

export const SUCCESS_DELETE_WIDGET_ACTION = 'SUCCESS_DELETE_WIDGET_ACTION';
export const PENDING_DELETE_WIDGET_ACTION = 'PENDING_DELETE_WIDGET_ACTION';
export const FAILURE_DELETE_WIDGET_ACTION = 'FAILURE_DELETE_WIDGET_ACTION';

export const SUCCESS_UPDATE_WIDGET_ACTION = 'SUCCESS_UPDATE_WIDGET_ACTION';
export const PENDING_UPDATE_WIDGET_ACTION = 'PENDING_UPDATE_WIDGET_ACTION';
export const FAILURE_UPDATE_WIDGET_ACTION = 'FAILURE_UPDATE_WIDGET_ACTION';

export const FAILURE_CREATE_WIDGET_ACTION = 'FAILURE_CREATE_WIDGET_ACTION';
export const PENDING_CREATE_WIDGET_ACTION = 'PENDING_CREATE_WIDGET_ACTION';
export const SUCCESS_CREATE_WIDGET_ACTION = 'SUCCESS_CREATE_WIDGET_ACTION';

export const RESET_STATS_STATE_ACTION = 'RESET_STATS_STATE_ACTION';
export const RESET_WIDGETS_STATS_STATE_ACTION = 'RESET_WIDGETS_STATS_STATE_ACTION';

export const SUCCESS_GET_STORES_LIST_ACTION = 'SUCCESS_GET_STORES_LIST_ACTION';
export const SUCCESS_GET_STORES_ACTION = 'SUCCESS_GET_STORES_ACTION';
export const SUCCESS_GET_STORES_SCROLL_ACTION = 'SUCCESS_GET_STORES_SCROLL_ACTION';
export const PENDING_GET_STORES_LIST_ACTION = 'PENDING_GET_STORES_LIST_ACTION';
export const PENDING_GET_STORES_ACTION = 'PENDING_GET_STORES_ACTION';
export const PENDING_GET_STORES_SCROLL_ACTION = 'PENDING_GET_STORES_SCROLL_ACTION';
export const FAILURE_GET_STORES_LIST_ACTION = 'FAILURE_GET_STORES_LIST_ACTION';
export const FAILURE_GET_STORES_ACTION = 'FAILURE_GET_STORES_ACTION';
export const FAILURE_GET_STORES_SCROLL_ACTION = 'FAILURE_GET_STORES_SCROLL_ACTION';
export const RESET_STORES_ACTION = 'RESET_STORES_ACTION';

export const PENDING_GET_STORE_ACTION = 'PENDING_GET_STORE_ACTION';
export const SUCCESS_GET_STORE_ACTION = 'SUCCESS_GET_STORE_ACTION';
export const FAILURE_GET_STORE_ACTION = 'FAILURE_GET_STORE_ACTION';
export const RESET_STORE_STATE_ACTION = 'RESET_STORE_STATE_ACTION';

export const FAILURE_CREATE_STORE_ACTION = 'FAILURE_CREATE_STORE_ACTION';
export const PENDING_CREATE_STORE_ACTION = 'PENDING_CREATE_STORE_ACTION';
export const SUCCESS_CREATE_STORE_ACTION = 'SUCCESS_CREATE_STORE_ACTION';

export const FAILURE_UPDATE_STORE_ACTION = 'FAILURE_UPDATE_STORE_ACTION';
export const PENDING_UPDATE_STORE_ACTION = 'PENDING_UPDATE_STORE_ACTION';
export const SUCCESS_UPDATE_STORE_ACTION = 'SUCCESS_UPDATE_STORE_ACTION';

export const SUCCESS_GET_POSS_LIST_ACTION = 'SUCCESS_GET_POSS_LIST_ACTION';
export const SUCCESS_GET_POSS_ACTION = 'SUCCESS_GET_POSS_ACTION';
export const SUCCESS_GET_POSS_SCROLL_ACTION = 'SUCCESS_GET_POS_SCROLL_ACTION';
export const PENDING_GET_POSS_LIST_ACTION = 'PENDING_GET_POSS_LIST_ACTION';
export const PENDING_GET_POSS_ACTION = 'PENDING_GET_POSS_ACTION';
export const PENDING_GET_POSS_SCROLL_ACTION = 'PENDING_GET_POS_SCROLL_ACTION';
export const FAILURE_GET_POSS_LIST_ACTION = 'FAILURE_GET_POSS_LIST_ACTION';
export const FAILURE_GET_POSS_ACTION = 'FAILURE_GET_POSS_ACTION';
export const FAILURE_GET_POSS_SCROLL_ACTION = 'FAILURE_GET_POS_SCROLL_ACTION';
export const RESET_POSS_ACTION = 'RESET_POSS_ACTION';

export const SUCCESS_GET_COUNTRIES_ACTION = 'SUCCESS_GET_COUNTRIES_ACTION';
export const PENDING_GET_COUNTRIES_ACTION = 'PENDING_GET_COUNTRIES_ACTION';
export const FAILURE_GET_COUNTRIES_ACTION = 'FAILURE_GET_COUNTRIES_ACTION';
export const RESET_COUNTRIES_STATE_ACTION = 'RESET_COUNTRIES_STATE_ACTION';

export const SUCCESS_DELETE_STORE_ACTION = 'SUCCESS_DELETE_STORE_ACTION';
export const PENDING_DELETE_STORES_ACTION = 'PENDING_DELETE_STORES_ACTION';
export const FAILURE_DELETE_STORES_ACTION = 'FAILURE_DELETE_STORES_ACTION';

export const SUCCESS_DELETE_POSS_ACTION = 'SUCCESS_DELETE_POSS_ACTION';
export const PENDING_DELETE_POSS_ACTION = 'PENDING_DELETE_POSS_ACTION';
export const FAILURE_DELETE_POSS_ACTION = 'FAILURE_DELETE_POSS_ACTION';

export const FAILURE_CREATE_POS_ACTION = 'FAILURE_CREATE_POS_ACTION';
export const PENDING_CREATE_POS_ACTION = 'PENDING_CREATE_POS_ACTION';
export const SUCCESS_CREATE_POS_ACTION = 'SUCCESS_CREATE_POS_ACTION';

export const PENDING_GET_POS_ACTION = 'PENDING_GET_POS_ACTION';
export const SUCCESS_GET_POS_ACTION = 'SUCCESS_GET_POS_ACTION';
export const FAILURE_GET_POS_ACTION = 'FAILURE_GET_POS_ACTION';
export const RESET_POS_STATE_ACTION = 'RESET_POS_STATE_ACTION';

export const FAILURE_UPDATE_POS_ACTION = 'FAILURE_UPDATE_POS_ACTION';
export const PENDING_UPDATE_POS_ACTION = 'PENDING_UPDATE_POS_ACTION';
export const SUCCESS_UPDATE_POS_ACTION = 'SUCCESS_UPDATE_POS_ACTION';

export const FAILURE_LOAD_DOWNLOAD_POLICIES_ACTION = 'FAILURE_LOAD_DOWNLOAD_POLICIES_ACTION';
export const PENDING_LOAD_DOWNLOAD_POLICIES_ACTION = 'PENDING_LOAD_DOWNLOAD_POLICIES_ACTION';
export const SUCCESS_LOAD_DOWNLOAD_POLICIES_ACTION = 'SUCCESS_LOAD_DOWNLOAD_POLICIES_ACTION';

export const FAILURE_LOAD_POI_CONTRACTS_ACTION = 'FAILURE_LOAD_POI_CONTRACTS_ACTION';
export const PENDING_LOAD_POI_CONTRACTS_ACTION = 'PENDING_LOAD_POI_CONTRACTS_ACTION';
export const SUCCESS_LOAD_POI_CONTRACTS_ACTION = 'SUCCESS_LOAD_POI_CONTRACTS_ACTION';

export const SUCCESS_DELETE_POS_ACTION = 'SUCCESS_DELETE_POS_ACTION';
export const PENDING_DELETE_POS_ACTION = 'PENDING_DELETE_POS_ACTION';
export const FAILURE_DELETE_POS_ACTION = 'FAILURE_DELETE_POS_ACTION';

export const PENDING_GET_SIMS_ACTION = 'PENDING_GET_SIMS_ACTION';
export const PENDING_GET_SIMS_SCROLL_ACTION = 'PENDING_GET_SIM_SCROLL_ACTION';
export const SUCCESS_GET_SIMS_ACTION = 'SUCCESS_GET_SIMS_ACTION';
export const SUCCESS_GET_SIMS_SCROLL_ACTION = 'SUCCESS_GET_SIM_SCROLL_ACTION';
export const FAILURE_GET_SIMS_ACTION = 'FAILURE_GET_SIMS_ACTION';
export const FAILURE_GET_SIMS_SCROLL_ACTION = 'FAILURE_GET_SIM_SCROLL_ACTION';
export const RESET_SIMS_ACTION = 'RESET_SIMS_ACTION';

export const SUCCESS_DELETE_SIM_ACTION = 'SUCCESS_DELETE_SIM_ACTION';
export const PENDING_DELETE_SIMS_ACTION = 'PENDING_DELETE_SIMS_ACTION';
export const FAILURE_DELETE_SIMS_ACTION = 'FAILURE_DELETE_SIMS_ACTION';

export const PENDING_UPDATE_STATUS_SIMS_ACTION = 'PENDING_UPDATE_STATUS_SIMS_ACTION';
export const SUCCESS_UPDATE_STATUS_SIMS_ACTION = 'SUCCESS_UPDATE_STATUS_SIMS_ACTION';
export const FAILURE_UPDATE_STATUS_SIMS_ACTION = 'FAILURE_UPDATE_STATUS_SIMS_ACTION';

export const SUCCESS_CREATE_SIM_ACTION = 'SUCCESS_CREATE_SIM_ACTION';
export const PENDING_CREATE_SIM_ACTION = 'PENDING_CREATE_SIM_ACTION';
export const FAILURE_CREATE_SIM_ACTION = 'FAILURE_CREATE_SIM_ACTION';
export const RESET_SIM_STATE_ACTION = 'RESET_SIM_STATE_ACTION';

export const SUCCESS_UPDATE_SIM_ACTION = 'SUCCESS_UPDATE_SIM_ACTION';
export const PENDING_UPDATE_SIM_ACTION = 'PENDING_UPDATE_SIM_ACTION';
export const FAILURE_UPDATE_SIM_ACTION = 'FAILURE_UPDATE_SIM_ACTION';

export const SUCCESS_GET_SIM_ACTION = 'SUCCESS_GET_SIM_ACTION';
export const PENDING_GET_SIM_ACTION = 'PENDING_GET_SIM_ACTION';
export const FAILURE_GET_SIM_ACTION = 'FAILURE_GET_SIM_ACTION';

export const PENDING_ASSIGN_SIM_ACTION = 'PENDING_ASSIGN_SIM_ACTION';
export const SUCCESS_ASSIGN_SIM_ACTION = 'SUCCESS_ASSIGN_SIM_ACTION';
export const FAILURE_ASSIGN_SIM_ACTION = 'FAILURE_ASSIGN_SIM_ACTION';

export const PENDING_IMPORT_SIERRA_SIM_ACTION = 'PENDING_IMPORT_SIERRA_SIM_ACTION';
export const SUCCESS_IMPORT_SIERRA_SIM_ACTION = 'SUCCESS_IMPORT_SIERRA_SIM_ACTION';
export const FAILURE_IMPORT_SIERRA_SIM_ACTION = 'FAILURE_IMPORT_SIERRA_SIM_ACTION';

export const PENDING_LOAD_PROVIDERS_ACTION = 'PENDING_LOAD_PROVIDERS_ACTION';
export const SUCCESS_LOAD_PROVIDERS_ACTION = 'SUCCESS_LOAD_PROVIDERS_ACTION';
export const FAILURE_LOAD_PROVIDERS_ACTION = 'FAILURE_LOAD_PROVIDERS_ACTION';

export const PENDING_LOAD_CONTRACTS_ACTION = 'PENDING_LOAD_CONTRACTS_ACTION';
export const SUCCESS_LOAD_CONTRACTS_ACTION = 'SUCCESS_LOAD_CONTRACTS_ACTION';
export const FAILURE_LOAD_CONTRACTS_ACTION = 'FAILURE_LOAD_CONTRACTS_ACTION';

export const PENDING_LOAD_OFFER_IDS_ACTION = 'PENDING_LOAD_OFFER_IDS_ACTION';
export const SUCCESS_LOAD_OFFER_IDS_ACTION = 'SUCCESS_LOAD_OFFER_IDS_ACTION';
export const FAILURE_LOAD_OFFER_IDS_ACTION = 'FAILURE_LOAD_OFFER_IDS_ACTION';

export const SUCCESS_GET_PROGRAMS_ACTION = 'SUCCESS_GET_PROGRAMS_ACTION';
export const PENDING_GET_PROGRAMS_ACTION = 'PENDING_GET_PROGRAMS_ACTION';
export const FAILURE_GET_PROGRAMS_ACTION = 'FAILURE_GET_PROGRAMS_ACTION';

export const SUCCESS_DELETE_PROGRAM_ACTION = 'SUCCESS_DELETE_PROGRAM_ACTION';
export const PENDING_DELETE_PROGRAM_ACTION = 'PENDING_DELETE_PROGRAM_ACTION';
export const FAILURE_DELETE_PROGRAM_ACTION = 'FAILURE_DELETE_PROGRAM_ACTION';

export const SUCCESS_CREATE_PROGRAM_ACTION = 'SUCCESS_CREATE_PROGRAM_ACTION';
export const PENDING_CREATE_PROGRAM_ACTION = 'PENDING_CREATE_PROGRAM_ACTION';
export const FAILURE_CREATE_PROGRAM_ACTION = 'FAILURE_CREATE_PROGRAM_ACTION';

export const SUCCESS_GET_PROGRAM_ACTION = 'SUCCESS_GET_PROGRAM_ACTION';
export const PENDING_GET_PROGRAM_ACTION = 'PENDING_GET_PROGRAM_ACTION';
export const FAILURE_GET_PROGRAM_ACTION = 'FAILURE_GET_PROGRAM_ACTION';
export const RESET_PROGRAM_STATE_ACTION = 'RESET_PROGRAM_STATE_ACTION';

export const SUCCESS_UPDATE_PROGRAM_ACTION = 'SUCCESS_UPDATE_PROGRAM_ACTION';
export const PENDING_UPDATE_PROGRAM_ACTION = 'PENDING_UPDATE_PROGRAM_ACTION';
export const FAILURE_UPDATE_PROGRAM_ACTION = 'FAILURE_UPDATE_PROGRAM_ACTION';

export const PENDING_GET_TERMINAL_ALERTS_ACTION = 'PENDING_GET_TERMINAL_ALERTS_ACTION';
export const SUCCESS_GET_TERMINAL_ALERTS_ACTION = 'SUCCESS_GET_TERMINAL_ALERTS_ACTION';
export const FAILURE_GET_TERMINAL_ALERTS_ACTION = 'FAILURE_GET_TERMINAL_ALERTS_ACTION';

export const FAILURE_UPDATE_TERMINAL_ALERT_ACTION = 'FAILURE_UPDATE_TERMINAL_ALERT_ACTION';
export const PENDING_UPDATE_TERMINAL_ALERT_ACTION = 'PENDING_UPDATE_TERMINAL_ALERT_ACTION';
export const SUCCESS_UPDATE_TERMINAL_ALERT_ACTION = 'SUCCESS_UPDATE_TERMINAL_ALERT_ACTION';

export const PENDING_UPDATE_STATUS_TERMINAL_ALERT_ACTION = 'PENDING_UPDATE_STATUS_TERMINAL_ALERT_ACTION';
export const FAILURE_UPDATE_STATUS_TERMINAL_ALERT_ACTION = 'FAILURE_UPDATE_STATUS_TERMINAL_ALERT_ACTION';
export const SUCCESS_UPDATE_STATUS_TERMINAL_ALERT_ACTION = 'SUCCESS_UPDATE_STATUS_TERMINAL_ALERT_ACTION';

export const PENDING_GET_SIM_ALERTS_ACTION = 'PENDING_GET_SIM_ALERTS_ACTION';
export const SUCCESS_GET_SIM_ALERTS_ACTION = 'SUCCESS_GET_SIM_ALERTS_ACTION';
export const FAILURE_GET_SIM_ALERTS_ACTION = 'FAILURE_GET_SIM_ALERTS_ACTION';

export const FAILURE_UPDATE_SIM_ALERT_ACTION = 'FAILURE_UPDATE_SIM_ALERT_ACTION';
export const PENDING_UPDATE_SIM_ALERT_ACTION = 'PENDING_UPDATE_SIM_ALERT_ACTION';
export const SUCCESS_UPDATE_SIM_ALERT_ACTION = 'SUCCESS_UPDATE_SIM_ALERT_ACTION';

export const PENDING_UPDATE_STATUS_SIM_ALERT_ACTION = 'PENDING_UPDATE_STATUS_SIM_ALERT_ACTION';
export const FAILURE_UPDATE_STATUS_SIM_ALERT_ACTION = 'FAILURE_UPDATE_STATUS_SIM_ALERT_ACTION';
export const SUCCESS_UPDATE_STATUS_SIM_ALERT_ACTION = 'SUCCESS_UPDATE_STATUS_SIM_ALERT_ACTION';

export const PENDING_GET_PREDEFINED_ALERTS_ACTION = 'PENDING_GET_PREDEFINED_ALERTS_ACTION';
export const SUCCESS_GET_PREDEFINED_ALERTS_ACTION = 'SUCCESS_GET_PREDEFINED_ALERTS_ACTION';
export const FAILURE_GET_PREDEFINED_ALERTS_ACTION = 'FAILURE_GET_PREDEFINED_ALERTS_ACTION';

export const PENDING_DELETE_PREDEFINED_ALERT_ACTION = 'PENDING_DELETE_PREDEFINED_ALERT_ACTION';
export const FAILURE_DELETE_PREDEFINED_ALERT_ACTION = 'FAILURE_DELETE_PREDEFINED_ALERT_ACTION';
export const SUCCESS_DELETE_PREDEFINED_ALERT_ACTION = 'SUCCESS_DELETE_PREDEFINED_ALERT_ACTION';

export const FAILURE_CREATE_PREDEFINED_ALERT_ACTION = 'FAILURE_CREATE_PREDEFINED_ALERT_ACTION';
export const PENDING_CREATE_PREDEFINED_ALERT_ACTION = 'PENDING_CREATE_PREDEFINED_ALERT_ACTION';
export const SUCCESS_CREATE_PREDEFINED_ALERT_ACTION = 'SUCCESS_CREATE_PREDEFINED_ALERT_ACTION';

export const SUCCESS_GET_POIS_LIST_ACTION = 'SUCCESS_GET_POIS_LIST_ACTION';
export const SUCCESS_GET_POIS_ACTION = 'SUCCESS_GET_POIS_ACTION';
export const SUCCESS_GET_POIS_SCROLL_ACTION = 'SUCCESS_GET_POI_SCROLL_ACTION';
export const PENDING_GET_POIS_LIST_ACTION = 'PENDING_GET_POIS_LIST_ACTION';
export const PENDING_GET_POIS_ACTION = 'PENDING_GET_POIS_ACTION';
export const PENDING_GET_POIS_SCROLL_ACTION = 'PENDING_GET_POI_SCROLL_ACTION';
export const FAILURE_GET_POIS_LIST_ACTION = 'FAILURE_GET_POIS_LIST_ACTION';
export const FAILURE_GET_POIS_ACTION = 'FAILURE_GET_POIS_ACTION';
export const FAILURE_GET_POIS_SCROLL_ACTION = 'FAILURE_GET_POI_SCROLL_ACTION';
export const RESET_POIS_ACTION = 'RESET_POIS_ACTION';

export const SUCCESS_DELETE_POI_ACTION = 'SUCCESS_DELETE_POI_ACTION';
export const PENDING_DELETE_POI_ACTION = 'PENDING_DELETE_POI_ACTION';
export const FAILURE_DELETE_POI_ACTION = 'FAILURE_DELETE_POI_ACTION';

export const SUCCESS_CREATE_POI_ACTION = 'SUCCESS_CREATE_POI_ACTION';
export const PENDING_CREATE_POI_ACTION = 'PENDING_CREATE_POI_ACTION';
export const FAILURE_CREATE_POI_ACTION = 'FAILURE_CREATE_POI_ACTION';

export const SUCCESS_SWAP_POI_ACTION = 'SUCCESS_SWAP_POI_ACTION';
export const PENDING_SWAP_POI_ACTION = 'PENDING_SWAP_POI_ACTION';
export const FAILURE_SWAP_POI_ACTION = 'FAILURE_SWAP_POI_ACTION';

export const SUCCESS_GET_POI_ACTION = 'SUCCESS_GET_POI_ACTION';
export const PENDING_GET_POI_ACTION = 'PENDING_GET_POI_ACTION';
export const FAILURE_GET_POI_ACTION = 'FAILURE_GET_POI_ACTION';
export const RESET_POI_STATE_ACTION = 'RESET_POI_STATE_ACTION';

export const SUCCESS_UPDATE_POI_ACTION = 'SUCCESS_UPDATE_POI_ACTION';
export const PENDING_UPDATE_POI_ACTION = 'PENDING_UPDATE_POI_ACTION';
export const FAILURE_UPDATE_POI_ACTION = 'FAILURE_UPDATE_POI_ACTION';

export const PENDING_LINK_POI_ACTION = 'PENDING_LINK_POI_ACTION';
export const SUCCESS_LINK_POI_ACTION = 'SUCCESS_LINK_POI_ACTION';
export const FAILURE_LINK_POI_ACTION = 'FAILURE_LINK_POI_ACTION';

export const PENDING_UNLINK_POI_ACTION = 'PENDING_UNLINK_POI_ACTION';
export const SUCCESS_UNLINK_POI_ACTION = 'SUCCESS_UNLINK_POI_ACTION';
export const FAILURE_UNLINK_POI_ACTION = 'FAILURE_UNLINK_POI_ACTION';

export const SUCCESS_GET_MERCHANT_CATEGORY_CODE_ACTION = 'SUCCESS_GET_MERCHANT_CATEGORY_CODE_ACTION';
export const PENDING_GET_MERCHANT_CATEGORY_CODE_ACTION = 'PENDING_GET_MERCHANT_CATEGORY_CODE_ACTION';
export const FAILURE_GET_MERCHANT_CATEGORY_CODE_ACTION = 'FAILURE_GET_MERCHANT_CATEGORY_CODE_ACTION';

export const SUCCESS_UPDATE_STATUS_STORES_ACTION = 'SUCCESS_UPDATE_STATUS_STORES_ACTION';
export const PENDING_UPDATE_STATUS_STORES_ACTION = 'PENDING_UPDATE_STATUS_STORES_ACTION';
export const FAILURE_UPDATE_STATUS_STORES_ACTION = 'FAILURE_UPDATE_STATUS_STORES_ACTION';

export const SUCCESS_UPDATE_USER_COLUMNS_SETTINGS_ACTION = 'SUCCESS_UPDATE_USER_COLUMNS_SETTINGS_ACTION';
export const PENDING_UPDATE_USER_COLUMNS_SETTINGS_ACTION = 'PENDING_UPDATE_USER_COLUMNS_SETTINGS_ACTION';
export const FAILURE_UPDATE_USER_COLUMNS_SETTINGS_ACTION = 'FAILURE_UPDATE_USER_COLUMNS_SETTINGS_ACTION';
export const RESET_UPDATE_USER_COLUMNS_SETTINGS_ACTION = 'RESET_UPDATE_USER_COLUMNS_SETTINGS_ACTION';

export const SUCCESS_DELETE_USER_COLUMNS_SETTINGS_ACTION = 'SUCCESS_DELETE_USER_COLUMNS_SETTINGS_ACTION';
export const PENDING_DELETE_USER_COLUMNS_SETTINGS_ACTION = 'PENDING_DELETE_USER_COLUMNS_SETTINGS_ACTION';
export const FAILURE_DELETE_USER_COLUMNS_SETTINGS_ACTION = 'FAILURE_DELETE_USER_COLUMNS_SETTINGS_ACTION';
export const RESET_DELETE_USER_COLUMNS_SETTINGS_ACTION = 'RESET_DELETE_USER_COLUMNS_SETTINGS_ACTION';

export const SUCCESS_GET_SIMS_ACTIVITIES_ACTION = 'SUCCESS_GET_SIMS_ACTIVITIES_ACTION';
export const PENDING_GET_SIMS_ACTIVITIES_ACTION = 'PENDING_GET_SIMS_ACTIVITIES_ACTION';
export const FAILURE_GET_SIMS_ACTIVITIES_ACTION = 'FAILURE_GET_SIMS_ACTIVITIES_ACTION';
export const RESET_SIMS_ACTIVITIES_ACTION = 'RESET_SIMS_ACTIVITIES_ACTION';
export const SUCCESS_GET_SIMS_ACTIVITIES_SCROLL_ACTION = 'SUCCESS_GET_SIMS_ACTIVITIES_SCROLL_ACTION';

export const SUCCESS_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION =
  'SUCCESS_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION';
export const PENDING_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION =
  'PENDING_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION';
export const FAILURE_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION =
  'FAILURE_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION';
export const RESET_SIMS_ACTIVITIES_CONNECTIONS_ACTION = 'RESET_SIMS_ACTIVITIES_CONNECTIONS_ACTION';

export const SUCCESS_UPDATE_STATUS_SIMS_ACTIVITIES_ACTION = 'SUCCESS_UPDATE_STATUS_SIMS_ACTIVITIES_ACTION';
export const PENDING_UPDATE_STATUS_SIMS_ACTIVITIES_ACTION = 'PENDING_UPDATE_STATUS_SIMS_ACTIVITIES_ACTION';
export const FAILURE_UPDATE_STATUS_SIMS_ACTIVITIES_ACTION = 'FAILURE_UPDATE_STATUS_SIMS_ACTIVITIES_ACTION';

export const SUCCESS_UPDATE_STATUS_POSS_ACTION = 'SUCCESS_UPDATE_STATUS_POSS_ACTION';
export const PENDING_UPDATE_STATUS_POSS_ACTION = 'PENDING_UPDATE_STATUS_POSS_ACTION';
export const FAILURE_UPDATE_STATUS_POSS_ACTION = 'FAILURE_UPDATE_STATUS_POSS_ACTION';

export const SUCCESS_UPDATE_STATUS_POS_ACTION = 'SUCCESS_UPDATE_STATUS_POS_ACTION';
export const PENDING_UPDATE_STATUS_POS_ACTION = 'PENDING_UPDATE_STATUS_POSS_ACTION';
export const FAILURE_UPDATE_STATUS_POS_ACTION = 'FAILURE_UPDATE_STATUS_POSS_ACTION';

export const SUCCESS_GET_TIMEZONES_ACTION = 'SUCCESS_GET_TIMEZONES_ACTION';
export const PENDING_GET_TIMEZONES_ACTION = 'PENDING_GET_TIMEZONES_ACTION';
export const FAILURE_GET_TIMEZONES_ACTION = 'FAILURE_GET_TIMEZONES_ACTION';
export const RESET_TIMEZONES_STATE_ACTION = 'RESET_TIMEZONES_STATE_ACTION';

export const CHANGE_FIELD_ACTION = 'CHANGE_FIELD_ACTION';

export const SUCCESS_GET_LOGO_ACTION = 'SUCCESS_GET_LOGO_ACTION';
export const PENDING_GET_LOGO_ACTION = 'PENDING_GET_LOGO_ACTION';
export const FAILURE_GET_LOGO_ACTION = 'FAILURE_GET_LOGO_ACTION';
export const RESET_LOGO_ACTION = 'RESET_LOGO_ACTION';

export const SUCCESS_GET_SALES_ACTION = 'SUCCESS_GET_SALES_ACTION';
export const PENDING_GET_SALES_ACTION = 'PENDING_GET_SALES_ACTION';
export const FAILURE_GET_SALES_ACTION = 'FAILURE_GET_SALES_ACTION';
export const SUCCESS_GET_SALES_SCROLL_ACTION = 'SUCCESS_GET_SALES_SCROLL_ACTION';
export const RESET_SALES_ACTION = 'RESET_SALES_ACTION';

export const SHOW_SALE_DETAIL_ACTION = 'SHOW_SALE_DETAIL_ACTION';
export const HIDE_SALE_DETAIL_ACTION = 'HIDE_SALE_DETAIL_ACTION';

export const SUCCESS_GET_SALE_DETAIL_ACTION = 'SUCCESS_GET_SALE_DETAIL_ACTION';
export const PENDING_GET_SALE_DETAIL_ACTION = 'PENDING_GET_SALE_DETAIL_ACTION';
export const FAILURE_GET_SALE_DETAIL_ACTION = 'FAILURE_GET_SALE_DETAIL_ACTION';

export const SUCCESS_SET_SALE_DETAIL_ACTION = 'SUCCESS_SET_SALE_DETAIL_ACTION';

export const SUCCESS_GET_RECONCILIATIONS_ACTION = 'SUCCESS_GET_RECONCILIATIONS_ACTION';
export const PENDING_GET_RECONCILIATIONS_ACTION = 'PENDING_GET_RECONCILIATIONS_ACTION';
export const FAILURE_GET_RECONCILIATIONS_ACTION = 'FAILURE_GET_RECONCILIATIONS_ACTION';
export const SUCCESS_GET_RECONCILIATIONS_SCROLL_ACTION = 'SUCCESS_GET_RECONCILIATION_SCROLL_ACTION';
export const RESET_RECONCILIATIONS_ACTION = 'RESET_RECONCILIATIONS_ACTION';

export const SUCCESS_GET_RECONCILIATION_RECEIPT_ACTION = 'SUCCESS_GET_RECONCILIATION_RECEIPT_ACTION';
export const FAILURE_GET_RECONCILIATION_RECEIPT_ACTION = 'FAILURE_GET_RECONCILIATION_RECEIPT_ACTION';

export const SUCCESS_DOWNLOAD_RECONCILIATION_RECEIPT_ACTION =
  'SUCCESS_DOWNLOAD_RECONCILIATION_RECEIPT_ACTION';
export const PENDING_DOWNLOAD_RECONCILIATION_RECEIPT_ACTION =
  'PENDING_DOWNLOAD_RECONCILIATION_RECEIPT_ACTION';
export const FAILURE_DOWNLOAD_RECONCILIATION_RECEIPT_ACTION =
  'FAILURE_DOWNLOAD_RECONCILIATION_RECEIPT_ACTION';

export const OPEN_MERCHANT_RECEIPT_ACTION = 'OPEN_MERCHANT_RECEIPT_ACTION';
export const CLOSE_MERCHANT_RECEIPT_ACTION = 'CLOSE_MERCHANT_RECEIPT_ACTION';

export const DISPLAY_POSS_QR_CODES_ACTION = 'DISPLAY_POSS_QR_CODES_ACTION';
export const HIDE_POSS_QR_CODES_ACTION = 'HIDE_POSS_QR_CODES_ACTION';
export const CLOSE_POSS_QR_CODE_ACTION = 'CLOSE_POSS_QR_CODE_ACTION';

export const PENDING_GET_AVAILABLE_WIDGETS_ACTION = 'PENDING_GET_AVAILABLE_WIDGETS_ACTION';
export const SUCCESS_GET_AVAILABLE_WIDGETS_ACTION = 'SUCCESS_GET_AVAILABLE_WIDGETS_ACTION';
export const FAILURE_GET_AVAILABLE_WIDGETS_ACTION = 'FAILURE_GET_AVAILABLE_WIDGETS_ACTION';
export const RESET_AVAILABLE_WIDGETS_STATE_ACTION = 'RESET_AVAILABLE_WIDGETS_STATE_ACTION';

export const RESET_TREND_STATE_ACTION = 'RESET_TREND_STATE_ACTION';
export const RESET_WIDGETS_TREND_STATE_ACTION = 'RESET_WIDGETS_TREND_STATE_ACTION';

export const PENDING_GET_TERMINAL_ACTIVITIES_ACTION = 'PENDING_GET_TERMINAL_ACTIVITIES_ACTION';
export const SUCCESS_GET_TERMINAL_ACTIVITIES_ACTION = 'SUCCESS_GET_TERMINAL_ACTIVITIES_ACTION';
export const FAILURE_GET_TERMINAL_ACTIVITIES_ACTION = 'FAILURE_GET_TERMINAL_ACTIVITIES_ACTION';
export const RESET_TERMINAL_ACTIVITIES_ACTION = 'RESET_TERMINAL_ACTIVITIES_ACTION';
export const SUCCESS_GET_TERMINAL_ACTIVITIES_SCROLL_ACTION = 'SUCCESS_GET_TERMINAL_ACTIVITIES_SCROLL_ACTION';

export const PENDING_GET_TERMINAL_ACTIVITIES_CONNECTIONS_ACTION =
  'PENDING_GET_TERMINAL_ACTIVITIES_CONNECTIONS_ACTION';
export const SUCCESS_GET_TERMINAL_ACTIVITIES_CONNECTIONS_ACTION =
  'SUCCESS_GET_TERMINAL_ACTIVITIES_CONNECTIONS_ACTION';
export const FAILURE_GET_TERMINAL_ACTIVITIES_CONNECTIONS_ACTION =
  'FAILURE_GET_TERMINAL_ACTIVITIES_CONNECTIONS_ACTION';
export const RESET_TERMINAL_ACTIVITIES_CONNECTIONS_ACTION = 'RESET_TERMINAL_ACTIVITIES_CONNECTIONS_ACTION';
export const SUCCESS_GET_TERMINAL_ACTIVITIES_CONNECTIONS_SCROLL_ACTION =
  'SUCCESS_GET_TERMINAL_ACTIVITIES_CONNECTIONS_SCROLL_ACTION';

export const PENDING_GET_TRANSACTIONS_ACTION = 'PENDING_GET_TRANSACTIONS_ACTION';
export const SUCCESS_GET_TRANSACTIONS_ACTION = 'SUCCESS_GET_TRANSACTION_ACTION';
export const SUCCESS_GET_TRANSACTIONS_SCROLL_ACTION = 'SUCCESS_GET_TRANSACTION_SCROLL_ACTION';
export const FAILURE_GET_TRANSACTIONS_ACTION = 'FAILURE_GET_TRANSACTIONS_ACTION';
export const PENDING_GET_TRANSACTION_CONFLICT_ACTION = 'PENDING_GET_TRANSACTION_CONFLICT_ACTION';
export const SUCCESS_GET_TRANSACTION_CONFLICT_ACTION = 'SUCCESS_GET_TRANSACTION_ACTION';
export const FAILURE_GET_TRANSACTION_CONFLICT_ACTION = 'FAILURE_GET_TRANSACTION_CONFLICT_ACTION';
export const PENDING_MERGE_TRANSACTION_CONFLICT_ACTION = 'PENDING_MERGE_TRANSACTION_CONFLICT_ACTION';
export const SUCCESS_MERGE_TRANSACTION_CONFLICT_ACTION = 'SUCCESS_MERGE_TRANSACTION_ACTION';
export const FAILURE_MERGE_TRANSACTION_CONFLICT_ACTION = 'FAILURE_MERGE_TRANSACTION_CONFLICT_ACTION';
export const PENDING_DOWNLOAD_TRANSACTIONS_RECEIPT_ACTION = 'PENDING_DOWNLOAD_TRANSACTIONS_RECEIPT_ACTION';
export const SUCCESS_DOWNLOAD_TRANSACTIONS_RECEIPT_ACTION = 'SUCCESS_DOWNLOAD_TRANSACTIONS_RECEIPT_ACTION';
export const FAILURE_DOWNLOAD_TRANSACTIONS_RECEIPT_ACTION = 'FAILURE_DOWNLOAD_TRANSACTIONS_RECEIPT_ACTION';
export const PENDING_SEND_TRANSACTIONS_RECEIPT_ACTION = 'PENDING_SEND_TRANSACTIONS_RECEIPT_ACTION';
export const SUCCESS_SEND_TRANSACTIONS_RECEIPT_ACTION = 'SUCCESS_SEND_TRANSACTIONS_RECEIPT_ACTION';
export const FAILURE_SEND_TRANSACTIONS_RECEIPT_ACTION = 'FAILURE_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION';
export const PENDING_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION =
  'PENDING_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION';
export const SUCCESS_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION =
  'SUCCESS_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION';
export const FAILURE_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION =
  'FAILURE_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION';
export const PENDING_DOWNLOAD_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION =
  'PENDING_DOWNLOAD_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION';
export const SUCCESS_DOWNLOAD_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION =
  'SUCCESS_DOWNLOAD_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION';
export const FAILURE_DOWNLOAD_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION =
  'FAILURE_DOWNLOAD_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION';
export const PENDING_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION =
  'PENDING_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION';
export const SUCCESS_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION =
  'SUCCESS_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION';
export const FAILURE_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION =
  'FAILURE_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION';
export const PENDING_GET_TRANSACTIONS_DETAIL_ACTION = 'PENDING_GET_TRANSACTIONS_DETAIL_ACTION';
export const SUCCESS_GET_TRANSACTIONS_DETAIL_ACTION = 'SUCCESS_GET_TRANSACTIONS_DETAIL_ACTION';
export const FAILURE_GET_TRANSACTIONS_DETAIL_ACTION = 'FAILURE_GET_TRANSACTIONS_DETAIL_ACTION';
export const SUCCESS_GET_TRANSACTIONS_MERCHANT_RECEIPT_ACTION =
  'SUCCESS_GET_TRANSACTIONS_MERCHANT_RECEIPT_ACTION';
export const FAILURE_GET_TRANSACTIONS_MERCHANT_RECEIPT_ACTION =
  'FAILURE_GET_TRANSACTIONS_MERCHANT_RECEIPT_ACTION';
export const SUCCESS_GET_TRANSACTIONS_CARDHOLDER_RECEIPT_ACTION =
  'SUCCESS_GET_TRANSACTIONS_CARDHOLDER_RECEIPT_ACTION';
export const FAILURE_GET_TRANSACTIONS_CARDHOLDER_RECEIPT_ACTION =
  'FAILURE_GET_TRANSACTIONS_CARDHOLDER_RECEIPT_ACTION';
export const SUCCESS_GET_TRANSACTIONS_RECEIPTS_ACTION = 'SUCCESS_GET_TRANSACTIONS_RECEIPTS_ACTION';
export const FAILURE_GET_TRANSACTIONS_RECEIPTS_ACTION = 'FAILURE_GET_TRANSACTIONS_RECEIPTS_ACTION';
export const RESET_TRANSACTIONS_ACTION = 'RESET_TRANSACTIONS_ACTION';
export const CLEAR_TRANSACTIONS_DETAIL_ACTION = 'CLEAR_TRANSACTIONS_DETAIL_ACTION';
export const SHOW_TRANSACTIONS_DETAIL_ACTION = 'SHOW_TRANSACTIONS_DETAIL_ACTION';
export const HIDE_TRANSACTIONS_DETAIL_ACTION = 'HIDE_TRANSACTIONS_DETAIL_ACTION';
export const SHOW_TRANSACTIONS_RECEIPTS_ACTION = 'SHOW_TRANSACTIONS_RECEIPTS_ACTION';
export const HIDE_TRANSACTIONS_RECEIPTS_ACTION = 'HIDE_TRANSACTIONS_RECEIPTS_ACTION';
export const CLOSE_TRANSACTIONS_RECEIPT_ACTION = 'CLOSE_TRANSACTIONS_RECEIPT_ACTION';
export const CLOSE_TRANSACTIONS_RECEIPTS_ACTION = 'CLOSE_TRANSACTIONS_RECEIPTS_ACTION';

export const DATATABLE_SCROLL_ACTION = 'DATATABLE_SCROLL_ACTION';

export const PENDING_GET_OFFERS_ACTION = 'PENDING_GET_OFFERS_ACTION';
export const SUCCESS_GET_OFFERS_ACTION = 'SUCCESS_GET_OFFERS_ACTION';
export const FAILURE_GET_OFFERS_ACTION = 'FAILURE_GET_OFFERS_ACTION';

export const SUCCESS_CREATE_OFFER_ACTION = 'SUCCESS_CREATE_OFFER_ACTION';
export const PENDING_CREATE_OFFER_ACTION = 'PENDING_CREATE_OFFER_ACTION';
export const FAILURE_CREATE_OFFER_ACTION = 'FAILURE_CREATE_OFFER_ACTION';

export const SUCCESS_GET_OFFER_ACTION = 'SUCCESS_GET_OFFER_ACTION';
export const PENDING_GET_OFFER_ACTION = 'PENDING_GET_OFFER_ACTION';
export const FAILURE_GET_OFFER_ACTION = 'FAILURE_GET_OFFER_ACTION';
export const RESET_OFFER_STATE_ACTION = 'RESET_OFFER_STATE_ACTION';

export const SUCCESS_UPDATE_OFFER_ACTION = 'SUCCESS_UPDATE_OFFER_ACTION';
export const PENDING_UPDATE_OFFER_ACTION = 'PENDING_UPDATE_OFFER_ACTION';
export const FAILURE_UPDATE_OFFER_ACTION = 'FAILURE_UPDATE_OFFER_ACTION';

export const PENDING_GET_SCHEMES_ACTION = 'PENDING_GET_SCHEMES_ACTION';
export const SUCCESS_GET_SCHEMES_ACTION = 'SUCCESS_GET_SCHEMES_ACTION';
export const FAILURE_GET_SCHEMES_ACTION = 'FAILURE_GET_SCHEMES_ACTION';

export const SUCCESS_UPLOAD_SCHEME_ACTION = 'SUCCESS_UPLOAD_SCHEME_ACTION';
export const PENDING_UPLOAD_SCHEME_ACTION = 'PENDING_UPLOAD_SCHEME_ACTION';
export const FAILURE_UPLOAD_SCHEME_ACTION = 'FAILURE_UPLOAD_SCHEME_ACTION';

export const SUCCESS_DELETE_SCHEME_ACTION = 'SUCCESS_DELETE_SCHEME_ACTION';
export const PENDING_DELETE_SCHEME_ACTION = 'PENDING_DELETE_SCHEME_ACTION';
export const FAILURE_DELETE_SCHEME_ACTION = 'FAILURE_DELETE_SCHEME_ACTION';

export const PENDING_GET_CUSTOMERS_ACTION = 'PENDING_GET_CUSTOMERS_ACTION';
export const SUCCESS_GET_CUSTOMERS_ACTION = 'SUCCESS_GET_CUSTOMERS_ACTION';
export const FAILURE_GET_CUSTOMERS_ACTION = 'FAILURE_GET_CUSTOMERS_ACTION';

export const SUCCESS_CREATE_CUSTOMER_ACTION = 'SUCCESS_CREATE_CUSTOMER_ACTION';
export const PENDING_CREATE_CUSTOMER_ACTION = 'PENDING_CREATE_CUSTOMER_ACTION';
export const FAILURE_CREATE_CUSTOMER_ACTION = 'FAILURE_CREATE_CUSTOMER_ACTION';

export const SUCCESS_GET_CUSTOMER_ACTION = 'SUCCESS_GET_CUSTOMER_ACTION';
export const PENDING_GET_CUSTOMER_ACTION = 'PENDING_GET_CUSTOMER_ACTION';
export const FAILURE_GET_CUSTOMER_ACTION = 'FAILURE_GET_CUSTOMER_ACTION';
export const RESET_CUSTOMER_STATE_ACTION = 'RESET_CUSTOMER_STATE_ACTION';

export const SUCCESS_UPDATE_CUSTOMER_ACTION = 'SUCCESS_UPDATE_CUSTOMER_ACTION';
export const PENDING_UPDATE_CUSTOMER_ACTION = 'PENDING_UPDATE_CUSTOMER_ACTION';
export const FAILURE_UPDATE_CUSTOMER_ACTION = 'FAILURE_UPDATE_CUSTOMER_ACTION';

export const SUCCESS_UPDATE_CUSTOMER_STATUS_ACTION = 'SUCCESS_UPDATE_CUSTOMER_STATUS_ACTION';
export const PENDING_UPDATE_CUSTOMER_STATUS_ACTION = 'PENDING_UPDATE_CUSTOMER_STATUS_ACTION';
export const FAILURE_UPDATE_CUSTOMER_STATUS_ACTION = 'FAILURE_UPDATE_CUSTOMER_STATUS_ACTION';

export const SUCCESS_GET_AUDITS_ACTION = 'SUCCESS_GET_AUDITS_ACTION';
export const SUCCESS_GET_AUDITS_SCROLL_ACTION = 'SUCCESS_GET_AUDIT_SCROLL_ACTION';
export const PENDING_GET_AUDITS_ACTION = 'PENDING_GET_AUDITS_ACTION';
export const PENDING_GET_AUDITS_SCROLL_ACTION = 'PENDING_GET_AUDIT_SCROLL_ACTION';
export const FAILURE_GET_AUDITS_ACTION = 'FAILURE_GET_AUDITS_ACTION';
export const FAILURE_GET_AUDITS_SCROLL_ACTION = 'FAILURE_GET_AUDIT_SCROLL_ACTION';
export const RESET_AUDITS_ACTION = 'RESET_AUDITS_ACTION';

export const SUCCESS_CREATE_AUTOINIT_PROGRAM_ACTION = 'SUCCESS_CREATE_AUTOINIT_PROGRAM_ACTION';
export const PENDING_CREATE_AUTOINIT_PROGRAM_ACTION = 'PENDING_CREATE_AUTOINIT_PROGRAM_ACTION';
export const FAILURE_CREATE_AUTOINIT_PROGRAM_ACTION = 'FAILURE_CREATE_AUTOINIT_PROGRAM_ACTION';

export const SUCCESS_GET_AUTOINIT_PROGRAM_ACTION = 'SUCCESS_GET_AUTOINIT_PROGRAM_ACTION';
export const PENDING_GET_AUTOINIT_PROGRAM_ACTION = 'PENDING_GET_AUTOINIT_PROGRAM_ACTION';
export const FAILURE_GET_AUTOINIT_PROGRAM_ACTION = 'FAILURE_GET_AUTOINIT_PROGRAM_ACTION';
export const RESET_AUTOINIT_PROGRAM_STATE_ACTION = 'RESET_AUTOINIT_PROGRAM_STATE_ACTION';

export const SUCCESS_UPDATE_AUTOINIT_PROGRAM_ACTION = 'SUCCESS_UPDATE_AUTOINIT_PROGRAM_ACTION';
export const PENDING_UPDATE_AUTOINIT_PROGRAM_ACTION = 'PENDING_UPDATE_AUTOINIT_PROGRAM_ACTION';
export const FAILURE_UPDATE_AUTOINIT_PROGRAM_ACTION = 'FAILURE_UPDATE_AUTOINIT_PROGRAM_ACTION';

export const PENDING_GET_USERS_ACTION = 'PENDING_GET_USERS_ACTION';
export const SUCCESS_GET_USERS_ACTION = 'SUCCESS_GET_USERS_ACTION';
export const FAILURE_GET_USERS_ACTION = 'FAILURE_GET_USERS_ACTION';

export const PENDING_GET_USERS_HIERARCHY_ACTION = 'PENDING_GET_USERS_HIERARCHY_ACTION';
export const SUCCESS_GET_USERS_HIERARCHY_ACTION = 'SUCCESS_GET_USERS_HIERARCHY_ACTION';
export const FAILURE_GET_USERS_HIERARCHY_ACTION = 'FAILURE_GET_USERS_HIERARCHY_ACTION';

export const SUCCESS_UPDATE_STATUS_USER_ACTION = 'SUCCESS_UPDATE_STATUS_USER_ACTION';
export const PENDING_UPDATE_STATUS_USER_ACTION = 'PENDING_UPDATE_STATUS_USER_ACTION';
export const FAILURE_UPDATE_STATUS_USER_ACTION = 'FAILURE_UPDATE_STATUS_USER_ACTION';

export const SUCCESS_DELETE_USER_ACTION = 'SUCCESS_DELETE_USER_ACTION';
export const PENDING_DELETE_USER_ACTION = 'PENDING_DELETE_USER_ACTION';
export const FAILURE_DELETE_USER_ACTION = 'FAILURE_DELETE_USER_ACTION';

export const SUCCESS_RESET_USER_PASSWORD_ACTION = 'SUCCESS_RESET_USER_PASSWORD_ACTION';
export const PENDING_RESET_USER_PASSWORD_ACTION = 'PENDING_RESET_USER_PASSWORD_ACTION';
export const FAILURE_RESET_USER_PASSWORD_ACTION = 'FAILURE_RESET_USER_PASSWORD_ACTION';

export const CHANGE_USER_MODE_ACTION = 'CHANGE_USER_MODE_ACTION';
export const RESET_USER_MODE_ACTION = 'RESET_USER_MODE_ACTION';

export const SUCCESS_CREATE_USER_ACTION = 'SUCCESS_CREATE_USER_ACTION';
export const PENDING_CREATE_USER_ACTION = 'PENDING_CREATE_USER_ACTION';
export const FAILURE_CREATE_USER_ACTION = 'FAILURE_CREATE_USER_ACTION';

export const SUCCESS_CREATE_MPOS_USER_ACTION = 'SUCCESS_CREATE_MPOS_USER_ACTION';
export const PENDING_CREATE_MPOS_USER_ACTION = 'PENDING_CREATE_MPOS_USER_ACTION';
export const FAILURE_CREATE_MPOS_USER_ACTION = 'FAILURE_CREATE_MPOS_USER_ACTION';

export const SUCCESS_EXIST_LOGIN_ACTION = 'SUCCESS_EXIST_LOGIN_ACTION';
export const PENDING_EXIST_LOGIN_ACTION = 'PENDING_EXIST_LOGIN_ACTION';
export const FAILURE_EXIST_LOGIN_ACTION = 'FAILURE_EXIST_LOGIN_ACTION';

export const SUCCESS_GET_USER_ACTION = 'SUCCESS_GET_USER_ACTION';
export const PENDING_GET_USER_ACTION = 'PENDING_GET_USER_ACTION';
export const FAILURE_GET_USER_ACTION = 'FAILURE_GET_USER_ACTION';

export const SUCCESS_GET_USER_ACCESSIBLE_SCOPE_ACTION = 'SUCCESS_GET_USER_ACCESSIBLE_SCOPE_ACTION';
export const PENDING_GET_USER_ACCESSIBLE_SCOPE_ACTION = 'PENDING_GET_USER_ACCESSIBLE_SCOPE_ACTION';
export const FAILURE_GET_USER_ACCESSIBLE_SCOPE_ACTION = 'FAILURE_GET_USER_ACCESSIBLE_SCOPE_ACTION';

export const SUCCESS_GET_USER_APPLICATION_SETTINGS_ACTION = 'SUCCESS_GET_USER_APPLICATION_SETTINGS_ACTION';
export const PENDING_GET_USER_APPLICATION_SETTINGS_ACTION = 'PENDING_GET_USER_APPLICATION_SETTINGS_ACTION';
export const FAILURE_GET_USER_APPLICATION_SETTINGS_ACTION = 'FAILURE_GET_USER_APPLICATION_SETTINGS_ACTION';

export const RESET_USER_STATE_ACTION = 'RESET_USER_STATE_ACTION';

export const SUCCESS_UPDATE_USER_ACTION = 'SUCCESS_UPDATE_USER_ACTION';
export const PENDING_UPDATE_USER_ACTION = 'PENDING_UPDATE_USER_ACTION';
export const FAILURE_UPDATE_USER_ACTION = 'FAILURE_UPDATE_USER_ACTION';

export const SUCCESS_UPDATE_USER_APPLICATION_SETTINGS_ACTION =
  'SUCCESS_UPDATE_USER_APPLICATION_SETTINGS_ACTION';
export const PENDING_UPDATE_USER_APPLICATION_SETTINGS_ACTION =
  'PENDING_UPDATE_USER_APPLICATION_SETTINGS_ACTION';
export const FAILURE_UPDATE_USER_APPLICATION_SETTINGS_ACTION =
  'FAILURE_UPDATE_USER_APPLICATION_SETTINGS_ACTION';

export const PENDING_GET_MENU_ACTION = 'PENDING_GET_MENU_ACTION';
export const SUCCESS_GET_MENU_ACTION = 'SUCCESS_GET_MENU_ACTION';
export const FAILURE_GET_MENU_ACTION = 'FAILURE_GET_MENU_ACTION';
export const RESET_MENU_ACTION = 'RESET_MENU_ACTION';

export const PENDING_RESET_PASSWORD_ACTION = 'PENDING_RESET_PASSWORD_ACTION';
export const SUCCESS_RESET_PASSWORD_ACTION = 'SUCCESS_RESET_PASSWORD_ACTION';
export const FAILURE_RESET_PASSWORD_ACTION = 'FAILURE_RESET_PASSWORD_ACTION';

export const PENDING_UPDATE_PASSWORD_ACTION = 'PENDING_UPDATE_PASSWORD_ACTION';
export const SUCCESS_UPDATE_PASSWORD_ACTION = 'SUCCESS_UPDATE_PASSWORD_ACTION';
export const FAILURE_UPDATE_PASSWORD_ACTION = 'FAILURE_UPDATE_PASSWORD_ACTION';

export const PENDING_GET_CUSTOM_CSS_ACTION = 'PENDING_GET_CUSTOM_CSS_ACTION';
export const SUCCESS_GET_CUSTOM_CSS_ACTION = 'SUCCESS_GET_CUSTOM_CSS_ACTION';

export const PENDING_GET_CONFIG_ACTION = 'PENDING_GET_CONFIG_ACTION';
export const SUCCESS_GET_CONFIG_ACTION = 'SUCCESS_GET_CONFIG_ACTION';
export const FAILURE_GET_CONFIG_ACTION = 'FAILURE_GET_CONFIG_ACTION';

export const PENDING_GET_COLOR_ACTION = 'PENDING_GET_COLOR_ACTION';
export const SUCCESS_GET_COLOR_ACTION = 'SUCCESS_GET_COLOR_ACTION';
export const FAILURE_GET_COLOR_ACTION = 'FAILURE_GET_COLOR_ACTION';

export const PENDING_GET_SERVICES_ACTION = 'PENDING_GET_SERVICES_ACTION';
export const SUCCESS_GET_SERVICES_ACTION = 'SUCCESS_GET_SERVICES_ACTION';
export const FAILURE_GET_SERVICES_ACTION = 'FAILURE_GET_SERVICES_ACTION';

export const PENDING_GET_TRANSACTION_ALERTS_ACTION = 'PENDING_GET_TRANSACTION_ALERTS_ACTION';
export const SUCCESS_GET_TRANSACTION_ALERTS_ACTION = 'SUCCESS_GET_TRANSACTION_ALERTS_ACTION';
export const FAILURE_GET_TRANSACTION_ALERTS_ACTION = 'FAILURE_GET_TRANSACTION_ALERTS_ACTION';

export const PENDING_UPDATE_STATUS_TRANSACTION_ALERT_ACTION =
  'PENDING_UPDATE_STATUS_TRANSACTION_ALERT_ACTION';
export const SUCCESS_UPDATE_STATUS_TRANSACTION_ALERT_ACTION =
  'SUCCESS_UPDATE_STATUS_TRANSACTION_ALERT_ACTION';
export const FAILURE_UPDATE_STATUS_TRANSACTION_ALERT_ACTION =
  'FAILURE_UPDATE_STATUS_TRANSACTION_ALERT_ACTION';

export const PENDING_DELETE_TRANSACTION_ALERT_ACTION = 'PENDING_DELETE_TRANSACTION_ALERT_ACTION';
export const FAILURE_DELETE_TRANSACTION_ALERT_ACTION = 'FAILURE_DELETE_TRANSACTION_ALERT_ACTION';
export const SUCCESS_DELETE_TRANSACTION_ALERT_ACTION = 'SUCCESS_DELETE_TRANSACTION_ALERT_ACTION';

export const FAILURE_CREATE_TRANSACTION_ALERT_ACTION = 'FAILURE_CREATE_TRANSACTION_ALERT_ACTION';
export const PENDING_CREATE_TRANSACTION_ALERT_ACTION = 'PENDING_CREATE_TRANSACTION_ALERT_ACTION';
export const SUCCESS_CREATE_TRANSACTION_ALERT_ACTION = 'SUCCESS_CREATE_TRANSACTION_ALERT_ACTION';

export const PENDING_GET_TRANSACTION_ALERT_ACTION = 'PENDING_GET_TRANSACTION_ALERT_ACTION';
export const SUCCESS_GET_TRANSACTION_ALERT_ACTION = 'SUCCESS_GET_TRANSACTION_ALERT_ACTION';
export const FAILURE_GET_TRANSACTION_ALERT_ACTION = 'FAILURE_GET_TRANSACTION_ALERT_ACTION';

export const FAILURE_UPDATE_TRANSACTION_ALERT_ACTION = 'FAILURE_UPDATE_TRANSACTION_ALERT_ACTION';
export const PENDING_UPDATE_TRANSACTION_ALERT_ACTION = 'PENDING_UPDATE_TRANSACTION_ALERT_ACTION';
export const SUCCESS_UPDATE_TRANSACTION_ALERT_ACTION = 'SUCCESS_UPDATE_TRANSACTION_ALERT_ACTION';
export const RESET_TRANSACTION_ALERT_STATE_ACTION = 'RESET_TRANSACTION_ALERT_STATE_ACTION';

export const PENDING_GET_SCHEDULED_REPORTS_ACTION = 'PENDING_GET_SCHEDULED_REPORTS_ACTION';
export const SUCCESS_GET_SCHEDULED_REPORTS_ACTION = 'SUCCESS_GET_SCHEDULED_REPORTS_ACTION';
export const FAILURE_GET_SCHEDULED_REPORTS_ACTION = 'FAILURE_GET_SCHEDULED_REPORTS_ACTION';

export const PENDING_UPDATE_STATUS_SCHEDULED_REPORT_ACTION = 'PENDING_UPDATE_STATUS_SCHEDULED_REPORT_ACTION';
export const SUCCESS_UPDATE_STATUS_SCHEDULED_REPORT_ACTION = 'SUCCESS_UPDATE_STATUS_SCHEDULED_REPORT_ACTION';
export const FAILURE_UPDATE_STATUS_SCHEDULED_REPORT_ACTION = 'FAILURE_UPDATE_STATUS_SCHEDULED_REPORT_ACTION';

export const PENDING_DELETE_SCHEDULED_REPORT_ACTION = 'PENDING_DELETE_SCHEDULED_REPORT_ACTION';
export const SUCCESS_DELETE_SCHEDULED_REPORT_ACTION = 'SUCCESS_DELETE_SCHEDULED_REPORT_ACTION';
export const FAILURE_DELETE_SCHEDULED_REPORT_ACTION = 'FAILURE_DELETE_SCHEDULED_REPORT_ACTION';

export const PENDING_CREATE_SCHEDULED_REPORT_ACTION = 'PENDING_CREATE_SCHEDULED_REPORT_ACTION';
export const SUCCESS_CREATE_SCHEDULED_REPORT_ACTION = 'SUCCESS_CREATE_SCHEDULED_REPORT_ACTION';
export const FAILURE_CREATE_SCHEDULED_REPORT_ACTION = 'FAILURE_CREATE_SCHEDULED_REPORT_ACTION';

export const PENDING_GET_FREQUENCIES_REPORT_ACTION = 'PENDING_GET_FREQUENCIES_REPORT_ACTION';
export const SUCCESS_GET_FREQUENCIES_REPORT_ACTION = 'SUCCESS_GET_FREQUENCIES_REPORT_ACTION';
export const FAILURE_GET_FREQUENCIES_REPORT_ACTION = 'FAILURE_GET_FREQUENCIES_REPORT_ACTION';

export const PENDING_GET_SCHEDULED_REPORT_ACTION = 'PENDING_GET_SCHEDULED_REPORT_ACTION';
export const SUCCESS_GET_SCHEDULED_REPORT_ACTION = 'SUCCESS_GET_SCHEDULED_REPORT_ACTION';
export const FAILURE_GET_SCHEDULED_REPORT_ACTION = 'FAILURE_GET_SCHEDULED_REPORT_ACTION';

export const PENDING_UPDATE_SCHEDULED_REPORT_ACTION = 'PENDING_UPDATE_SCHEDULED_REPORT_ACTION';
export const SUCCESS_UPDATE_SCHEDULED_REPORT_ACTION = 'SUCCESS_UPDATE_SCHEDULED_REPORT_ACTION';
export const FAILURE_UPDATE_SCHEDULED_REPORT_ACTION = 'FAILURE_UPDATE_SCHEDULED_REPORT_ACTION';

export const PENDING_GET_SCHEDULER_JOBS_ACTION = 'PENDING_GET_SCHEDULER_JOBS_ACTION';
export const SUCCESS_GET_SCHEDULER_JOBS_ACTION = 'SUCCESS_GET_SCHEDULER_JOBS_ACTION';
export const FAILURE_GET_SCHEDULER_JOBS_ACTION = 'FAILURE_GET_SCHEDULER_JOBS_ACTION';

export const PENDING_FIRE_SCHEDULER_JOB_ACTION = 'PENDING_FIRE_SCHEDULER_JOB_ACTION';
export const SUCCESS_FIRE_SCHEDULER_JOB_ACTION = 'SUCCESS_FIRE_SCHEDULER_JOB_ACTION';
export const FAILURE_FIRE_SCHEDULER_JOB_ACTION = 'FAILURE_FIRE_SCHEDULER_JOB_ACTION';

export const PENDING_DELETE_SCHEDULER_JOB_ACTION = 'PENDING_DELETE_SCHEDULER_JOB_ACTION';
export const SUCCESS_DELETE_SCHEDULER_JOB_ACTION = 'SUCCESS_DELETE_SCHEDULER_JOB_ACTION';
export const FAILURE_DELETE_SCHEDULER_JOB_ACTION = 'FAILURE_DELETE_SCHEDULER_JOB_ACTION';

export const PENDING_CREATE_SCHEDULER_JOB_ACTION = 'PENDING_CREATE_SCHEDULER_JOB_ACTION';
export const SUCCESS_CREATE_SCHEDULER_JOB_ACTION = 'SUCCESS_CREATE_SCHEDULER_JOB_ACTION';
export const FAILURE_CREATE_SCHEDULER_JOB_ACTION = 'FAILURE_CREATE_SCHEDULER_JOB_ACTION';

export const COLLAPSE_MENU_ACTION = 'COLLAPSE_MENU_ACTION';
export const UN_COLLAPSE_MENU_ACTION = 'UN_COLLAPSE_MENU_ACTION';

export const PENDING_GET_VERSION_ACTION = 'PENDING_GET_VERSION_ACTION';
export const SUCCESS_GET_VERSION_ACTION = 'SUCCESS_GET_VERSION_ACTION';
export const FAILURE_GET_VERSION_ACTION = 'FAILURE_GET_VERSION_ACTION';

export const PENDING_GET_SMS_PROVIDERS_ACTION = 'PENDING_GET_SMS_PROVIDERS_ACTION';
export const SUCCESS_GET_SMS_PROVIDERS_ACTION = 'SUCCESS_GET_SMS_PROVIDERS_ACTION';
export const FAILURE_GET_SMS_PROVIDERS_ACTION = 'FAILURE_GET_SMS_PROVIDERS_ACTION';

export const PENDING_GET_COMMENT_ACTION = 'PENDING_GET_COMMENT_ACTION';
export const SUCCESS_GET_COMMENT_ACTION = 'SUCCESS_GET_COMMENT_ACTION';
export const FAILURE_GET_COMMENT_ACTION = 'FAILURE_GET_COMMENT_ACTION';
export const RESET_COMMENT_STATE_ACTION = 'RESET_OFFER_STATE_ACTION';

export const FAILURE_UPDATE_COMMENT_ACTION = 'FAILURE_UPDATE_COMMENT_ACTION';
export const PENDING_UPDATE_COMMENT_ACTION = 'PENDING_UPDATE_COMMENT_ACTION';
export const SUCCESS_UPDATE_COMMENT_ACTION = 'SUCCESS_UPDATE_COMMENT_ACTION';

export const PENDING_GET_CURRENCIES_ACTION = 'PENDING_GET_CURRENCIES_ACTION';
export const SUCCESS_GET_CURRENCIES_ACTION = 'SUCCESS_GET_CURRENCIES_ACTION';
export const FAILURE_GET_CURRENCIES_ACTION = 'FAILURE_GET_CURRENCIES_ACTION';

export const SET_SEARCH_CONTEXT_ACTION = 'SET_SEARCH_CONTEXT_ACTION';
export const CLEAN_SEARCH_CONTEXT_ACTION = 'CLEAN_SEARCH_CONTEXT_ACTION';

export const PENDING_GET_PERMISSIONS_FORM_ACTION = 'PENDING_GET_PERMISSIONS_FORM_ACTION';
export const SUCCESS_GET_PERMISSIONS_FORM_ACTION = 'SUCCESS_GET_PERMISSIONS_FORM_ACTION';
export const FAILURE_GET_PERMISSIONS_FORM_ACTION = 'FAILURE_GET_PERMISSIONS_FORM_ACTION';

export const SUCCESS_UPDATE_USER_SETTINGS_ACTION = 'SUCCESS_UPDATE_USER_SETTINGS_ACTION';
export const PENDING_UPDATE_USER_SETTINGS_ACTION = 'PENDING_UPDATE_USER_SETTINGS_ACTION';
export const FAILURE_UPDATE_USER_SETTINGS_ACTION = 'FAILURE_UPDATE_USER_SETTINGS_ACTION';

export const SUCCESS_UPDATE_USER_LANGUAGE_ACTION = 'SUCCESS_UPDATE_USER_LANGUAGE_ACTION';
export const PENDING_UPDATE_USER_LANGUAGE_ACTION = 'PENDING_UPDATE_USER_LANGUAGE_ACTION';
export const FAILURE_UPDATE_USER_LANGUAGE_ACTION = 'FAILURE_UPDATE_USER_LANGUAGE_ACTION';

export const PENDING_GET_PALETTES_ACTION = 'PENDING_GET_PALETTES_ACTION';
export const SUCCESS_GET_PALETTES_ACTION = 'SUCCESS_GET_PALETTES_ACTION';
export const FAILURE_GET_PALETTES_ACTION = 'FAILURE_GET_PALETTES_ACTION';

export const PENDING_GET_SECOND_FACTORS_ACTION = 'PENDING_GET_SECOND_FACTORS_ACTION';
export const SUCCESS_GET_SECOND_FACTORS_ACTION = 'SUCCESS_GET_SECOND_FACTORS_ACTION';
export const FAILURE_GET_SECOND_FACTORS_ACTION = 'FAILURE_GET_SECOND_FACTORS_ACTION';

export const FAILURE_ADD_SECOND_FACTOR_ACTION = 'FAILURE_ADD_SECOND_FACTOR_ACTION';
export const PENDING_ADD_SECOND_FACTOR_ACTION = 'PENDING_ADD_SECOND_FACTOR_ACTION';
export const SUCCESS_ADD_SECOND_FACTOR_ACTION = 'SUCCESS_ADD_SECOND_FACTOR_ACTION';

export const FAILURE_DELETE_SECOND_FACTOR_ACTION = 'FAILURE_DELETE_SECOND_FACTOR_ACTION';
export const PENDING_DELETE_SECOND_FACTOR_ACTION = 'PENDING_DELETE_SECOND_FACTOR_ACTION';
export const SUCCESS_DELETE_SECOND_FACTOR_ACTION = 'SUCCESS_DELETE_SECOND_FACTOR_ACTION';

export const SET_MODE_ACTION = 'SET_DARK_MODE_ACTION';
export const GET_MODE_ACTION = 'GET_DARK_MODE_ACTION';

export const SUCCESS_GET_TAXAUDITS_ACTION = 'SUCCESS_GET_TAXAUDITS_ACTION';
export const SUCCESS_GET_TAX_AUDIT_SCROLL_ACTION = 'SUCCESS_GET_TAX_AUDIT_SCROLL_ACTION';
export const PENDING_GET_TAXAUDITS_ACTION = 'PENDING_GET_TAXAUDITS_ACTION';
export const PENDING_GET_TAX_AUDIT_SCROLL_ACTION = 'PENDING_GET_TAX_AUDIT_SCROLL_ACTION';
export const FAILURE_GET_TAXAUDITS_ACTION = 'FAILURE_GET_TAXAUDITS_ACTION';
export const FAILURE_GET_TAX_AUDIT_SCROLL_ACTION = 'FAILURE_GET_TAX_AUDIT_SCROLL_ACTION';
export const RESET_TAXAUDITS_ACTION = 'RESET_TAXAUDITS_ACTION';

export const SUCCESS_GET_TAX_AUDIT_LOG_ACTION = 'SUCCESS_GET_TAX_AUDIT_LOG_ACTION';
export const SUCCESS_GET_TAX_AUDIT_LOG_SCROLL_ACTION = 'SUCCESS_GET_TAX_AUDIT_LOG_SCROLL_ACTION';
export const PENDING_GET_TAX_AUDIT_LOG_ACTION = 'PENDING_GET_TAX_AUDIT_LOG_ACTION';
export const PENDING_GET_TAX_AUDIT_LOG_SCROLL_ACTION = 'PENDING_GET_TAX_AUDIT_LOG_SCROLL_ACTION';
export const FAILURE_GET_TAX_AUDIT_LOG_ACTION = 'FAILURE_GET_TAX_AUDIT_LOG_ACTION';
export const FAILURE_GET_TAX_AUDIT_LOG_SCROLL_ACTION = 'FAILURE_GET_TAX_AUDIT_LOG_SCROLL_ACTION';
export const RESET_TAX_AUDIT_LOG_ACTION = 'RESET_TAX_AUDIT_LOGS_ACTION';

export const SUCCESS_UPDATE_STATUS_TAX_AUDIT_LOG_ACTION = 'SUCCESS_UPDATE_STATUS_TAX_AUDIT_LOG_ACTION';
export const PENDING_UPDATE_STATUS_TAX_AUDIT_LOG_ACTION = 'PENDING_UPDATE_STATUS_TAX_AUDIT_LOG_ACTION';
export const FAILURE_UPDATE_STATUS_TAX_AUDIT_LOG_ACTION = 'FAILURE_UPDATE_STATUS_TAX_AUDIT_LOG_ACTION';

export const SUCCESS_GET_TAX_AUDIT_LOG_DETAILS_ACTION = 'SUCCESS_GET_TAX_AUDIT_LOG_DETAILS_ACTION';
export const SUCCESS_GET_TAX_AUDIT_LOG_DETAILS_SCROLL_ACTION =
  'SUCCESS_GET_TAX_AUDIT_LOG_DETAILS_SCROLL_ACTION';
export const PENDING_GET_TAX_AUDIT_LOG_DETAILS_ACTION = 'PENDING_GET_TAX_AUDIT_LOG_DETAILS_ACTION';
export const PENDING_GET_TAX_AUDIT_LOG_DETAILS_SCROLL_ACTION =
  'PENDING_GET_TAX_AUDIT_LOG_DETAILS_SCROLL_ACTION';
export const FAILURE_GET_TAX_AUDIT_LOG_DETAILS_ACTION = 'FAILURE_GET_TAX_AUDIT_LOG_DETAILS_ACTION';
export const FAILURE_GET_TAX_AUDIT_LOG_DETAILS_SCROLL_ACTION =
  'FAILURE_GET_TAX_AUDIT_LOG_DETAILS_SCROLL_ACTION';
export const RESET_TAX_AUDIT_LOG_DETAILS_ACTION = 'RESET_TAX_AUDIT_LOG_DETAILSG_ACTION';

export const PENDING_GET_SESSIONS_ACTION = 'PENDING_GET_SESSIONS_ACTION';
export const SUCCESS_GET_SESSIONS_ACTION = 'SUCCESS_GET_SESSIONS_ACTION';
export const FAILURE_GET_SESSIONS_ACTION = 'FAILURE_GET_SESSIONS_ACTION';

export const PENDING_DELETE_SESSION_ACTION = 'PENDING_DELETE_SESSION_ACTION';
export const FAILURE_DELETE_SESSION_ACTION = 'FAILURE_DELETE_SESSION_ACTION';
export const SUCCESS_DELETE_SESSION_ACTION = 'SUCCESS_DELETE_SESSION_ACTION';

export const INIT_SEARCH_CONTEXT_ACTION = 'INIT_SEARCH_CONTEXT_ACTION';

export const PENDING_POST_DEVICE_VERIFICATION_ACTION = 'PENDING_POST_DEVICE_VERIFICATION_ACTION';
export const SUCCESS_POST_DEVICE_VERIFICATION_ACTION = 'SUCCESS_POST_DEVICE_VERIFICATION_ACTION';
export const FAILURE_POST_DEVICE_VERIFICATION_ACTION = 'FAILURE_POST_DEVICE_VERIFICATION_ACTION';
export const RESET_DEVICE_VERIFICATION_STATE_ACTION = 'RESET_DEVICE_VERIFICATION_STATE_ACTION';
