import {
  PENDING_POST_DEVICE_VERIFICATION_ACTION,
  SUCCESS_POST_DEVICE_VERIFICATION_ACTION,
  FAILURE_POST_DEVICE_VERIFICATION_ACTION,
  RESET_DEVICE_VERIFICATION_STATE_ACTION
} from '../actionsTypes';

const initialState = {
  verification: {
    done: false,
    loading: false,
    error: false
  }
};

const deviceFlowReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_POST_DEVICE_VERIFICATION_ACTION: {
      return {
        ...state,
        verification: {
          ...initialState.verification,
          loading: true
        }
      };
    }
    case SUCCESS_POST_DEVICE_VERIFICATION_ACTION: {
      return {
        ...state,
        verification: {
          ...initialState.verification,
          done: true,
          loading: false
        }
      };
    }
    case FAILURE_POST_DEVICE_VERIFICATION_ACTION: {
      return {
        ...state,
        verification: {
          ...initialState.verification,
          loading: false,
          error: true
        }
      };
    }
    case RESET_DEVICE_VERIFICATION_STATE_ACTION:
      return initialState;
    default:
      return state;
  }
};

export default deviceFlowReducer;
