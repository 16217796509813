import get from 'get-value';
import {
  PENDING_GET_SIMS_ACTION,
  SUCCESS_GET_SIMS_ACTION,
  FAILURE_GET_SIMS_ACTION,
  RESET_SIMS_ACTION,
  SUCCESS_UPDATE_STATUS_SIMS_ACTION,
  SUCCESS_GET_SIMS_SCROLL_ACTION,
  SUCCESS_DELETE_SIM_ACTION
} from '../actionsTypes';
import {
  initSelection,
  onStatusUpdated,
  onToggleSelectAll,
  onToggleUnSelectAll,
  filterId
} from './selection';

import { reduceCount } from '../actions/sseActions';

const initialState = {
  data: [],
  selection: {},
  loading: true,
  error: false,
  errors: {}
};

const simsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_SIMS_ACTION: {
      return {
        ...state,
        selection: {},
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_SIMS_ACTION: {
      const {
        payload: { count, scrollId, data }
      } = action;

      const selection = initSelection({ data, key: 'sim' });

      return {
        ...state,
        data,
        selection,
        count,
        scrollId,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_SIMS_SCROLL_ACTION: {
      const {
        payload: { data: scrollData }
      } = action;
      const data = [...state.data, ...scrollData];
      const selection = {
        ...initSelection({ data, key: 'sim' }),
        ...state.selection
      };

      return {
        ...state,
        data,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_SIMS_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        selection: {},
        loading: false,
        error: true,
        ...errors
      };
    }
    case RESET_SIMS_ACTION: {
      return {
        ...state,
        ...initialState
      };
    }
    case `TOGGLE_SELECT_SIMS_ACTION`:
    case `TOGGLE_UNSELECT_SIMS_ACTION`: {
      const { id } = action.payload;
      const {
        selection: { [id]: value },
        data
      } = state;
      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find((sim) => get(sim, 'sim.id') === id) : false
        }
      };
    }
    case `TOGGLE_SELECT_ALL_SIMS_ACTION`: {
      const { selectionDisabled } = action.payload;
      const { selection, data } = state;
      const nextSelection = onToggleSelectAll({
        selection,
        data,
        keyId: 'sim.id',
        selectionDisabled
      });
      return {
        ...state,
        selection: nextSelection
      };
    }
    case `TOGGLE_UNSELECT_ALL_SIMS_ACTION`: {
      const { selection } = state;
      const nextSelection = onToggleUnSelectAll({ selection });
      return {
        ...state,
        selection: nextSelection
      };
    }
    case SUCCESS_UPDATE_STATUS_SIMS_ACTION: {
      const {
        payload: { status, ids }
      } = action;
      const { data } = state;
      const nextData = onStatusUpdated({ data, ids, status, key: 'sim' });
      return {
        ...state,
        data: nextData
      };
    }
    case SUCCESS_DELETE_SIM_ACTION: {
      const {
        payload: { id }
      } = action;

      const { data, selection: prevSelection, count } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });
      const nextData = filterId({ data, keyValue: id, keyId: 'sim.id' });
      const countNumber = parseInt(count);
      const nextCount = reduceCount({ countNumber, data, nextData });

      return {
        ...state,
        selection,
        count: nextCount.toString(),
        data: nextData
      };
    }
    default:
      return state;
  }
};

export default simsReducer;
