import {
  FAILURE_GET_OFFERS_ACTION,
  PENDING_GET_OFFERS_ACTION,
  SUCCESS_GET_OFFERS_ACTION
} from '../actionsTypes';
import { initSelection, onToggleSelectAll, onToggleUnSelectAll } from './selection';
import get from 'get-value';

const initialState = {
  data: [],
  loading: false,
  selection: {},
  error: false,
  errors: {}
};

const offersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_OFFERS_ACTION:
      return {
        ...state,
        selection: {},
        loading: true,
        error: false
      };
    case SUCCESS_GET_OFFERS_ACTION:
      const {
        payload: { offers }
      } = action;
      const selection = initSelection({ data: offers, keyId: 'id' });

      return {
        ...state,
        data: offers,
        selection
      };
    case FAILURE_GET_OFFERS_ACTION:
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        data: initialState.data,
        selection: {},
        loading: false,
        error: true,
        ...errors
      };
    case 'TOGGLE_SELECT_OFFERS_ACTION':
    case 'TOGGLE_UNSELECT_OFFERS_ACTION': {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find((offer) => get(offer, 'id') === id) : false
        }
      };
    }
    case 'TOGGLE_SELECT_ALL_OFFERS_ACTION': {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: 'id'
      });

      return {
        ...state,
        selection
      };
    }
    case 'TOGGLE_UNSELECT_ALL_OFFERS_ACTION': {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    default:
      return state;
  }
};

export default offersReducer;
