import {
  FAILURE_GET_SESSIONS_ACTION,
  PENDING_GET_SESSIONS_ACTION,
  SUCCESS_GET_SESSIONS_ACTION,
  SUCCESS_DELETE_SESSION_ACTION
} from '../actionsTypes';

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

const _onSessionDeleted = ({ sessionId, sessions }) => {
  return sessions.filter((session) => {
    const { id } = session;

    return id !== sessionId;
  });
};

const sessionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_SESSIONS_ACTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS_GET_SESSIONS_ACTION:
      const { sessions } = action.payload;
      return {
        ...state,
        ...initialState,
        data: sessions
      };
    case FAILURE_GET_SESSIONS_ACTION:
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    case SUCCESS_DELETE_SESSION_ACTION: {
      const {
        payload: { sessionId }
      } = action;
      const { data: prevPrograms } = state;
      const sessions = _onSessionDeleted({ sessionId, sessions: prevPrograms });

      return {
        ...state,
        data: sessions
      };
    }
    default:
      return state;
  }
};

export default sessionsReducer;
