import _ from 'lodash';
import history from '../history';
import moment from 'moment';
import TimePeriod from '../reporting/models/TimePeriod';
import { SearchContext } from './SearchContext';

const PeriodTypesWithExtraAttributes = [TimePeriod.EXACT.type, TimePeriod.BETWEEN.type];

const SEARCH_PARAMS_KEY = 'searchParams';

export const serializeSearchContext = (searchContext: SearchContext) => {
  const lightSearchPeriod: any = {};

  if (searchContext && searchContext.timePeriod) {
    lightSearchPeriod.type = searchContext.timePeriod.type;
  }

  if (_.includes(PeriodTypesWithExtraAttributes, searchContext.timePeriod && searchContext.timePeriod.type)) {
    lightSearchPeriod.startTime = searchContext.timePeriod.startTime.valueOf();
    lightSearchPeriod.endTime = searchContext.timePeriod.endTime.valueOf();
  }

  const lightSearchContext = {
    filtersByAdvancedSearchKey: searchContext.filtersByAdvancedSearchKey,
    sortByAdvancedSearchKey: searchContext.sortByAdvancedSearchKey,
    timePeriod: lightSearchPeriod,
    currency: searchContext.currency
  } as SearchContext;

  return JSON.stringify(lightSearchContext);
};

export const deserializeSearchContext = (searchParamsStr: string): SearchContext => {
  try {
    const searchParams = (searchParamsStr ? JSON.parse(searchParamsStr) : {}) as SearchContext;
    const result = {} as SearchContext;

    if (searchParams.timePeriod && !_.isEmpty(searchParams.timePeriod)) {
      result.timePeriod = TimePeriod[searchParams.timePeriod.type];

      if (_.includes(PeriodTypesWithExtraAttributes, searchParams.timePeriod.type)) {
        result.timePeriod.startTime = moment(searchParams.timePeriod.startTime);
        result.timePeriod.endTime = moment(searchParams.timePeriod.endTime);

        if (result.timePeriod.type === TimePeriod.BETWEEN.type) {
          result.timePeriod.setBetweenFromTimePeriod(result.timePeriod.startTime, result.timePeriod.endTime);
        }
      }
    }

    if (searchParams.currency) {
      result.currency = searchParams.currency;
    }

    if (searchParams.filtersByAdvancedSearchKey) {
      result.filtersByAdvancedSearchKey = searchParams.filtersByAdvancedSearchKey;
    }

    if (searchParams.sortByAdvancedSearchKey) {
      result.sortByAdvancedSearchKey = searchParams.sortByAdvancedSearchKey;
    }

    return result;
  } catch (e) {
    return null;
  }
};

export const getParameterByName = (name, url) => {
  let locationHref;
  if (typeof window !== 'undefined') {
    locationHref = window.location.href;
  }
  url = url || locationHref;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return null;

  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const setUrlWithParams = (searchContext: SearchContext, currentPath: string) => {
  const urlParams = serializeSearchContext(searchContext);

  history.replace({
    pathname: currentPath,
    search: `?${SEARCH_PARAMS_KEY}=${encodeURIComponent(urlParams)}`
  });
};

export const extractUrlSearchParams = (url?: string) => {
  return deserializeSearchContext(decodeURIComponent(getParameterByName(SEARCH_PARAMS_KEY, url)));
};
