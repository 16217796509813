import {
  FAILURE_GET_WIDGETS_ACTION,
  PENDING_GET_WIDGETS_ACTION,
  SUCCESS_GET_WIDGETS_ACTION,
  RESET_WIDGETS_STATE_ACTION,
  PENDING_DELETE_WIDGET_ACTION,
  SUCCESS_DELETE_WIDGET_ACTION,
  FAILURE_DELETE_WIDGET_ACTION,
  SUCCESS_UPDATE_WIDGET_ACTION,
  FAILURE_UPDATE_WIDGET_ACTION,
  PENDING_GET_AVAILABLE_WIDGETS_ACTION,
  SUCCESS_GET_AVAILABLE_WIDGETS_ACTION,
  FAILURE_GET_AVAILABLE_WIDGETS_ACTION,
  RESET_AVAILABLE_WIDGETS_STATE_ACTION
} from '../actionsTypes';

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {},
  availableWidgets: {
    data: [],
    loading: false,
    error: false,
    errors: {}
  }
};

const removeWidgetId = ({ id, widgets }) => {
  return widgets.reduce((prevWidget, widget) => {
    return widget.id !== id ? prevWidget.concat(widget) : prevWidget;
  }, []);
};

const widgetsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_WIDGETS_ACTION: {
      return {
        ...state,
        data: [],
        loading: true,
        error: false
      };
    }
    case SUCCESS_GET_WIDGETS_ACTION: {
      const { widgets: data } = action.payload;
      return {
        ...state,
        data,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_WIDGETS_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case PENDING_DELETE_WIDGET_ACTION:
      return state;
    case SUCCESS_DELETE_WIDGET_ACTION: {
      const { id } = action.payload;
      const widgets = removeWidgetId({ id, widgets: state.data });
      return {
        ...state,
        data: widgets,
        loading: false,
        error: false
      };
    }
    case SUCCESS_UPDATE_WIDGET_ACTION: {
      const { widgets: data } = action.payload;
      return {
        ...state,
        data,
        loading: false,
        error: false
      };
    }
    case FAILURE_UPDATE_WIDGET_ACTION:
      return state;
    case FAILURE_DELETE_WIDGET_ACTION:
      return state;
    case RESET_WIDGETS_STATE_ACTION:
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: false,
        errors: {}
      };
    case PENDING_GET_AVAILABLE_WIDGETS_ACTION: {
      return {
        ...state,
        availableWidgets: {
          data: [],
          loading: true,
          error: false,
          errors: {}
        }
      };
    }
    case SUCCESS_GET_AVAILABLE_WIDGETS_ACTION: {
      const { widgets: data } = action.payload;
      return {
        ...state,
        availableWidgets: {
          data,
          loading: false,
          error: false,
          errors: {}
        }
      };
    }
    case FAILURE_GET_AVAILABLE_WIDGETS_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        availableWidgets: {
          data: initialState.availableWidgets.data,
          loading: false,
          error: true,
          ...errors
        }
      };
    }
    case RESET_AVAILABLE_WIDGETS_STATE_ACTION: {
      return {
        ...state,
        availableWidgets: initialState.availableWidgets
      };
    }
    default:
      return state;
  }
};

export default widgetsReducer;
