/**
 * @secure
 */
import moment from 'moment';

require('moment/min/locales');

//Set locale from browser
if (typeof window !== 'undefined') {
  moment.locale(window.navigator.language);
}

const NBR_MIN_IN_HOUR = 3600;
const NBR_MS_IN_SEC = 1000;
const NBR_HOUR_IN_DAY = 24;
const NBR_DAY_IN_MONTH = 30;

const HOUR_INTERVAL = NBR_MIN_IN_HOUR * NBR_MS_IN_SEC;
const DAY_INTERVAL = NBR_HOUR_IN_DAY * HOUR_INTERVAL;
const MONTH_INTERVAL = NBR_DAY_IN_MONTH * DAY_INTERVAL;

export enum PeriodType {
  EXACT = 'Minute',
  DAY = 'Day',
  MONTH = 'Month',
  WEEK = 'Week',
  YEAR = 'Year',
  BETWEEN = 'Between'
}

export enum PeriodSelection {
  INTRADAY = 'INTRADAY',
  PREVIOUS_DAY = 'PREVIOUS_DAY',
  WEEK_TO_DATE = 'WEEK_TO_DATE',
  LAST_WEEK = 'LAST_WEEK',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  LAST_MONTH = 'LAST_MONTH',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  LAST_YEAR = 'LAST_YEAR',
  EXACT = 'EXACT',
  BETWEEN = 'BETWEEN'
}

export const MatchPreviousPeriod = ({ timePeriod }) => {
  switch (timePeriod.type) {
    case PeriodSelection.INTRADAY: {
      return PeriodSelection.PREVIOUS_DAY;
    }
    case PeriodSelection.WEEK_TO_DATE: {
      return PeriodSelection.LAST_WEEK;
    }
    case PeriodSelection.MONTH_TO_DATE: {
      return PeriodSelection.LAST_MONTH;
    }
    case PeriodSelection.YEAR_TO_DATE: {
      return PeriodSelection.LAST_YEAR;
    }
    default:
      break;
  }
};

export function toPeriodType(type: PeriodSelection): PeriodType {
  if (type === PeriodSelection.INTRADAY || type === PeriodSelection.PREVIOUS_DAY) {
    return PeriodType.DAY;
  } else if (type === PeriodSelection.WEEK_TO_DATE || type === PeriodSelection.LAST_WEEK) {
    return PeriodType.WEEK;
  } else if (type === PeriodSelection.MONTH_TO_DATE || type === PeriodSelection.LAST_MONTH) {
    return PeriodType.MONTH;
  } else if (type === PeriodSelection.BETWEEN) {
    return PeriodType.BETWEEN;
  } else if (type === PeriodSelection.EXACT) {
    return PeriodType.EXACT;
  } else {
    return PeriodType.YEAR;
  }
}

export class TimePeriod {
  type: PeriodSelection;
  startTimeFunction: (time: moment.Moment) => moment.Moment;
  endTimeFunction: (time: moment.Moment) => moment.Moment;
  startTime: moment.Moment;
  endTime: moment.Moment;
  tickInterval: number;
  units: Array<[string, Array<number>]>;

  constructor(
    type: PeriodSelection,
    startTimeFunction: (time: moment.Moment) => moment.Moment,
    endTimeFunction: (time: moment.Moment) => moment.Moment,
    tickInterval: number,
    units: Array<[string, Array<number>]>
  ) {
    this.type = type;
    this.startTimeFunction = startTimeFunction;
    this.endTimeFunction = endTimeFunction;
    this.startTime = null;
    this.endTime = null;
    this.tickInterval = tickInterval;
    this.units = units;
    this.refreshTime();
  }

  refreshTime() {
    const localNow = moment();

    if (this.startTimeFunction && this.endTimeFunction) {
      //moment's instance are f*ing mutable :( Clone is mandatory
      this.startTime = this.startTimeFunction(localNow.clone()).clone();
      this.endTime = this.endTimeFunction(localNow.clone()).clone();
    }
  }

  setBetweenFromTimePeriod(startTime, endTime) {
    this.startTime = startTime;
    this.endTime = endTime;

    const difference = this.endTime.valueOf() - this.startTime.valueOf() + 1;

    const dayInMs = moment.duration(1, 'days').asMilliseconds();
    const monthInMs = moment.duration(1, 'months').asMilliseconds();
    const yearInMs = moment.duration(1, 'years').asMilliseconds();

    if (difference <= dayInMs) {
      this.tickInterval = HOUR_INTERVAL;
      this.units = [['hour', [1]]];
    } else if (difference > dayInMs && difference <= monthInMs) {
      this.tickInterval = DAY_INTERVAL;
      this.units = [['day', [1]]];
    } else if (difference <= yearInMs) {
      this.tickInterval = MONTH_INTERVAL;
      this.units = [['month', [1]]];
    }
  }

  clone() {
    return new TimePeriod(
      this.type,
      this.startTimeFunction,
      this.endTimeFunction,
      this.tickInterval,
      this.units
    );
  }

  static fromPeriodSelection(periodSelection: PeriodSelection): TimePeriod {
    switch (periodSelection) {
      case PeriodSelection.INTRADAY:
        return intradayPeriod;
      case PeriodSelection.PREVIOUS_DAY:
        return previousDayPeriod;
      case PeriodSelection.WEEK_TO_DATE:
        return weekToDatePeriod;
      case PeriodSelection.MONTH_TO_DATE:
        return monthToDatePeriod;
      case PeriodSelection.YEAR_TO_DATE:
        return yearToDatePeriod;
      case PeriodSelection.BETWEEN:
        return betweenPeriod;
      case PeriodSelection.EXACT:
        return exactPeriod;
      case PeriodSelection.LAST_WEEK:
        return lastWeekPeriod;
      case PeriodSelection.LAST_MONTH:
        return lastMonthPeriod;
      case PeriodSelection.LAST_YEAR:
        return lastYearPeriod;
      default:
        return yearToDatePeriod;
    }
  }
}

const intradayPeriod = new TimePeriod(
  PeriodSelection.INTRADAY,
  (start) => start.startOf('day'),
  (end) => end,
  HOUR_INTERVAL,
  [['hour', [1]]]
);
const previousDayPeriod = new TimePeriod(
  PeriodSelection.PREVIOUS_DAY,
  (start) => start.startOf('day').subtract(1, 'days'),
  (end) => end.endOf('day').subtract(1, 'days'),
  HOUR_INTERVAL,
  [['hour', [1]]]
);
const weekToDatePeriod = new TimePeriod(
  PeriodSelection.WEEK_TO_DATE,
  (start) => start.startOf('day').startOf('week'),
  (end) => end,
  DAY_INTERVAL,
  [['day', [1]]]
);
const monthToDatePeriod = new TimePeriod(
  PeriodSelection.MONTH_TO_DATE,
  (start) => start.startOf('day').startOf('month'),
  (end) => end,
  DAY_INTERVAL,
  [['day', [1]]]
);
const yearToDatePeriod = new TimePeriod(
  PeriodSelection.YEAR_TO_DATE,
  (start) => start.startOf('day').startOf('year'),
  (end) => end,
  MONTH_INTERVAL,
  [['month', [1]]]
);
const betweenPeriod = new TimePeriod(
  PeriodSelection.BETWEEN,
  (start) => start.startOf('minute'),
  (end) => end.endOf('minute'),
  HOUR_INTERVAL,
  [['hour', [1]]]
);
const exactPeriod = new TimePeriod(
  PeriodSelection.EXACT,
  (start) => start.startOf('minute'),
  (end) => end.add(1, 'minutes').startOf('minute'),
  NBR_MS_IN_SEC,
  [['minute', [1]]]
);
const lastWeekPeriod = new TimePeriod(
  PeriodSelection.LAST_WEEK,
  (start) => start.startOf('day').startOf('week').subtract(1, 'weeks'),
  (end) => end.endOf('day').endOf('week').subtract(1, 'weeks'),
  DAY_INTERVAL,
  [['day', [1]]]
);
const lastMonthPeriod = new TimePeriod(
  PeriodSelection.LAST_MONTH,
  (start) => start.subtract(1, 'months').startOf('month'),
  (end) => end.subtract(1, 'months').endOf('month'),
  DAY_INTERVAL,
  [['day', [1]]]
);
const lastYearPeriod = new TimePeriod(
  PeriodSelection.LAST_YEAR,
  (start) => start.startOf('day').startOf('year').subtract(1, 'years'),
  (end) => end.endOf('day').endOf('year').subtract(1, 'years'),
  MONTH_INTERVAL,
  [['month', [1]]]
);
export default {
  INTRADAY: intradayPeriod,
  PREVIOUS_DAY: previousDayPeriod,
  WEEK_TO_DATE: weekToDatePeriod,
  MONTH_TO_DATE: monthToDatePeriod,
  YEAR_TO_DATE: yearToDatePeriod,
  BETWEEN: betweenPeriod,
  EXACT: exactPeriod,
  LAST_WEEK: lastWeekPeriod,
  LAST_MONTH: lastMonthPeriod,
  LAST_YEAR: lastYearPeriod
};
