import {
  PENDING_GET_SCHEDULED_REPORTS_ACTION,
  SUCCESS_GET_SCHEDULED_REPORTS_ACTION,
  FAILURE_GET_SCHEDULED_REPORTS_ACTION,
  SUCCESS_UPDATE_STATUS_SCHEDULED_REPORT_ACTION,
  SUCCESS_DELETE_SCHEDULED_REPORT_ACTION,
  PENDING_GET_FREQUENCIES_REPORT_ACTION,
  SUCCESS_GET_FREQUENCIES_REPORT_ACTION,
  FAILURE_GET_FREQUENCIES_REPORT_ACTION
} from '../actionsTypes';
import { initSelection, onToggleSelectAll, onToggleUnSelectAll } from './selection';
import get from 'get-value';

const initialState = {
  data: [],
  loading: false,
  selection: {},
  error: false,
  errors: {},
  scheduledFrequencies: {
    data: [],
    loading: false,
    error: false,
    errors: {}
  }
};

const _deleteScheduledReport = ({ scheduledReportId, scheduledReports }) => {
  return scheduledReports.filter((scheme) => {
    const { id } = scheme;

    return id !== scheduledReportId;
  });
};

const _updateStatus = ({ scheduledReportId, scheduledReports, status }) => {
  const reports = scheduledReports.reduce((acc, current) => {
    const { id } = current;

    acc.push({
      ...current,
      ...(id === scheduledReportId ? { active: status } : {})
    });
    return acc;
  }, []);
  return reports;
};

const scheduledReportsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_SCHEDULED_REPORTS_ACTION: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    case PENDING_GET_FREQUENCIES_REPORT_ACTION: {
      return {
        ...state,
        scheduledFrequencies: {
          ...state.scheduledFrequencies,
          ...initialState.scheduledFrequencies,
          loading: true
        }
      };
    }
    case SUCCESS_GET_SCHEDULED_REPORTS_ACTION: {
      const {
        payload: { scheduledReports: data }
      } = action;
      const selection = initSelection({ data, keyId: 'id' });

      return {
        ...state,
        ...initialState,
        selection,
        data
      };
    }
    case SUCCESS_GET_FREQUENCIES_REPORT_ACTION: {
      const {
        payload: { frequencies: data }
      } = action;

      return {
        ...state,
        scheduledFrequencies: {
          ...state.scheduledFrequencies,
          ...initialState.scheduledFrequencies,
          data
        }
      };
    }
    case FAILURE_GET_SCHEDULED_REPORTS_ACTION: {
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        ...initialState,
        loading: false,
        error: true,
        ...errors
      };
    }
    case FAILURE_GET_FREQUENCIES_REPORT_ACTION: {
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        scheduledFrequencies: {
          ...state.scheduledFrequencies,
          ...initialState.scheduledFrequencies,
          error: true,
          ...errors
        }
      };
    }
    case SUCCESS_UPDATE_STATUS_SCHEDULED_REPORT_ACTION: {
      const {
        payload: { id, active }
      } = action;

      const data = _updateStatus({
        scheduledReportId: id,
        scheduledReports: state.data,
        status: active
      });
      return {
        ...state,
        ...initialState,
        data
      };
    }
    case SUCCESS_DELETE_SCHEDULED_REPORT_ACTION: {
      const {
        payload: { id }
      } = action;

      const { data: prevScheduledReports, selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });
      const data = _deleteScheduledReport({
        scheduledReportId: id,
        scheduledReports: prevScheduledReports
      });
      return {
        ...state,
        ...initialState,
        selection,
        data
      };
    }
    case 'TOGGLE_SELECT_SCHEDULED_REPORTS_ACTION':
    case 'TOGGLE_UNSELECT_SCHEDULED_REPORTS_ACTION': {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find((scheduled) => get(scheduled, 'id') === id) : false
        }
      };
    }
    case 'TOGGLE_SELECT_ALL_SCHEDULED_REPORTS_ACTION': {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: 'id'
      });

      return {
        ...state,
        selection
      };
    }
    case 'TOGGLE_UNSELECT_ALL_SCHEDULED_REPORTS_ACTION': {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    default:
      return state;
  }
};

export default scheduledReportsReducer;
