import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import numeral from 'numeral';
import _ from 'lodash';

import languagesList from '../../public/languages/languages.json';

import { generateUrl } from './commons/utils/url';
import { store } from './redux/store';

const LOCALSTORAGE_KEY = 'i18nextLng';

const { supported: fallbackLng, numeralLanguages } = languagesList;

const savedLanguage = localStorage.getItem(LOCALSTORAGE_KEY);
const {
  auth: {
    isIngenico,
    levelSettings: { languages }
  }
} = store.getState();

const supportedLngs = ({ languages, savedLanguage }) => {
  if (isIngenico) {
    return { supportedLngs: fallbackLng };
  } else if (languages) {
    return { supportedLngs: languages };
  } else if (savedLanguage) {
    return { supportedLngs: [savedLanguage] };
  }
};

const options = {
  ns: [''],

  defaultNS: '',

  fallbackNS: '',

  backend: {
    // https://github.com/i18next/i18next-xhr-backend/issues/235
    loadPath: ([lng], [ns]) => {
      let url = `/i18n/${lng}`;

      if (ns) {
        url = `/i18n/${lng}/${ns}`;
      }

      return generateUrl({ url });
    }
  },

  ...supportedLngs({ languages, savedLanguage }),

  fallbackLng, // use en if detected lng is not available

  interpolation: {
    escapeValue: false // react already safes from xss
  }
};

const changeNumeralLang = async (lang) => {
  let lng;

  switch (lang) {
    case 'en': {
      lng = 'en-gb';

      break;
    }
    case 'cr': {
      lng = 'es';

      break;
    }
    default: {
      if (_.includes(numeralLanguages, lang)) {
        lng = lang;
      } else {
        lng = 'en-gb';
      }

      break;
    }
  }

  await import(`numeral/locales/${lng}`);

  numeral.locale(lng);
};

const getBrowserLang = ({ languages }) => {
  const [browserLocal = ''] = languages;
  const [locale] = browserLocal.split('-');
  const supportedLang = _.includes(numeralLanguages, locale);

  return supportedLang ? locale : 'en';
};

i18n
  .use(detector)
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(options, (err) => {
    if (err) {
      const locale = getBrowserLang({ languages: i18n.languages });

      i18n.init({
        supportedLngs: [locale]
      });
    }
  });

export const changeLang = async (lang) => {
  try {
    if (lang) {
      await i18n.loadLanguages(lang);

      const [{ default: setApexChartDefaultLocale }] = await Promise.all([
        import('./apexcharts'),
        i18n.changeLanguage(lang),
        changeNumeralLang(lang)
      ]);

      setApexChartDefaultLocale(lang);
    }
  } catch (err) {
    return console.log('something went wrong loading', err);
  }
};

export default i18n;
