export class JSONClass {
  value: string;

  constructor() {
    this.value = 'application/json';
  }
}

export class FORMClass {
  value: string;

  constructor() {
    this.value = 'application/x-www-form-urlencoded; charset=UTF-8';
  }
}

export class TEXTClass {
  value: string;

  constructor() {
    this.value = 'text/plain';
  }
}

export const JSON = new JSONClass();
export const FORM = new FORMClass();
export const TEXT = new TEXTClass();
