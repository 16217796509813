import {
  PENDING_GET_TRANSACTION_ALERTS_ACTION,
  SUCCESS_GET_TRANSACTION_ALERTS_ACTION,
  FAILURE_GET_TRANSACTION_ALERTS_ACTION,
  SUCCESS_UPDATE_STATUS_TRANSACTION_ALERT_ACTION,
  SUCCESS_DELETE_TRANSACTION_ALERT_ACTION
} from '../actionsTypes';
import { initSelection, onToggleSelectAll, onToggleUnSelectAll } from './selection';
import get from 'get-value';

const initialState = {
  data: [],
  selection: {},
  loading: false,
  error: false,
  errors: {}
};

const _onStatusUpdated = (id, newStatus, transactionAlertsList) => {
  return transactionAlertsList.map((currAlert) => {
    if (currAlert.id === id) {
      return {
        ...currAlert,
        active: newStatus
      };
    }

    return currAlert;
  });
};

const _onTransactionAlertDeleted = ({ alertId, transactionAlerts }) => {
  return transactionAlerts.filter((transactionAlert) => {
    const { id } = transactionAlert;

    return id !== alertId;
  });
};

const transactionAlertsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_TRANSACTION_ALERTS_ACTION:
      return {
        ...state,
        ...initialState,
        loading: true
      };
    case SUCCESS_GET_TRANSACTION_ALERTS_ACTION: {
      const { transactionAlerts } = action.payload;
      const selection = initSelection({ data: transactionAlerts, keyId: 'id' });

      return {
        ...state,
        data: transactionAlerts,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_TRANSACTION_ALERTS_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case SUCCESS_UPDATE_STATUS_TRANSACTION_ALERT_ACTION: {
      const {
        payload: { status, id }
      } = action;
      const { data } = state;
      const nextData = _onStatusUpdated(id, status, data);

      return {
        ...state,
        data: nextData
      };
    }
    case SUCCESS_DELETE_TRANSACTION_ALERT_ACTION: {
      const {
        payload: { id: alertId }
      } = action;
      const { data: prevAlerts, selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });
      const transactionAlerts = _onTransactionAlertDeleted({
        alertId,
        transactionAlerts: prevAlerts
      });

      return {
        ...state,
        selection,
        data: transactionAlerts
      };
    }
    case 'TOGGLE_SELECT_TRANSACTION_ALERTS_ACTION':
    case 'TOGGLE_UNSELECT_TRANSACTION_ALERTS_ACTION': {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find((alert) => get(alert, 'id') === id) : false
        }
      };
    }
    case 'TOGGLE_SELECT_ALL_TRANSACTION_ALERTS_ACTION': {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: 'id'
      });

      return {
        ...state,
        selection
      };
    }
    case 'TOGGLE_UNSELECT_ALL_TRANSACTION_ALERTS_ACTION': {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    default:
      return state;
  }
};

export default transactionAlertsReducer;
