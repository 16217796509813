import {
  PENDING_GET_STORE_ACTION,
  SUCCESS_GET_STORE_ACTION,
  FAILURE_GET_STORE_ACTION,
  RESET_STORE_STATE_ACTION
} from '../actionsTypes';

const initialState = {
  data: {},
  loading: false,
  error: false,
  errors: {}
};

const storeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_STORE_ACTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS_GET_STORE_ACTION:
      const {
        payload: { store: data }
      } = action;

      return {
        ...state,
        data,
        loading: false,
        error: false
      };
    case FAILURE_GET_STORE_ACTION:
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        errors
      };
    case RESET_STORE_STATE_ACTION:
      return initialState;
    default:
      return state;
  }
};

export default storeReducer;
